<mat-form-field [class]="inputClass">
  <mat-label>{{label}}</mat-label>

  <input matInput
         [disabled]="disabled"
         [errorStateMatcher]="errorStateMatcher"
         [matDatepicker]="picker"
         [placeholder]="placeholder"
         [min]="min"
         [max]="max"
         [(ngModel)]="value"
         (dateChange)="onChange($event.target.value?.toDate())"
         (blur)="onTouched()">

  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>

  <mat-datepicker [touchUi]="viewPortService.isMobile" #picker></mat-datepicker>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
