import { Component, OnInit } from '@angular/core';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';
import { AbstractForm, AbstractModel } from 'taxtank-core';

/**
 * Abstract dorm control for complex data, required form inside (Phone, Address for example)
 */
@Component({
  template: ''
})
export abstract class AbstractFormInputComponent<Model extends AbstractModel, FormModel extends AbstractForm<Model>> extends AbstractControlComponent<Model> implements OnInit {
  abstract formModel;
  form: FormModel;

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.createFormInstance(this.formModel, this.ngControl.value);

    // write form value to form control when inner form become valid
    this.form.statusChanges.subscribe((status: string) => {
      if (status === 'VALID') {
        this.onChange(this.form.submit());
      } else {
        this.onChange(null);
      }
    });
  }

  private createFormInstance(formConstructor: new(controlValue: object) => FormModel, controlValue: object): FormModel {
    return new formConstructor(controlValue);
  }
}
