import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';
import { NgControl } from '@angular/forms';
import { AbstractModel, Collection, CollectionDictionary, compareMatOptions } from 'taxtank-core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

/**
 * Abstract select component with common functionality for all custom select controls
 */
@Component({
  template: ''
})
export abstract class AbstractSelectComponent<Model extends AbstractModel> extends AbstractControlComponent<Model | Model[]> implements OnInit {
  /**
   * List of select options
   */
  @Input() options: Collection<Model>;

  /**
   * Multi selection flag
   */
  @Input() multiple: boolean;

  /**
   * Path for items grouping (Create a dictionary grouped by this value)
   */
  @Input() groupBy: string;

  /**
   * Flag show/hide empty option with false value (All or single items case)
   */
  @Input() hasEmptyOption: boolean;

  /**
   * Label for empty option
   */
  @Input() emptyOptionLabel: string;

  /**
   * Message text for empty items
   */
  @Input() emptyMessage: string;

  /**
   * Flag show/hide add item option
   */
  @Input() canAdd: boolean;

  /**
   * Options grouped by passed path
   */
  groupedOptions: CollectionDictionary<Collection<Model>>;

  /**
   * Compare function for mat-select.
   * This function helps assign values from options and initial value from outside (because objects never equal)
   */
  compareFn = compareMatOptions;

  /**
   * Flag for 'Select all' checkbox indeterminate state
   */
  isIndeterminate: boolean;

  /**
   * Items loading flag
   */
  isLoading: boolean;

  /**
   * Dialog component class to add items
   */
  protected dialogComponent: ComponentType<unknown>;

  /**
   * Class for add item dialog
   */
  protected dialogPanelClass = 'dialog-medium';
  protected dialog: MatDialog = inject(MatDialog);

  constructor(public ngControl: NgControl, protected changeDetectorRef: ChangeDetectorRef) {
    super(ngControl, changeDetectorRef);
  }

  /**
   * Service calling to get default items list
   */
  abstract getOptions$(): Observable<Model[]>;

  ngOnInit(): void {
    if (this.options) {
      this.groupOptions();
      return;
    }

    this.isLoading = true;

    this.getOptions$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((items: Model[]) => {
        this.options = new Collection<Model>(items);
        this.isLoading = false;
        this.groupOptions();
        this.changeDetectorRef.markForCheck();
      });
  }

  onSelect(value: Model | Model[]): void {
    this.value = value;
    this.onChange(this.value);
    this.setIndeterminateState();
  }

  openAddItemDialog(data?: unknown): void {
    if (!this.dialogComponent) {
      // Throw error when add option enabled but dialog not specified
      if (this.canAdd) {
        console.error(`${this.constructor.name}: property 'dialogComponent' is not defined`);
      }

      return;
    }

    this.dialog.open(this.dialogComponent, {
      data,
      panelClass: this.dialogPanelClass
    });
  }

  /**
   * Group dropdown list options
   */
  private groupOptions(): void {
    // don't group if grouping path not specified
    if (!this.groupBy) {
      return;
    }

    this.groupedOptions = this.options.groupBy(this.groupBy);
  }

  /**
   * For multi select we have a common checkbox which select/deselect all properties.
   * Checkbox is indeterminate when properties selected partially (some selected but not all)
   */
  private setIndeterminateState(): void {
    if (this.multiple) {
      this.isIndeterminate = (this.value as Model[])?.length !== 0 && (this.value as Model[])?.length !== this.options?.length;
    }
  }

  /**
   * Select/Deselect all items
   */
  private toggleAll(checked: boolean) {
    checked ? this.onSelect(this.options.toArray()) : this.onSelect([]);
  }
}
