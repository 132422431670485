import { StripeElementsOptions } from '@stripe/stripe-js';

/**
 * UI options for Stripe elements
 * More info: https://stripe.com/docs/elements/appearance-api?platform=web#rules
 * @TODO Alex: move to core?
 */
export const STRIPE_ELEMENTS_OPTIONS: StripeElementsOptions = {
  appearance: {
    theme: 'stripe',
    variables: {
      fontFamily: 'Montserrat, sans-serif',
      colorPrimary: '#00b7f0',
      colorBackground: '#ffffff',
      colorDanger: '#F6465D',
      colorText: '#09435F',
      colorTextPlaceholder: '#979797',
      fontSizeBase: '18px',
      borderRadius: '3px',
      colorIconTabSelected: '#00b7f0',
      colorIconTab: '#979797'
    },
    rules: {
      '.Tab': {
        color: '#979797'
      },
      '.Tab--selected': {
        border: '1px solid #00b7f0',
        color: '#00b7f0'
      }
    }
  }
};
