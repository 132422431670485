export const COLORS: {[key: string]: string} = {
  primary:              '#00B7F0',
  secondary:            '#133F5D',
  blue:                 '#00A3FF',
  lightBlue:            '#13C5D2',
  red:                  '#FF6159',
  grey:                 '#D8D8D8',
  darkGrey:             '#9B9B9B',
  darkestGrey:          '#4A4A4A',
  green:                '#29c940',
  orange:               '#fabf2e',
  redLight:             '#FF6159',
  white:                '#FFFFFF',
  chartBlue:            '#53ABE2',
  chartBlueLight:       '#76DDFB',
  chartBlueDark:        '#2C82BE'
};

// blue palette used for charts with dynamic count of data groups
export const PALETTE_PRIMARY: string[] = [
  COLORS.chartBlue,
  COLORS.chartBlueLight,
  COLORS.chartBlueDark,
  COLORS.primary,
  COLORS.lightBlue,
  COLORS.secondary,
  COLORS.blue
];

// blue/grey palette used for charts with forecasted and real values comparison
export const PALETTE_COMPARISON: string[] = [
  COLORS.primary,
  COLORS.grey
];

// blue/green/red palette for equity position chart
export const PALETTE_EQUITY: string[] = [
  COLORS.green,
  COLORS.primary,
  COLORS.red
];
