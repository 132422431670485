import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@shared/component/base/base.component';
import {
  Collection,
  Dictionary, PropertyCollection,
  PropertyService, ServicePaymentMethod, ServicePaymentMethodService, ServicePrice,
  ServicePriceCollection, ServicePriceService, ServiceProduct,
  ServiceProductCollection,
  ServiceProductService, ServiceSubscription,
  ServiceSubscriptionItem, SubscriptionMessagesEnum, SubscriptionService, ToastService
} from 'taxtank-core';
import { plainToClass } from 'class-transformer';
import { ALL_FEATURES, FEATURES_BY_PRODUCT } from './features.const';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  PRODUCTS_TOOLTIPS
} from '@client/shared/components/subscription/subscription-change-dialog/products-tooltips.enum';
import {
  PaymentDialogComponent
} from '@client/my-account/my-account-subscription/components/payment-methods/payment-dialog/payment-dialog.component';
import cloneDeep from 'lodash/cloneDeep';
import { ConfirmationService } from 'primeng/api';
import clone from 'lodash/clone';
import { PaymentMethod } from '@stripe/stripe-js';
import {
  AddPaymentMethodDialogComponent
} from '@client/my-account/my-account-subscription/components/payment-methods/add-payment-method-dialog/add-payment-method-dialog.component';

/**
 * @TODO vik refactor
 * @TODO Alex: Component is not finished. Finish during TT-2728
 */
@Component({
  selector: 'app-subscription-change-dialog',
  templateUrl: './subscription-change-dialog.component.html',
  styleUrls: ['./subscription-change-dialog.component.scss'],
})
export class SubscriptionChangeDialogComponent extends BaseComponent implements OnInit, AfterViewChecked {
  products: ServiceProductCollection;
  prices: ServicePriceCollection;
  pricesByProduct: Dictionary<ServicePrice>;
  packagePricesByProduct: Dictionary<ServicePrice>;
  propertyPrice: ServicePrice;
  packageSum: number;
  propertyProduct: ServiceProduct;
  isPackage: boolean;
  isAnnual: boolean;
  frequency: 'mo' | 'year';
  subscription = plainToClass(ServiceSubscription, { items: [] });
  propertiesQty: number;
  propertiesMinQty: number;
  propertiesMaxQty: number;
  features: string[] = ALL_FEATURES;
  tooltips = PRODUCTS_TOOLTIPS;
  featuresByProduct = FEATURES_BY_PRODUCT;
  properties: PropertyCollection;
  isLoading: boolean;
  firstPayment: boolean;
  paymentMethods: Collection<ServicePaymentMethod>;

  constructor(
    private serviceProductService: ServiceProductService,
    private servicePriceService: ServicePriceService,
    private subscriptionService: SubscriptionService,
    private paymentMethodService: ServicePaymentMethodService,
    private propertyService: PropertyService,
    public dialogRef: MatDialogRef<SubscriptionChangeDialogComponent>,
    private dialog: MatDialog,
    private confirmationService: ConfirmationService,
    protected toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.paymentMethodService.get().subscribe(paymentMethods => {
      this.paymentMethods = paymentMethods;
    });

    combineLatest([
      this.serviceProductService.get(),
      this.servicePriceService.get(),
      this.subscriptionService.get(),
      this.propertyService.get()
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([products, prices, subscriptions, properties]) => {
        // User may have only 1 paid subscription
        // @TODO vik had to clone, some big problems in rest service
        this.firstPayment = !subscriptions.getPaid();
        this.subscription = cloneDeep(subscriptions.getActive().getPaid().first) ?? this.subscription;
        this.isPackage = this.subscription.isFullPackage();
        this.prices = prices.getActive();
        this.products = products.getActive();

        // Properties quantity
        this.propertyProduct = this.products.findBy('propertyTank', true);
        this.properties = properties;
        this.propertiesMaxQty = this.propertyProduct.maxQty;
        this.propertiesMinQty = Math.max(this.propertyProduct.minQty, this.properties.length);
        // set current properties quantity if exist or default based on minQty and actual properties length
        if (this.subscription.propertyTankItem) {
          this.propertiesQty = this.subscription.propertyTankItem.quantity;
        } else {
          this.propertiesQty = Math.max(this.propertiesMinQty, this.properties.length);
        }

        this.setPrices(this.subscription.isAnnual());

        this.products.items.forEach((product) => {
          if (subscriptions.getActive().findByProduct(product) && !this.subscription.hasProduct(product)) {
            this.toggleProduct(product);
          }
        });
      });
  }

  /**
   * @TODO Alex: hack: avoid ChangeBeforeCkecked error related to mat-checkbox
   * https://stackoverflow.com/questions/39787038/how-to-manage-angular2-expression-has-changed-after-it-was-checked-exception-w
   */
  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * @TODO Alex: move to model/collection, bad performance
   */
  get featuresJoin(): string {
    return this.subscription.getItems().mapBy('price.product.id').map(
      (productId: number) => this.featuresByProduct[this.products.findBy('id', productId).title]
    ).join(',');
  }

  /**
   * Select/Deselect subscription product
   */
  toggleProduct(product: ServiceProduct): void {
    if (this.subscription.isFullPackage()) {
      this.togglePackage(false);
      this.toggleProducts(this.products.filter((serviceProduct) => product.id !== serviceProduct.id));
    } else {
      this.subscription.hasProduct(product) ? this.removeProduct(product) : this.addProduct(product);
      if (this.subscription.isFullPackage()) {
        this.togglePackage(true);
      } else if (this.subscription.isPackage()) {
        const products = this.subscription.getItems().prices.products;
        this.togglePackage(false);
        this.toggleProducts(products.filter((serviceProduct) => product.id !== serviceProduct.id));
      }
    }
  }

  toggleProducts(products: ServiceProductCollection): void {
    products.items.forEach((product) => this.toggleProduct(product));
  }

  /**
   * Select/Deselect product
   */
  togglePackage(isSelected: boolean): void {
    this.isPackage = isSelected;
    this.subscription.items = [];
    if (!isSelected) {
      return;
    }

    this.products.toArray().forEach((product) => {
      this.addProduct(product, isSelected);
    });
    this.propertyPrice = this.prices.getProperty(this.isAnnual, isSelected);
  }

  /**
   * Add passed product to subscription
   */
  private addProduct(product: ServiceProduct, isPackage = false): void {
    const prices = isPackage ? this.packagePricesByProduct : this.pricesByProduct;
    // @TODO Alex: improve logic
    this.subscription.items.push(plainToClass(ServiceSubscriptionItem, {
      price: prices.get(product.id),
      quantity: product.isProperties() ? this.propertiesQty : 1
    }));
  }

  /**
   * Remove passed product from subscription
   */
  private removeProduct(product: ServiceProduct): void {
    this.subscription.items = this.subscription.items.filter((item) => item.price.product.id !== product.id);
  }

  /**
   * Update current subscription property item with selected quantity
   */
  updatePropertiesQuantity(): void {
    this.subscription.propertyTankItem.quantity = this.propertiesQty;
    this.setPrices(this.subscription.isAnnual());
  }

  setPrices(isAnnual: boolean): void {
    this.frequency = isAnnual ? 'year' : 'mo';
    const prices = isAnnual ? this.prices.annual : this.prices.monthly;
    const packagePrices = isAnnual ? this.prices.annualPackage : this.prices.monthlyPackage;
    this.pricesByProduct = new Dictionary(prices.toArray(), 'product.id');
    this.packagePricesByProduct = new Dictionary(packagePrices.toArray(), 'product.id');
    this.packageSum = isAnnual ? this.prices.getAnnualPackageSum(this.propertiesQty) : this.prices.getMonthlyPackageSum(this.propertiesQty);
    this.propertyPrice = this.prices.getProperty(isAnnual, this.isPackage);

    if (this.isAnnual !== undefined) {
      this.subscription.items.forEach((item) => {
        this.removeProduct(item.price.product);
        this.addProduct(item.price.product, this.isPackage);
      });
    }

    this.isAnnual = isAnnual;
    this.subscription.applyPromoCode(this.subscription.promoCode);
  }

  upgrade(): void {
    // payment method required for temporary free subscription
    if (this.subscription.price || this.subscription.promoCode.isDurationForever() || this.paymentMethods.length) {
      this.postSubscription();
      return;
    }

    const dialogRef = this.dialog.open(AddPaymentMethodDialogComponent, {
      panelClass: 'dialog-small'
    });

    dialogRef.afterClosed().subscribe((isConfirmed: boolean): void => {
      if (!isConfirmed) {
        return;
      }

      this.postSubscription();
    });
  }

  /**
   * check subscription and handle result (continue with payment or close if payment if not required)
   */
  postSubscription(): void {
    // Send prepared subscription to backend to get Stripe client secret
    this.subscriptionService.postStripeSubscription(this.subscription)
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientSecret: string) => {
        // empty response for 100% discount via promocode (no need for payment)
        if (!clientSecret) {
          this.toastService.success(SubscriptionMessagesEnum.SUCCEEDED_FREE);
          this.dialogRef.close();

          return;
        }

        // continue with payment
        this.openPaymentDialog(clientSecret);
      });
  }

  /**
   * Show dialog with Stripe payment form
   */
  openPaymentDialog(clientSecret: string): void {
    this.dialog.open(PaymentDialogComponent, {
      data: {
        subscription: this.subscription,
        stripeClientSecret: clientSecret
      },
      panelClass: 'dialog-small'
    });

    this.dialogRef.close();
  }

  /**
   * Show confirmation popup with proration cost
   */
  openChangeSubscriptionConfirmPopup(event: Event): void {
    this.subscriptionService.getProrationCost(this.subscription).subscribe((prorationCost: number) => {
      const frequency = this.subscription.isAnnual() ? 'annual' : 'monthly';
      const date = clone(this.subscription.startDate);
      date.setMonth(date.getMonth() + (this.subscription.isAnnual() ? 12 : 1));
      const dateString = date.toLocaleDateString('en-AU');
      const isDowngrade = prorationCost < 0;


      // @TODO Alex no way we keep that
      let confirmPopupContent = `
        <div class=" fs-big fw-bold mb-10">
          Change Subscription Plan
        </div>
      `;

      if (isDowngrade) {
        confirmPopupContent += `
          <div class="c-secondary">
            Downgrade will take affect from the next period <b>${dateString}</b>,<br/>
            you will be charged <h5 class="d-inline">$${this.subscription.price.toFixed(2)}</h5> next time.
          </div>
        `;
      } else {
        confirmPopupContent += `
          <div class="c-secondary">
            Upgrade will take affect right away, you will be charged <h5
            class="d-inline">$${prorationCost.toFixed(2)}</h5> right now.<br/>
            Your ${frequency} payment will be
            <h5 class="d-inline">$${this.subscription.price.toFixed(2)}</h5> from <b>${dateString}</b>.
          </div>
        `;
      }

      this.confirmationService.confirm({
        target: event.target,
        acceptLabel: 'Confirm',
        rejectLabel: 'Cancel',
        message: confirmPopupContent,
        accept: () => {
          this.isLoading = true;
          // confirm action
          this.subscriptionService.changeSubscription(this.subscription).subscribe(() => {
            // @TODO Alex: show preloader by endpoints listen
            this.isLoading = false;
            this.toastService.warning('Please wait while we process your payment.');
            this.dialogRef.close();
          });
        }
      });
    });
  }
}
