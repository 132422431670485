<ng-container [appPreloader]="[endpoints.BASIQ_ACCOUNTS_GET, endpoints.BASIQ_DELETE, endpoints.BASIQ_TOKEN_GET]" [preloaderType]="preloaderTypes.OVERLAY"></ng-container>

<!-- Desktop view -->
<div *ngIf="!viewPortService.isMobile" mat-dialog-content class="bank-connections-dialog-content">
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()" class="transparent" appFormErrorScroll>
    <div class="row">
      <div class="col-6">
        <app-datepicker label="Import transactions from" formControlName="migrateFrom"></app-datepicker>
      </div>

      <div class="col-6">
        <app-datepicker label="Import transactions to" formControlName="migrateTo"></app-datepicker>
      </div>
      <!-- @TODO Front: create component or directive to handle formGroup errors -->
      <div class="col-12" *ngIf="form.submitted && form.hasError('arrayControlsDisabled')">
        <div class="form-group-error">{{nothingSelectedErrorMessage}}</div>
      </div>
    </div>

    <div class="bank-accounts-container">
      <app-bank-account-import-form *ngFor="let bankAccountForm of form.get('bankAccounts').controls; let i = index"
                                    [form]="bankAccountForm">
      </app-bank-account-import-form>

      <ng-container *ngIf="savedAccounts.length">
        <div class="fs-big c-secondary fw-bold mv-10">Imported bank accounts</div>

        <div *ngFor="let bankAccount of savedAccounts" class="saved-account">
          <div class="row">
            <div class="col-4">
              <div class="align-v-center">
                <div class="mr-10">
                  <mat-checkbox [checked]="true" [disabled]="true"></mat-checkbox>
                </div>

                <div class="fw-bold">
                  <div class="c-primary">{{bankAccount.accountName}}</div>
                  <div class="fs-small c-text mt-5">{{bankAccount.accountNumber}}</div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-8 c-text">
              <div class="row">
                <div class="col-12 col-md-4 align-v-center">
                  <div>
                    <div class="fw-bold mb-5">Balance</div>
                    <div>{{bankAccount.currentBalance | customCurrency}}</div>
                  </div>
                </div>

                <div class="col-12 col-md-4  align-v-center">
                  <div>
                    <div class="fw-bold mb-5">Type</div>
                    <div>{{bankAccountTypes[bankAccount.type] | translate}}</div>
                  </div>
                </div>

                <div class="col-12 col-md-4 align-v-center">
                  <div>
                    <div class="fw-bold mb-5">Tank type</div>
                    <div>{{tankTypes[bankAccount.tankType] | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>

<!-- Mobile view -->
<div *ngIf="viewPortService.isMobile" mat-dialog-content>
  <!-- Bank accounts allowed to import -->
  <app-bank-account-preview-card *ngFor="let bankAccount of bankAccounts; let i = index"
                                 [bankAccount]="bankAccount"
                                 class="bg-white"
                                 (click)="openBankAccountImportFormDialog(bankAccount)">
  </app-bank-account-preview-card>

  <!-- Imported bank accounts -->
  <ng-container *ngIf="savedAccounts?.length">
    <div class="c-secondary fs-big mb-15">Imported bank accounts</div>

    <app-bank-account-preview-card *ngFor="let bankAccount of savedAccounts"
                                   [bankAccount]="bankAccount">
    </app-bank-account-preview-card>
  </ng-container>
</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-sm-auto align-v-center">
      <button class="btn link c-primary"
              [appConfirm]="messages.CONFIRM_MANAGE"
              (accepted)="reconnect(connection)">
        Manage accounts
      </button>
    </div>

    <div class="col-6 col-sm-auto">
      <button (click)="back.emit()" class="btn basic block" type="button">Back</button>
    </div>

    <!-- Sumbit is not allowed for mobile because we are using separated dialog component for bank account import -->
    <div *ngIf="viewPortService.isDesktop" class="col-6 col-sm-auto">
      <button class="btn primary w-100" (click)="formRef.ngSubmit.emit()" [disabled]="!form">Submit</button>
    </div>
  </div>
</div>
