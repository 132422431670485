import { Component, OnInit } from '@angular/core';
import { ServicePaymentMethodService, SubscriptionService, ToastService } from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';
import { StripeElements, StripeElementsOptions } from '@stripe/stripe-js';
import {
  StripeResponseStatusEnum
} from '@client/my-account/my-account-subscription/components/current-subscription/stripe-response-status.enum';
import { MatDialogRef } from '@angular/material/dialog';
import {
  STRIPE_ELEMENTS_OPTIONS
} from '@client/my-account/my-account-subscription/components/payment-methods/stripe-elements-options.const';
import { MessagesEnum } from './messages.enum';
import { takeUntil } from 'rxjs/operators';

/**
 * Add and save user's payment method
 */
@Component({
  selector: 'app-add-payment-method-dialog',
  templateUrl: './add-payment-method-dialog.component.html',
  styleUrls: ['./add-payment-method-dialog.component.scss']
})
export class AddPaymentMethodDialogComponent extends BaseComponent implements OnInit {
  /**
   * Stripe elements using for payment form
   */
  stripeElements: StripeElements;

  /**
   * Stripe client secret for requests to Stripe
   */
  stripeClientSecret: string;

  /**
   * Error message sent by Stripe if something wrong
   */
  errorMessage: string;

  /**
   * Flag show preloader when stripe is loading
   */
  isLoading: boolean;

  constructor(
    private servicePaymentMethodService: ServicePaymentMethodService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<AddPaymentMethodDialogComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.servicePaymentMethodService.getSecret()
      .pipe(takeUntil(this.destroy$))
      .subscribe((clientSecret: string) => {
        this.stripeClientSecret = clientSecret;
        this.initStripeElements(clientSecret);
      });
  }

  submit(): void {
    this.errorMessage = null;
    this.isLoading = true;

    window['stripe'].confirmSetup({
      elements: this.stripeElements,
      confirmParams: {
        // Redirect URL after when payment method added
        // https://stripe.com/docs/payments/save-and-reuse?platform=web#web-collect-payment-details
        return_url: window.location.href,
      },
      redirect: 'if_required'
    }).then((response) => {
      this.isLoading = false;

      if (response.error) {
        this.errorMessage = response.error.message;
        return;
      }

      // @TODO Alex: move to separated component (payment status page)
      switch (response.setupIntent.status) {
        case StripeResponseStatusEnum.SUCCEEDED:
          this.toastService.success(MessagesEnum.SUCCEEDED);
          this.dialogRef.close(true);
          break;

        case StripeResponseStatusEnum.PROCESSING:
          this.toastService.warning(MessagesEnum.PROCESSING);
          this.dialogRef.close(true);
          break;

        case StripeResponseStatusEnum.REQUIRES_PAYMENT_METHOD:
          this.errorMessage = MessagesEnum.REQUIRES_PAYMENT_METHOD;
          break;

        default:
          this.errorMessage = MessagesEnum.ERROR;
      }
    });
  }

  /**
   * Init Stripe elements and show form
   * @TODO Alex: copypast, move to separated component or something
   */
  private initStripeElements(clientSecret: string): void {
    // Setup Stripe elements
    // Available options: https://stripe.com/docs/elements/appearance-api
    const options: StripeElementsOptions = Object.assign(STRIPE_ELEMENTS_OPTIONS, { clientSecret });

    // Add Stripe payment element to the page
    // https://stripe.com/docs/billing/subscriptions/build-subscriptions?ui=elements#collect-payment
    this.stripeElements = window['stripe'].elements(options);

    const payment = this.stripeElements.create('payment');

    // Hide preloader when Stripe payment element is ready
    payment.on('ready', () => {
      this.isLoading = false;
    });

    payment.mount('#payment-element');
  }
}
