<ng-container [appPreloader]="[endpoints.CHART_ACCOUNTS_POST, endpoints.CHART_ACCOUNTS_PUT, endpoints.CHART_ACCOUNTS_DELETE]"
              [preloaderType]="preloaderTypes.OVERLAY"
              [preloaderSize]="20">
</ng-container>

<div class="row">
  <div class="col-md-6">
    <div [class.no-data]="!chartAccountsList.length">
      <div>
        <div class="fs-big fw-bolder c-secondary mb-5">Custom Personal Categories List</div>
        <div *ngIf="!chartAccountsList.length" class="fs-default c-text">Custom categories will appear in the dropdown to select when allocating</div>

        <mat-nav-list *ngIf="chartAccountsList.length" class="chart-accounts-list">
          <mat-list-item *ngFor="let chartAccounts of chartAccountsList.items"
                         class="chart-accounts"
                         [class.selected]="chartAccounts.id === selectedChartAccounts?.id"
                         (click)="toggle(chartAccounts)">
            <a matLine>{{ chartAccounts.name }}</a>
            <!--<i class="icon icon-edit mr-5"></i>-->
            <i class="icon icon-trash"
               appStopPropagation
               [appConfirm]="messages.CONFIRM_DELETE"
               (accepted)="delete(chartAccounts)">
            </i>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <form [formGroup]="form" (ngSubmit)="submit()" class="p-10">
      <div class="fs-big fw-bolder c-secondary">Add Custom Personal Categories</div>
      <div class="fs-small mb-15">Custom categories can be edited but not deleted if transactions have been allocated.</div>

      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required>
        <mat-error *ngIf="form.get('name').invalid && form.get('name').touched">
          <app-validation-error [errors]="form.get('name').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-radio-group formControlName="category" class="default" color="primary">
        <mat-radio-button [value]="chartAccountsCategories.PERSONAL_EXPENSE" class="mr-10">Expense</mat-radio-button>
        <mat-radio-button [value]="chartAccountsCategories.PERSONAL_INCOME">Income</mat-radio-button>
      </mat-radio-group>

      <div class="mt-15">
        <button class="btn primary">Save</button>
        <button *ngIf="selectedChartAccounts" type="button" (click)="reset()" class="btn basic">Cancel</button>
      </div>
    </form>
  </div>
</div>
