import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  SubscriptionChangeDialogComponent
} from '@client/shared/components/subscription/subscription-change-dialog/subscription-change-dialog.component';
import { BaseComponent } from '@shared/component/base/base.component';

@Component({
  selector: 'app-subscription-banner-past-due',
  templateUrl: './subscription-banner-past-due.component.html',
  styleUrls: ['./subscription-banner-past-due.component.scss']
})
export class SubscriptionBannerPastDueComponent extends BaseComponent {
  constructor(private dialog: MatDialog) {
    super();
  }

  openSubscriptionDialog(): void {
    this.dialog.open(SubscriptionChangeDialogComponent, {
      panelClass: 'dialog-large'
    });
  }
}
