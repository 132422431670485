<section class="p-0">
  <ul *ngIf="(notifications$ | async) as notifications" [class.is-popup]="isPopup" class="notification-list">
    <li *ngFor="let notification of notifications">
      <app-notification-message [notification]="notification"></app-notification-message>
    </li>
    <li *ngIf="!notifications.length"
        class="notification fs-default mb-10 p-25 w-100 border-radius bg-white shadow text-center">
      <span class="fw-700 mb-20">No notifications</span>
    </li>
    <li *ngIf="isPopup && user.isClient()"
        class="all-notifications redirection-link fs-default c-primary bg-white text-center p-15 pointer shadow fw-700"
        [routerLink]="user.isClient() ? '/client/notifications' : '/firm/notifications'">
      See all notifications
    </li>
  </ul>
</section>
