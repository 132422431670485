import { Component, Input, OnInit } from '@angular/core';
import flatten from 'lodash/flatten';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';

declare type FileTypes = 'doc' | 'jpg' | 'png' | 'pdf' | 'tiff' | 'bmp' | 'xls' | 'csv';

// @TODO refactor
const MIME_TYPES: { [key in FileTypes]: string[] } = {
  doc: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'],
  jpg: ['image/jpg', 'image/jpeg'],
  png: ['image/png'],
  pdf: ['application/pdf'],
  tiff: ['image/tiff'],
  bmp: ['image/bmp'],
  xls: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  csv: ['text/csv'],
};

/**
 * Component to upload files. Allows direct files select or drag'n'drop select.
 * @TODO Alex: remove when new files api ready
 */
@Component({
  selector: 'app-drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss']
})
export class DragNDropComponent extends AbstractControlComponent<File | File[]> implements OnInit {
  @Input() caption = 'Upload receipt';
  @Input() placeholder = '[Choose a file or drag it here]';
  @Input() multiple: boolean;
  @Input() allowedTypes: FileTypes[];
  mimeTypes: string;

  ngOnInit(): void {
    if (!this.placeholder) {
      this.placeholder = '[Choose a file or drag it here]';
    }

    if (!this.allowedTypes) {
      this.allowedTypes = Object.keys(MIME_TYPES) as FileTypes[];
    }
    this.mimeTypes = flatten(this.allowedTypes.map((type: string) => MIME_TYPES[type])).join();
  }

  onSelect(fileList: FileList): void {
    if (!fileList) {
      this.value = null;
      this.onChange(null);
      return;
    }

    if (this.multiple) {
      // convert FileList to an array of files
      this.value = [];

      for (let i = 0; i < fileList.length; i++) {
        this.value.push(fileList[i]);
      }
    } else {
      // get the first file from FileList
      this.value = fileList[0];
    }

    this.onChange(this.value);
    this.onTouched();
  }
}
