import { Component, Input } from '@angular/core';
import { BankConnection } from 'taxtank-core';

/**
 * Final step of bank accounts adding flow
 */
@Component({
  selector: 'app-bank-accounts-success',
  templateUrl: './bank-accounts-success.component.html',
  styleUrls: ['./bank-accounts-success.component.scss']
})
export class BankAccountsSuccessComponent {
  @Input() connection: BankConnection;
  @Input() areBankAccountsAdded: boolean;
}
