import { Component, Input } from '@angular/core';
import { CustomCurrencyPipe } from '@shared/pipe/custom-currency/custom-currency.pipe';
import { COLORS, PALETTE_PRIMARY } from '@shared/globals/colors';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * @TODO refactor charts system with error handling and beautiful chart data
 */
@Component({
  selector: 'app-base-chart',
  template: '',
})
export class BaseChartComponent extends BaseComponent {
  // chart data source
  @Input() data: any[] = [];
  // id of html container to display chart
  @Input() containerId?: string;
  // field for data
  @Input() bindSeriesData = 'data';
  // field for data name
  @Input() bindSeriesName = 'name';
  // field name for column value
  @Input() bindY = 'value';
  // field name for xAxis label
  @Input() bindX = 'label';
  // flag define how to display xAxis with date or with category
  @Input() isDateChart: boolean;
  // flag define how to display yAxis: as currency or not
  @Input() isCurrency = true;
  // chart settings
  chartOptions: any;
  // data based on @Input data to display on chart
  chartData: any;

  constructor(public customCurrencyPipe: CustomCurrencyPipe) {
    super();
  }

  setDefaultOptions(customCurrencyPipe: CustomCurrencyPipe): void {
    this.chartOptions = {
      // Disable HighCharts label on chart
      credits: {
        enabled: false
      },
      // X axis settings
      xAxis: {
        dateTimeLabelFormats: {
          // show short month label
          month: '%b'
        },
        labels: {
          style: {
            color: COLORS.grey,
            fontWeight: 'bold',
            fontSize: '12px',
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'uppercase'
          }
        },
        // disable short vertical line from xAxis line to xAsix label
        tickLength: 0
      },
      yAxis: {
        title: null,
        labels: {
          formatter: function (): string {
            return customCurrencyPipe.transform(this.value, true);
          },
          style: {
            color: COLORS.grey,
            fontWeight: 'bold',
            fontSize: '12px',
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'uppercase'
          }
        }
      },
      // common chart settings
      chart: {
        backgroundColor: 'transparent'
      },
      plotOptions: {
        areaspline: {
          marker: {
            fillColor: COLORS.white,
            lineWidth: 2,
            lineColor: null,
            symbol: 'circle'
          },
        },
        pie: {
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f}%'
          }
        },
        series: {
          pointPadding: 0.05,
          borderWidth: 0
        }
      },
      tooltip: {
        backgroundColor: COLORS.white,
        borderWidth: 1,
        borderRadius: 4,
        shadow: false,
        useHTML: true,
        style: {
          padding: 15
        }
      },
      title: null,
      legend: {
        enabled: false
      },
      colors: PALETTE_PRIMARY
    };
  }
}
