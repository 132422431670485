import { Component, Input } from '@angular/core';
import { ExportFormatEnum, PdfOrientationEnum } from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * Component that contains base logic to work with exported data, like title, filename, format, e.t.c.
 */
@Component({
  selector: 'app-export',
  template: ''
})
export abstract class ExportComponent extends BaseComponent {
  @Input() title = '';
  @Input() filename = 'export';
  @Input() fileOrientation: PdfOrientationEnum = PdfOrientationEnum.PORTRAIT;
  @Input() subtitle?: string = '';
  @Input() description?: number | string = '';
  /**
   * Export file formats (PDF, XLSX, e.t.c.)
   */
  @Input() formats: ExportFormatEnum[] = [ExportFormatEnum.PDF];
  fileFormats: typeof ExportFormatEnum = ExportFormatEnum;
}
