import { Directive, HostListener, Input } from '@angular/core';
import { BaseFormComponent } from '@shared/component/base/base-form.component';

/**
 * Directive which submit form in passed component
 */
@Directive({
  selector: '[appFormSubmit]'
})
export class FormSubmitDirective {
  @Input() appFormSubmit: BaseFormComponent;

  @HostListener('click')
  onClick(): void {
    this.appFormSubmit.formRef.ngSubmit.emit();
  }
}
