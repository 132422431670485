import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppFile, getDocIcon } from 'taxtank-core';

/**
 * Shared component with document view
 */
@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentComponent {
  @Input() file: AppFile;

  /**
   * Get picture for file
   */
  getDocIcon(file: AppFile): string {
    return getDocIcon(file);
  }
}
