import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DOCUMENT_FILE_TYPES, FileValidator, ToastService } from 'taxtank-core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileSizeConvertPipe } from '@shared/pipe/size-convert/file-size-convert.pipe';

const MESSAGE_PHOTO_SIZE_ERROR = 'File is too big. Maximum file size is $size';
const MESSAGE_PHOTO_TYPE_ERROR = 'File type not supported. Allowed file types: jpg, png, bmp';

/**
 * Component, that contains form to upload image
 * It works via reactive form logic, because native form logic incorrectly converts file to formData object
 */
@Component({
  selector: 'app-photo-form',
  templateUrl: './photo-form.component.html',
  styleUrls: ['./photo-form.component.scss']
})
export class PhotoFormComponent implements OnInit {
  // Max file size (10Mb by default)
  @Input() photoMaxSize = 10485760;
  // allowed file types
  @Input() photoFileTypes: string[] = DOCUMENT_FILE_TYPES.image;
  @ViewChild('input', {static: true}) input: ElementRef;
  // emit submit event with uploaded file prepared to upload
  @Output() submitted: EventEmitter<FormData> = new EventEmitter<FormData>();
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  /**
   * Build upload image form
   */
  buildForm(): void {
    this.form = this.fb.group({
      photo: [null, [Validators.required, FileValidator.fileExtensions(DOCUMENT_FILE_TYPES.image), FileValidator.fileMaxSize(this.photoMaxSize)]]
    });
  }

  /**
   * Check file is valid and show errors if not
   */
  // @Todo create shared solution for file validation logic
  checkIsFileValid(): void {
    // check file extension
    if (this.form.get('photo').hasError('fileExtension')) {
      this.form.get('photo').reset();
      this.toastService.error(MESSAGE_PHOTO_TYPE_ERROR);
      return;
    }

    // check file size
    if (this.form.get('photo').hasError('fileMaxSize')) {
      this.form.get('photo').reset();
      this.toastService.error(MESSAGE_PHOTO_SIZE_ERROR.replace('$size', new FileSizeConvertPipe().transform(this.photoMaxSize)));
      return;
    }
  }

  /**
   * Check validity and upload file to backend
   * @param file
   */
  uploadFile(file: File): void {
    this.form.get('photo').setValue(file);

    this.checkIsFileValid();
    // check if whole form is valid
    if (!this.form.valid) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('file', this.form.get('photo').value);

    this.submitted.emit(formData);
    this.form.get('photo').reset();
  }

  /**
   * Open system files dialog
   */
  openDialog(): void {
    this.input.nativeElement.click();
  }
}
