import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-inner-card-group',
  templateUrl: './inner-card-group.component.html',
  styleUrls: ['./inner-card-group.component.scss']
})
export class InnerCardGroupComponent {
  @Input() isTransparent: boolean;
  // Stretch inner cards to full width
  @Input() isFullWidth: boolean;
}
