import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BankAccountCollection, SoleInvoiceTemplate,
  SoleInvoiceTemplateForm, SoleInvoiceTemplateService, SoleInvoiceTemplateTaxTypeEnum,
} from 'taxtank-core';
import { BaseFormComponent } from '@shared/component/base/base-form.component';
import { MessagesEnum } from './messages.enum';
import { Observable } from 'rxjs';

/**
 * Sole Invoice Template add/edit form component
 */
@Component({
  selector: 'app-invoice-template-form',
  templateUrl: './invoice-template-form.component.html',
  styleUrls: ['./invoice-template-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceTemplateFormComponent extends BaseFormComponent implements OnInit {
  @Input() invoiceTemplate: SoleInvoiceTemplate;
  @Output() saved: EventEmitter<void> = new EventEmitter();
  form: SoleInvoiceTemplateForm;
  bankAccounts$: Observable<BankAccountCollection>;
  taxTypes: typeof SoleInvoiceTemplateTaxTypeEnum = SoleInvoiceTemplateTaxTypeEnum;

  constructor(private soleInvoiceTemplateService: SoleInvoiceTemplateService) {
    super();
  }

  /**
   * @TODO Alex: refactor parent class with generics and move method there
   */
  buildForm(): void {
    this.form = new SoleInvoiceTemplateForm(this.invoiceTemplate);
  }

  /**
   * @TODO Alex: refactor parent class with generics and move method there
   */
  save(invoiceTemplate: SoleInvoiceTemplate): void {
    if (invoiceTemplate.id) {
      this.update(invoiceTemplate);
    } else {
      this.add(invoiceTemplate);
    }
  }

  private update(invoiceTemplate: SoleInvoiceTemplate): void {
    this.soleInvoiceTemplateService.update(invoiceTemplate).subscribe(() => {
      this.toastService.success(MessagesEnum.UPDATED);
      this.saved.emit();
    });
  }

  private add(invoiceTemplate: SoleInvoiceTemplate) {
    this.soleInvoiceTemplateService.add(invoiceTemplate).subscribe(() => {
      this.toastService.success(MessagesEnum.CREATED);
      this.saved.emit();
    });
  }
}
