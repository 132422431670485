import { Component, Inject } from '@angular/core';
import {  MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  imageLink: string;
}

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<ImagePopupComponent>
  ) { }
}
