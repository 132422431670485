<ul id="sidenavTopMenu" [class.collapsed]="isCollapsed" class="sidenav-menu">
  <ng-container *ngFor="let item of filteredMenuItems">
    <!-- @Todo TT-2406 Mikhail refactor block when project will be updated to 14+ Material version -->
    <li *ngIf="item.link"
        [attr.id]="item.id"
        class="sidenav-menu-item align-v-center"
        [routerLink]="item.link"
        routerLinkActive="active"
        [matTooltip]="item.tooltipText"
        matTooltipPosition="after"
        [matTooltipDisabled]="!(item.tooltipText)">

      <i class="icon icon-{{item.icon}}" [appNotifications]="item.notifications$"></i>
      <div class="sidenav-menu-item-label">{{item.label}}</div>
    </li>

    <!-- @Todo TT-2406 Mikhail remove when project will be updated to 14+ Material version -->
    <ng-container *ngIf="!item.link">
      <li [attr.id]="item.id"
          class="sidenav-menu-item align-v-center"
          [matTooltip]="item.label"
          [matMenuTriggerFor]="menu"
          routerLinkActive="active"
          appStopPropagation
          [matTooltipDisabled]="!isCollapsed">
        <i class="icon icon-{{item.icon}}" [appNotifications]="item.notifications$"></i>
        <div class="sidenav-menu-item-label">{{item.label}}</div>

        <!-- Add mat-menu if top menu item has sub-items -->
        <mat-menu #menu="matMenu">
          <ul class="sidenav-submenu m-0">
            <ng-container *ngFor="let submenuItem of item.subMenuItems">
              <li [attr.id]="item.id"
                  class="sidenav-submenu-item align-v-center m-0"
                  [routerLink]="[submenuItem.link]"
                  routerLinkActive="active"
                  [matTooltip]="submenuItem.label"
                  [matTooltipDisabled]="!isCollapsed">
                <div class="sidenav-menu-item-label">{{submenuItem.label}}</div>
              </li>
            </ng-container>
          </ul>
        </mat-menu>
      </li>
    </ng-container>
  </ng-container>
</ul>
