<div class="card" [class.collapsed]="isCollapsed">
  <div class="card-header" [class.bg-light]="disabled">
    <div class="card-badge">
      <ng-content select="[slot='label']"></ng-content>
    </div>
    <div class="card-status">
      <ng-content select="[slot='status']"></ng-content>
    </div>
    <div *ngIf="expandable" class="card-toggler">
      <i class="icon icon-caret-down" (click)="toggle()"></i>
    </div>
    <div class="row gy-3">
      <!-- @TODO Alex refactor, should be positioned automatically -->
      <div [class.col-7]="!longTitle" [class.col-10]="longTitle">
        <div class="card-title">
          <ng-content select="[slot='title']"></ng-content>
        </div>
        <div class="card-info">
          <ng-content select="[slot='info']"></ng-content>
        </div>
        <div class="card-subtitle">
          <ng-content select="[slot='subtitle']"></ng-content>
        </div>
      </div>
      <div [class.col-5]="!longTitle" [class.col-2]="longTitle">
        <div class="card-additional">
          <ng-content select="[slot='additional']"></ng-content>
          <ng-content select="[slot='icon']"></ng-content>
          <ng-content select="[slot='menu']"></ng-content>
        </div>
      </div>
    </div>
    <div class="card-overview">
      <ng-content select="[slot='overview']"></ng-content>
    </div>
  </div>

  <div class="card-content">
    <ng-content select="[slot='content']"></ng-content>
  </div>

  <div class="card-footer" [class.bg-light]="disabled">
    <ng-content select="[slot='footer']"></ng-content>
  </div>
</div>
