import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@shared/component/base/base.component';
import { plainToClass } from 'class-transformer';
import {
  Property, PropertyCategory,
  PropertyCategoryMovementCollection,
  PropertyCategoryMovementService, PropertyForecast, PropertyForecastForm
} from 'taxtank-core';
import { takeUntil } from 'rxjs/operators';

/**
 * Property forecast form component. Contain property forecast own fields
 */
@Component({
  selector: 'app-property-forecast-form',
  templateUrl: './property-forecast-form.component.html',
  styleUrls: ['./property-forecast-form.component.scss']
})
export class PropertyForecastFormComponent extends BaseComponent implements OnInit, OnChanges {
  // PropertyForecast instance new or existing
  @Input() forecast: PropertyForecast = plainToClass(PropertyForecast, {});
  // @TODO TT-2355 Alex remove when forecast api refactored (to use separated api instead of property api)
  @Input() property?: Property;
  @Input() category: PropertyCategory;
  // form with property forecast fields
  @Input() form: PropertyForecastForm;
  // UI flag: remove vertical paddings on the form block
  @Input() isTransparent: boolean;
  // flag to hide Market Value field
  @Input() hideMarketValue: boolean;
  // flag to show financial year disabled field
  @Input() showFinancialYear: boolean;
  @Output() submitted: EventEmitter<PropertyForecast> = new EventEmitter<PropertyForecast>();
  expenseLabel: string;
  interestLabel: string;

  constructor(private categoryMovementService: PropertyCategoryMovementService) {
    super();
  }

  ngOnInit(): void {
    if (this.form) {
      return;
    }

    this.categoryMovementService.get().pipe(takeUntil(this.destroy$))
      .subscribe((movements: PropertyCategoryMovementCollection) => {
        this.buildForm(movements.filterByForecast(this.property, this.forecast));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.category) {
      this.setLabels(this.category);
    }
  }

  buildForm(movements: PropertyCategoryMovementCollection): void {
    this.form = new PropertyForecastForm(this.forecast, movements);
    this.setLabels(movements.last.propertyCategory);
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (!this.form.submit()) {
      return;
    }

    this.submitted.emit(this.form.submit());
  }

  /**
   * Some field labels are depends on property category
   */
  private setLabels(category?: PropertyCategory): void {
    switch (true) {
      case category?.isOwnerOccupied():
        this.expenseLabel = 'Expenses';
        this.interestLabel = 'Interest';
        break;
      case category?.isVacantLand():
        this.expenseLabel = 'Land Expenses';
        this.interestLabel = 'Interest on loans';
        break;
      default:
        this.expenseLabel = 'Rental Expenses';
        this.interestLabel = 'Rental Interest';
    }
  }
}
