import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PropertyGrowthPercentageCorelogicDialogComponent } from '@client/shared/components/property/property-growth-percentage/property-growth-percentage-corelogic-dialog/property-growth-percentage-corelogic-dialog.component';
import { IGrowthPercentProperty } from '@shared/interfaces/property-growth-percent.interface';
import { BaseComponent } from '@shared/component/base/base.component';
import { Property, PropertyService, ToastService, replace } from 'taxtank-core';

const MESSAGE_ADDED = 'Growth percentage for properties was successfully calculated';

interface DialogData {
  properties: Property[];
}

/**
 * Component to work with property growth percentage
 */
@Component({
  selector: 'app-property-growth-percentage-dialog',
  templateUrl: './property-growth-percentage.component.html',
  styleUrls: ['./property-growth-percentage.component.scss']
})
export class PropertyGrowthPercentageComponent extends BaseComponent implements OnInit {
  isFullNotice = false;
  propertiesWithPercentages: IGrowthPercentProperty[] = [];
  // Max value by which the growth percentage can deviate
  percentageRange = 3;
  // Step by which the growth percentage can change
  percentageStep = 0.01;
  // Default min/max percent if no value was received from Corelogic
  defaultMinPercent = 0;
  defaultMaxPercent = 10;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private propertyService: PropertyService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<PropertyGrowthPercentageComponent>,
    public dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getPropertiesWithPercentages();
  }

  /**
   * Fill propertiesWithPercentages array with values
   */
  getPropertiesWithPercentages(): void {
    this.data.properties.forEach((property: Property) => {
      this.propertiesWithPercentages.push({percent: property.growthPercent, property: property});
    });
  }

  /**
   * Show more corelogic information
   */
  showMore(): void {
    this.isFullNotice = true;
  }

  /**
   * Show less corelogic information
   */
  showLess(): void {
    this.isFullNotice = false;
  }

  /**
   * Open dialog with corelogic information
   */
  openLearnAboutCorelogicDialog(): void {
    this.dialog.open(PropertyGrowthPercentageCorelogicDialogComponent);
  }

  /**
   * Growth percentage changing handler
   * @param sliderValue
   * @param property
   */
  changePercent(sliderValue: number, property: Property): void {
    const replaceObject: IGrowthPercentProperty = this.propertiesWithPercentages.find((propertyWithPercent: IGrowthPercentProperty) => propertyWithPercent.property.id === property.id);
    const replacePercentagePropertyIndex: number = this.propertiesWithPercentages.findIndex((p: IGrowthPercentProperty) => p.property.id === property.id);

    replaceObject.percent = sliderValue;
    replaceObject.property = property;

    this.propertiesWithPercentages.splice(replacePercentagePropertyIndex, 1, replaceObject);
  }

  /**
   * Apply growth percentage values
   */
  applyPercentage(): void {
    this.propertiesWithPercentages.map((propertyWithPercentage: IGrowthPercentProperty) => {
      propertyWithPercentage.property.growthPercent = propertyWithPercentage.percent;
      replace(this.data.properties, propertyWithPercentage.property);
    });

    this.propertyService.putBatch(this.data.properties).subscribe((properties: Property[]) => {
      this.dialogRef.close(properties);
      this.toastService.success(MESSAGE_ADDED);
    });
  }
}
