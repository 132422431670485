import { Directive, HostListener, Input } from '@angular/core';
import { AppEvent, AppEventTypeEnum, EventDispatcherService } from 'taxtank-core';

@Directive({
  selector: '[appOpenChat]'
})
export class OpenChatDirective {

  constructor(
    private eventDispatcherService: EventDispatcherService
  ) { }
  @Input('appOpenChat') employeeId: number;

  @HostListener('click')
  onClick(): void {
    this.eventDispatcherService.dispatch(new AppEvent(AppEventTypeEnum.CLIENT_OPEN_CHAT, this.employeeId));
  }
}
