import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';
import { TankTypeEnum, User } from 'taxtank-core';
import { NgControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

/**
 * Tank type select / radio button form control
 */
@Component({
  selector: 'app-tank-type-select',
  templateUrl: './tank-type-select.component.html',
  styleUrls: ['./tank-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TankTypeSelectComponent extends AbstractControlComponent<number> implements OnInit {
  @ViewChild('matSelect') matSelect: MatSelect;
  @Input() mode: 'select' | 'radioIcons' | 'radioLabels' = 'select';
  @Input() placeholder = 'Select Tank Type';
  @Input() label = 'Tank Type';
  @Input() emptyOptionLabel = 'All Tanks';
  @Input() hasEmptyOption = false;
  @Input() exclude: TankTypeEnum[] = [];
  tankTypes: typeof TankTypeEnum = TankTypeEnum;
  types: TankTypeEnum[];
  user: User;

  constructor(public ngControl: NgControl, protected changeDetectorRef: ChangeDetectorRef) {
    super(ngControl, changeDetectorRef);
  }

  ngOnInit(): void {
    this.user = this.userService.getCacheFirst();
    this.types = this.user.getTankTypes().filter(type => !this.exclude.includes(type));

    // @TODO hide instead of preselect?
    if (this.types.length === 1) {
      // this.ngControl.control.setValue(this.types[0]);
    }
  }
}
