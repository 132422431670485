<div [matMenuTriggerFor]="menu" class="header-menu-button pointer">
  <ng-content select="[slot='menuButtonContent']"></ng-content>
</div>

<mat-menu #menu>
  <ng-content select="[slot='menuListHeader']"></ng-content>

  <button class="header-menu-item"
          *ngFor="let item of items"
          [routerLink]="item.link ? [item.link] : []"
          [attr.id]="item?.id"
          (click)="item.id === 'helpMenuTutorial' ? openCVideoPlayerDialogComponent() : ''">
    <i class="{{item.icon}} is-default ic-primary mr-10"></i>
    <span class="fw-normal c-secondary">{{item.label}}</span>
  </button>

  <ng-content select="[slot='menuListFooter']"></ng-content>
</mat-menu>
