import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { HoldingType, HoldingTypeCategoryEnum } from 'taxtank-core';

/**
 * Dialog component with holding type form
 */
@Component({
  selector: 'app-holding-type-form-dialog',
  templateUrl: './holding-type-form-dialog.component.html',
  styleUrls: ['./holding-type-form-dialog.component.scss']
})
export class HoldingTypeFormDialogComponent {
  categories: typeof HoldingTypeCategoryEnum = HoldingTypeCategoryEnum;
  category: HoldingTypeCategoryEnum = HoldingTypeCategoryEnum.STOCK;

  constructor(
    @Inject(MAT_DIALOG_DATA) public holdingType: HoldingType = plainToClass(HoldingType, {}),
    public dialogRef: MatDialogRef<HoldingTypeFormDialogComponent>
  ) {
  }
}
