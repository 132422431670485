<!-- @Todo TT-2340 Mikhail Create common component with markup and use in each banner via slots -->
<header mat-dialog-title>
  <div>
    <h1 class="c-white mb-15">Sole hustle made easy</h1>
    <span class="fs-big">
      Powerful tax tools to make it easy to manage your side hustle or small business all year round. TaxTank automatically
      tracks you invoices and uses smart tools to highlight now much tax you should be paying all year round.
    </span>
  </div>
  <button mat-dialog-close class="btn icon-close"></button>
</header>

<div mat-dialog-content>
  <div class="banner-image"></div>
  <div class="content-wrapper">
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Monitor business performance</span>
    </div>
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Create and track invoices & expenses</span>
    </div>
    <div class="mb-10">
      <i class="icon mr-10 icon-check ic-primary"></i>
      <span class="text-center fw-600 c-secondary">Real-time Reporting</span>
    </div>
    <button class="btn primary w-100">Start free trial</button>
  </div>
</div>
