import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Directive that does not allow more than one space to be entered and removes spaces at the beginning and end of a line
 * @TODO rename with app prefix
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type=text][inputTrim]'
})
export class InputTrimDirective {

  constructor(
    private control: NgControl
  ) {
  }

  @HostListener('blur')
  onBlur(): void {
    this.control.control.setValue(this.control.control.value.replace(/\s+/g, ' ').trim());
  }
}
