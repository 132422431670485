<div mat-dialog-title>
  <div class="dialog-caption">
    {{holdingType.id ? 'Edit' : 'Create'}} Ticker
  </div>

  <button mat-dialog-close class="btn icon-close"></button>
</div>

<div mat-dialog-content>
  <app-holding-type-form #holdingTypeFormComponent
                         [holdingType]="holdingType"
                         (saved)="dialogRef.close()">
  </app-holding-type-form>
</div>

<div mat-dialog-actions>
  <div class="row">
    <div class="col-12 col-sm text-right">
      <button mat-dialog-close class="btn basic">Cancel</button>
    </div>

    <div class="col-12 col-sm-auto">
      <button [appFormSubmit]="holdingTypeFormComponent" class="btn primary">Save</button>
    </div>
  </div>
</div>
