import { Component } from '@angular/core';

/**
 * UI Component: horizontal line with the label in the center
 */
@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent {}
