import { Component } from '@angular/core';
import { TermsPrivacyDialogComponent } from '@components/terms/terms-privacy-dialog/terms-privacy-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  constructor(
    protected dialog: MatDialog
  ) {
  }

  /**
   * Open 'Terms & privacy' dialog
   */
  openTermsPrivacyDialog(): void {
    this.dialog.open(TermsPrivacyDialogComponent);
  }
}
