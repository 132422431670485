import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService, passwordMatchValidator, passwordValidator, UserMessagesEnum } from 'taxtank-core';
import { HttpErrorResponse } from '@angular/common/http';
import { MESSAGES } from '@shared/component/account-security/messages';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * Component with account security information
 */
@Component({
  selector: 'app-account-security',
  templateUrl: './account-security.component.html',
  styleUrls: ['./account-security.component.scss']
})
export class AccountSecurityComponent extends BaseComponent implements OnInit {
  messages = UserMessagesEnum;
  form: UntypedFormGroup;
  @ViewChild(FormGroupDirective) formRef: FormGroupDirective;

  constructor(protected fb: UntypedFormBuilder, protected toastService: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  /**
   * Build password form
   */
  buildForm(): void {
    this.form = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, passwordValidator()]],
      confirmPassword: ['', Validators.required]
    }, {
      validators: passwordMatchValidator('newPassword', 'confirmPassword')
    });
  }

  /**
   * Submit form and change password
   */
  changePassword(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.userService.changePassword(this.form.get('currentPassword').value, this.form.get('newPassword').value).subscribe(() => {
      this.formRef.resetForm();
      this.toastService.success(MESSAGES.passwordSuccessfullyUpdated);
    }, (errorResponse: HttpErrorResponse) => {

      if (errorResponse.status === 403) {
        this.toastService.error(MESSAGES.cantChangePassword);
      }

      if (errorResponse.error.violations[0].errorPath === 'currentPassword') {
        this.toastService.error(MESSAGES.currentPasswordIsWrong);
      }
    });
  }

  delete(): void {
    this.userService.deactivate(this.currentUser).subscribe();
  }
}
