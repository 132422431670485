import { Directive, HostListener, Input, OnInit } from '@angular/core';
import {
  PropertyCollection,
  PropertyService,
  ServiceSubscriptionCollection,
  SubscriptionService
} from 'taxtank-core';
import {Observable, combineLatest} from 'rxjs';
import {
  SubscriptionBannerUpgradePropertyLimitComponent
} from '@components/subscription-banner/subscription-banner-upgrade/subscription-banner-upgrade-property-limit/subscription-banner-upgrade-property-limit.component';
import {MatDialog} from '@angular/material/dialog';

/**
 * Checks user's amount of properties and compares them with the limit quantity of properties for the current subscription
 * If the limit is exceeded, a subscription dialog opens
 */
@Directive({
  selector: '[appPropertiesLimit]'
})
export class PropertiesLimitDirective implements OnInit {
  /**
   * In case we need to add an amount of properties (i.e. when creating a new property in the form)
   * to the already existing amount of client's properties
   */
  @Input() addablePropertiesAmount = 0;
  properties: PropertyCollection;
  limit: number;

  /**
   * we listen to 'mousedown' event to prevent calling the following element methods
   * https://stackoverflow.com/questions/35274028/stop-mouse-event-propagation#comment96178762_41001184
   */
  @HostListener('mousedown', ['$event']) onMousedown($event: Event): void {
    if (this.properties.length + this.addablePropertiesAmount < this.limit) {
      return;
    }
    this.dialog.open(SubscriptionBannerUpgradePropertyLimitComponent, {
      panelClass: 'dialog-banner'
    });
    $event.stopPropagation();

  }

  constructor(private propertyService: PropertyService, private subscriptionService: SubscriptionService, private dialog: MatDialog) {}

  ngOnInit(): void {
    combineLatest([this.propertyService.get(), this.subscriptionService.getActive()])
      .subscribe(([properties, subscriptions]) => {
        this.properties = properties;
        this.limit = subscriptions.getItems().propertyQuantity;
      });
  }
}
