import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControlComponent } from '../../abstract-control/abstract-control.component';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent extends AbstractControlComponent<Date> {
  @Input() placeholder = 'DD/MM/YYYY';
  @Input() max: Date;
  @Input() min: Date;

  constructor(public ngControl: NgControl, protected changeDetectorRef: ChangeDetectorRef) {
    super(ngControl, changeDetectorRef);
  }
}
