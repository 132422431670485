import { Directive, HostListener } from '@angular/core';

/**
 * Directive which prevent default click behaviour
 */
@Directive({
  selector: '[appPreventDefault]'
})
export class PreventDefaultDirective {
  @HostListener('click', ['$event']) onClick(event: Event): void {
    event.preventDefault();
  }
}
