<header mat-dialog-title>
  <button mat-dialog-close class="btn icon-close"></button>
</header>

<div mat-dialog-content>
  <div class="content-wrapper">
    <h1>Woohoo, you’re all set!</h1>

    <div class="description">
      We’re currently processing your payment which might take a minute. <br>
      Otherwise, just refresh the page to continue working with TaxTank.
    </div>

    <div class="button-area">
      <button mat-dialog-close class="btn primary">Got it!</button>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <div class="logo-container">
    <img src="assets/img/icons/logo.svg" title="logo" alt="logo"/>
  </div>
</div>
