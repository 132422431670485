import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/component/base/base.component';
import { FinancialYear, FinancialYearService, User } from 'taxtank-core';

// todo: rename to app-financial-year-select
@Component({
  selector: 'app-year-chooser',
  templateUrl: './year-chooser.component.html',
  styleUrls: ['./year-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearChooserComponent extends BaseComponent implements OnInit {
  isMobile: boolean;
  lastFinYear = FinancialYear.toFinYear(new Date());

  @Input() user: User;
  @Input() isCollapsed: boolean;

  constructor(private financialYearService: FinancialYearService) {
    super();
  }

  ngOnInit(): void {
    // @TODO Artem use css solution instead
    this.isMobile = this.viewPortService.isMobile;
  }

  switch(year: number): void {
    this.financialYearService.switch(year).subscribe();
  }
}
