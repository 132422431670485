import { Component, Input } from '@angular/core';

/**
 * Component to copy to clipboard provided content by click on button
 */
@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss']
})
export class CopyToClipboardComponent {
  @Input() copyContent: string;
  @Input() label: string;
}
