import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ViewPortService } from '@shared/services/view-port.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@shared/component/confirm-dialog/confirm-dialog.component';

/**
 * @TODO Alex/Artem refactor: 1. looks bad when added to absolute elements 2. inputs should have a similar name format
 * directive adds confirm popup
 */
@Directive({
  selector: '[appConfirm]'
})
export class ConfirmDirective {
  // message text that will be displayed in the popup
  @Input() appConfirm: string;
  @Input() confirmCaption = 'Confirmation required';
  @Input() confirmDialog: boolean;
  @Input() confirmDisabled: boolean;
  @Input() acceptLabel = 'Confirm';
  @Input() rejectLabel = 'Cancel';
  @Output() accepted: EventEmitter<void> = new EventEmitter<void>();
  @Output() rejected: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private confirmationService: ConfirmationService,
    private viewPortService: ViewPortService,
    private el: ElementRef,
    private dialog: MatDialog
  ) {
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    if (this.el.nativeElement.tagName.toLowerCase() === 'form') {
      return;
    }

    this.confirm(event);
  }

  @HostListener('ngSubmit', ['$event'])
  onSubmit(event: Event): void {
    if (this.el.nativeElement.tagName.toLowerCase() !== 'form') {
      return;
    }

    this.confirm(event);
  }

  private confirm(event: Event): void {
    if (this.confirmDisabled) {
      this.accepted.emit();
      return;
    }

    // will confirm material dialog instead of confirm popup
    if (this.viewPortService.isMobile || this.confirmDialog) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: this.appConfirm,
          caption: this.confirmCaption
        },
        panelClass: 'dialog-confirm'
      });

      dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
        if (!isConfirmed) {
          this.rejected.emit();
          return;
        }
        this.accepted.emit();
      });

      return;
    }

    this.confirmationService.confirm({
      target: event.target,
      acceptLabel: this.acceptLabel,
      rejectLabel: this.rejectLabel,
      message: this.appConfirm,
      accept: () => {
        this.accepted.emit();
      },
      reject: () => {
        this.rejected.emit();
      }
    });
  }
}
