<div *ngIf="!bankAccounts" class="bank-connections-add-dialog-container">
  <!-- first step - select bank -->
  <app-banks *ngIf="!connection" (selected)="connection = $event"></app-banks>

  <!-- second step - bank accounts add form for manual/basiq flow -->
  <app-bank-accounts-add *ngIf="connection?.isActive() || connection?.bank.isManual()"
                         [connection]="connection"
                         (saved)="bankAccounts = $event"
                         (back)="restart()"
                         (connectionSaved)="connection = $event">
  </app-bank-accounts-add>
</div>

<!-- last step - new connection or bank accounts added -->
<div *ngIf="bankAccounts" class="bank-connections-add-dialog-container">
  <app-bank-accounts-success class="success"
                             [connection]="connection"
                             [areBankAccountsAdded]="!!bankAccounts.length">
  </app-bank-accounts-success>
</div>
