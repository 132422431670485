import { Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NavigationLink } from './navigation-link';
import { MatTabLink } from '@angular/material/tabs';
import { SWIPER_OPTIONS } from '@shared/component/navigation/swiper-options';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

declare type ColorType = 'primary' | 'secondary';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

export class NavigationComponent {
  @ViewChildren(MatTabLink) tabLinks: QueryList<MatTabLink>;
  @ViewChild('swiper') swiper: SwiperComponent;
  @Input() links: NavigationLink[];
  // style tabs: primary/secondary/light
  @Input() color: ColorType = 'primary';
  carouselConfig: SwiperOptions = SWIPER_OPTIONS;

  slideToActiveTab(index: number) {
    this.swiper.swiperRef.slideTo(index);
  }
}
