import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

/**
 * Function compares values of two form controls.
 * If the value of the second control is less than the first, then a validation error is displayed for second control
 * @param firstControlName
 * @param secondControlName
 * @param control1Alias: alias for 1st form control that should be shown in error message
 * @param control2Alias: alias for 2nd form control that should be shown in error message
 * @param isOppositeControl: flag is set validation error to opposite control (to second by default)
 */
export function lessThanValidator(
  firstControlName: string,
  secondControlName: string,
  control1Alias?: string,
  control2Alias?: string,
  isOppositeControl?: boolean): ValidatorFn {
  return (group: UntypedFormGroup): object => {
    const firstControl: AbstractControl = group.get(firstControlName);
    const secondControl: AbstractControl = group.get(secondControlName);
    // set control to show validation error depends on provided isSwitchControls flag
    const controlToSetError: AbstractControl = isOppositeControl ? firstControl : secondControl;

    if (secondControl.errors && !secondControl.hasError('lessThanValidation')) {
      // return if another validator has already found an error on the secondControl
      return this;
    }

    if ((firstControl.value !== null && secondControl.value !== null) && firstControl.value > secondControl.value) {
      controlToSetError.setErrors({
        lessThanValidation: {
          firstControl: {
            name: firstControlName,
            alias: control1Alias
          },
          secondControl: {
            name: secondControlName,
            alias: control2Alias
          }
        }
      });
    } else {
      controlToSetError.setErrors(null);
    }
  };
}
