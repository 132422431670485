<div class="notification p-15 border-radius bg-white d-flex align-v-top p-relative">
  <div class="unread-badge p-absolute border-radius-50 bg-primary" *ngIf="!notification.isRead"></div>

  <app-image *ngIf="notification.isFromUser()" [src]="notification.sender.file?.publicUrl" class="mr-15"></app-image>
  <i *ngIf="!notification.isFromUser()" class="icon icon-bell is-big mr-15 mt-5 ic-primary"></i>

  <div class="notification-content text-left f-column fs-default text-normal">
    <span class="fw-normal fs-default c-secondary">{{ notification.message }}</span>
    <div class="align-h-space-between">
      <a *ngIf="notification.redirectionLink"
         [routerLink]="notification.redirectionLink"
         class="pointer redirection-link underline fw-700">
        Click to view
      </a>
      <span class="fs-small c-secondary mt-5">{{ notification.createdAt | date }}</span>
    </div>
  </div>
</div>
