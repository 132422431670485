<mat-form-field [class]="inputClass">
  <mat-label class="with-action">
    <div class="align-h-space-between">
      <span>{{ label }}</span>
      <button type="button" appStopPropagation (click)="openAddItemDialog()" class="btn icon icon-plus link">Add Template</button>
    </div>
  </mat-label>

  <mat-select [disabled]="disabled"
              [errorStateMatcher]="errorStateMatcher"
              [placeholder]="placeholder"
              [compareWith]="compareFn"
              [(ngModel)]="value"
              (selectionChange)="onSelect($event.value)"
              (blur)="onTouched()">

    <ng-container *ngIf="options">
      <!-- List mode -->
      <ng-container>
        <mat-option *ngIf="hasEmptyOption" [value]="false">{{emptyOptionLabel}}</mat-option>
        <mat-option [value]="template" *ngFor="let template of options">
          {{template.name}}
        </mat-option>
      </ng-container>

      <!-- Empty list -->
      <mat-option [value]="false" *ngIf="!options.length" [disabled]="true">
        {{emptyMessage}}
      </mat-option>
    </ng-container>
  </mat-select>

  <div matSuffix>
    <app-preloader *ngIf="isLoading" [type]="preloaderTypes.ICON" [size]="20"></app-preloader>
  </div>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
