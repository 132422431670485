import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  SoleDetails,
  SoleDetailsService,
  SoleInvoiceTemplate,
  SoleInvoiceTemplateService,
  SoleInvoiceTemplateTaxTypeEnum
} from 'taxtank-core';
import { NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { InvoiceTemplateFormDialogComponent } from '@client/sole/invoices/components/invoice-template/invoice-template-form-dialog/invoice-template-form-dialog.component';
import { plainToClass } from 'class-transformer';
import { AbstractSelectComponent } from '../abstract-select/abstract-select.component';

@Component({
  selector: 'app-sole-invoice-template-select',
  templateUrl: './sole-invoice-template-select.component.html',
  styleUrls: ['./sole-invoice-template-select.component.scss']
})
export class SoleInvoiceTemplateSelectComponent extends AbstractSelectComponent<SoleInvoiceTemplate> implements OnInit {
  @Input() businessId: number;
  @Input() emptyMessage = 'You haven\'t added any sole businesses yet.';
  @Input() emptyOptionLabel = 'All sole businesses';
  @Input() placeholder = 'Select template';
  @Input() label = 'Template';
  soleDetails: SoleDetails;
  dialogComponent = InvoiceTemplateFormDialogComponent;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    protected dialog: MatDialog,
    private soleInvoiceTemplateService: SoleInvoiceTemplateService,
    private soleDetailsService: SoleDetailsService
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnInit() {
    this.soleDetailsService.getFirst()
      .pipe(takeUntil(this.destroy$))
      .subscribe((soleDetails) => {
        this.soleDetails = soleDetails;
      });

    super.ngOnInit();
  }

  openAddItemDialog(): void {
    super.openAddItemDialog(plainToClass(SoleInvoiceTemplate, {
      business: { id: this.businessId },
      taxType: this.soleDetails.isGST ? SoleInvoiceTemplateTaxTypeEnum.TAX_EXCLUSIVE : SoleInvoiceTemplateTaxTypeEnum.NO_TAX
    }));
  }

  getOptions$(): Observable<SoleInvoiceTemplate[]> {
    return this.soleInvoiceTemplateService.get().pipe(
      map((templates: SoleInvoiceTemplate[]) => templates.filter((template: SoleInvoiceTemplate) => template.business.id === this.businessId))
    );
  }
}
