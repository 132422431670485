import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserService } from 'taxtank-core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class FirmGuard {
  constructor(protected router: Router, protected userService: UserService) {
  }

  canActivate(): Observable<boolean> {
    return this.userService.getFirst().pipe(map((user: User) => this.check(user)));
  }

  check(user: User): boolean {
    if (user.isClient()) {
      this.router.navigateByUrl('/client');
    }

    return true;
  }
}
