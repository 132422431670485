import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe converts number to string with fixed length (count).
 * if value has no enough digits the rest digits fill by 0;
 */
@Pipe({
  name: 'minDigits'
})
export class MinDigitsPipe implements PipeTransform {

  transform(value: number, count: number): string {
    return ('0'.repeat(count) + value.toString()).slice(-count);
  }

}
