import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { PropertyGrowthPercentageComponent } from '@client/shared/components/property/property-growth-percentage/property-growth-percentage.component';
import { MatDialog } from '@angular/material/dialog';
import { Property, PropertyAddForm, PropertyService, ToastService } from 'taxtank-core';
import { PropertiesForm } from '@models';
import { MatTabGroup } from '@angular/material/tabs';

const MESSAGE_ADDED = 'Properties saved';

/**
 * Component with multiple properties forms
 */
@Component({
  selector: 'app-properties-form',
  templateUrl: './properties-form.component.html',
  styleUrls: ['./properties-form.component.scss']
})
export class PropertiesFormComponent implements OnInit, AfterViewInit {
  @ViewChild(FormGroupDirective) formRef: FormGroupDirective;
  @Output() saved: EventEmitter<Property[]> = new EventEmitter<Property[]>();
  form: PropertiesForm;
  // helper flag: logic for tabs labels error state
  isSubmitFired: boolean;

  constructor(
    private propertyService: PropertyService,
    private toastService: ToastService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngAfterViewInit() {
    // mark tabs labels as invalid only after try of submit
    this.formRef.ngSubmit.subscribe(() => {
      this.isSubmitFired = true;
    });
  }

  buildForm(): void {
    this.form = new PropertiesForm([
      new PropertyAddForm()
    ]);
  }

  save() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.propertyService.postBatch(this.form.submit()).subscribe((properties: Property[]) => {
      this.toastService.success(MESSAGE_ADDED);
      this.openGrowthPercentageDialog(properties);
    });
  }

  /**
   * Show popup with properties growth percent settings
   * @param properties
   */
  openGrowthPercentageDialog(properties: Property[]): void {
    const dialogRef = this.dialog.open(PropertyGrowthPercentageComponent, {
      data: { properties }
    });

    dialogRef.afterClosed().subscribe((propertiesWithGrowthPercent: Property[]) => {
      this.saved.emit(propertiesWithGrowthPercent || properties);
    });
  }

  addProperty($event: MouseEvent, tabGroup: MatTabGroup) {
    $event.stopPropagation();
    this.form.addProperty();
    tabGroup.selectedIndex = this.form.controls.length;
  }
}
