import { Component, Input } from '@angular/core';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * UI component: Block with title, number value and optional footer
 */
@Component({
  selector: 'app-data-card',
  templateUrl: './data-card.component.html',
  styleUrls: ['./data-card.component.scss']
})
export class DataCardComponent extends BaseComponent {
  @Input() header: string;
  @Input() withoutFraction: boolean = false;
  @Input() isBankCard: boolean = false;
  @Input() isTextLeft: boolean;
}
