<ng-container [appPreloader]="[endpoints.USER_STATUS_PUT]" [preloaderType]="preloaderTypes.OVERLAY">
  <header mat-dialog-title>
    <div class="dialog-caption">Welcome to TaxTank</div>
  </header>

  <div mat-dialog-content>
    <div class="description">
      We're really excited that you have joined <b>TaxTank</b>. It takes just 3 simple steps to onboard and start paying
      less tax.

      <div class="mv-20">
        <div class="align-v-center mb-15">
          <img src="assets/img/icons/low-startup-cost.png" class="icon">
          <span class="fw-700 c-secondary ml-15">
          Step 1: Add your salary and wages
        </span>
        </div>
        <div class="align-v-center mb-15">
          <img src="assets/img/icons/passive-income.png" class="icon">
          <span class="fw-700 c-secondary ml-15">
          Step 2: Add your other incomes and bonuses
        </span>
        </div>
        <div class="align-v-center mb-15">
          <img src="assets/img/icons/home-office.png" class="icon">
          <span class="fw-700 c-secondary ml-15">
          Step 3: Add your property details
        </span>
        </div>
      </div>

      Estimations are fine, you can always update later. You can even skip and get to the good stuff straight away!
    </div>
    <div class="button-area">
      <button class="btn primary" (click)="startOnboarding()">Onboard now</button>
      <button class="btn primary inverted" (click)="skipOnboarding()">Skip</button>
    </div>
  </div>

  <div mat-dialog-actions>
    <div class="logo-container">
      <img src="assets/img/icons/logo.svg" title="logo" alt="logo"/>
    </div>
  </div>
</ng-container>
