<header mat-dialog-title>
  <button mat-dialog-close class="btn icon-close"></button>
</header>

<div mat-dialog-content>
  <i class="icon icon-thank-you"></i>
  <div class="content-wrapper">
    <h1>
      Thanks for trialling taxtank, we'll miss you.
    </h1>

    <div class="description">
      So we can continue to improve we'd love to get your feedback.
    </div>

    <div class="button-area">
      <button class="btn primary" (click)="leaveFeedback()">Feedback</button>
      <button class="btn link" (click)="dialogRef.close()">Skip</button>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <div class="logo-container">
    <img src="assets/img/icons/logo.svg" title="logo" alt="logo"/>
  </div>
</div>
