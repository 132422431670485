import { Directive } from '@angular/core';

/**
 * Directive to mark the DOM element as exportable
 */
@Directive({
  selector: '[appExportable]'
})
export class ExportableDirective {
}
