import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { UserService } from 'taxtank-core';

@Directive({
  selector: '[appAccessDisable]'
})
export class AccessDisableDirective {
  constructor(private userService: UserService, private el: ElementRef, private renderer: Renderer2) { }

  @Input() set appAccessDisable(userId: number) {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', userId !== this.userService.getCacheFirst().id);
  }
}
