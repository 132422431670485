<p-multiSelect [options]="options"
               [showClear]="true"
               [placeholder]="placeholder"
               [(ngModel)]="value"
               (ngModelChange)="onChange($event?.length === 0 ? null : $event)"
               class="holding-type-multiselect"
               filterBy="name,ticker"
               optionLabel="name"
               dropdownIcon="icon-caret-down">

  <ng-template let-type pTemplate="item">
    {{type.name}}
    <ng-container *ngIf="type.ticker">({{type.ticker}})</ng-container>
  </ng-template>
</p-multiSelect>
