<form [formGroup]="form" appFormErrorScroll class="border-radius">
  <app-validation-error [errors]="form.errors"></app-validation-error>

  <div class="row p-relative" *ngFor="let businessAllocationGroup of form.controls; let index = index" [formGroup]="businessAllocationGroup">
    <div class="col-12">
      <div class="row align-v-center">
        <div class="col">
          <div class="fs-big fw-bold c-secondary">Business {{index + 1}}</div>
        </div>

        <div class="col-auto">
          <button *ngIf="form.controls.length > 1" class="btn icon-close" (click)="form.removeAt(index)"></button>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <app-business-select formControlName="business" label=""></app-business-select>
    </div>

    <!-- classes: Show remove button when added 2 and more business allocations -->
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>Percent</mat-label>
        <input matInput type="number" formControlName="percent" placeholder="Enter Percent"/>
        <mat-error *ngIf="businessAllocationGroup.get('percent').invalid">
          <app-validation-error [errors]="businessAllocationGroup.get('percent').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

<button class="btn link primary ml-20" type="button" (click)="form.add()">
  <i class="fa fa-plus-circle ic-primary mr-5"></i>
  Add business
</button>
