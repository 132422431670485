import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

/**
 * Custom form control for new password and confirmation of new password
 */
@Component({
  selector: 'app-password-matcher',
  templateUrl: './password-matcher.component.html',
  styleUrls: ['./password-matcher.component.scss']
})
export class PasswordMatcherComponent {
  @Input() form: UntypedFormGroup;
  @Input() passwordFieldName = 'password';
  @Input() confirmFieldName = 'confirmPassword';
}
