<ng-container [appPreloader]="[endpoints.PROPERTIES_POST]"
              [preloaderType]="preloaderTypes.OVERLAY">
</ng-container>

<div mat-dialog-title>
  <div class="dialog-caption">Add Properties</div>
  <button mat-dialog-close class="btn icon-close"></button>
</div>

<div mat-dialog-content id="addProperty">
  <app-properties-form #propertiesFormComponent (saved)="dialogRef.close()"></app-properties-form>
</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-md-auto">
      <button mat-dialog-close class="btn basic w-100">Cancel</button>
    </div>
    <div class="col-6 col-md-auto">
      <button type="submit"
              name="addPropertiesSubmit"
              class="btn primary w-100"
              (click)="propertiesFormComponent.formRef.ngSubmit.emit()">
        Add properties
      </button>
    </div>
  </div>
</div>

