<div mat-dialog-title>
  <div class="dialog-caption">Select Bank</div>
  <button mat-dialog-close class="btn icon-close"></button>
</div>

<div mat-dialog-content class="bank-dialog-content">
  <ng-container [appPreloader]="[endpoints.BANKS_GET, endpoints.BANK_ACCOUNTS_GET, endpoints.BASIQ_USER_POST]"
                [preloaderType]="preloaderTypes.OVERLAY">
  </ng-container>

  <!-- @TODO Alex: implement better solution for listbox:
   Extend Prime Listbox and improve design and filtration or create a custom component.
   Problems:
   - filtering should not destroy list items, but just hide them (images blinking problem);
   - add delay before filtration start
   - show preloaders while data filtering or loading -->
  <app-preloader *ngIf="hasSelected"></app-preloader>
  <p-listbox *ngIf="banks.length"
             [options]="banks"
             [filter]="true"
             filterBy="shortName"
             filterPlaceHolder="Search"
             scrollHeight="auto"
             optionLabel="shortName">
    <ng-template let-bank pTemplate="item">
      <app-basiq-connect [bank]="bank" (click)="hasSelected = true" (connected)="select($event)" >
        <button class="btn bank">
          <app-image [src]="bank | call:'getPhoto'" [size]="60" format="square"></app-image>

          <div class="bank-name">
            {{bank.shortName}}
            <i *ngIf="bank.isManual()" class="icon-info pointer" matTooltip="Live bank feeds are not available"></i>
          </div>
        </button>
      </app-basiq-connect>
    </ng-template>
  </p-listbox>
</div>
