<app-preloader *ngIf="isLoading" [type]="preloaderTypes.OVERLAY"></app-preloader>

<div mat-dialog-title>
  <div class="dialog-caption c-primary">Payment details</div>

  <button mat-dialog-close class="btn icon-close ic-secondary"></button>

  <div class="align-h-space-between c-secondary fw-normal fs-big">
    <div>Total to pay</div>
    <div>{{data.subscription.price | customCurrency}}/{{ data.subscription.frequency }}</div>
  </div>
</div>

<div mat-dialog-content>
  <div *ngIf="errorMessage" id="error-message" class="form-group-error">
    {{errorMessage}}
  </div>

  <ng-container *ngIf="(paymentMethods$ | async) as paymentMethods">

    <mat-radio-group *ngIf="paymentMethods.length" class="default full-width" [(ngModel)]="selectedPaymentMethod">
      <mat-radio-button *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod" color="primary"
                        class="d-block pv-15">
        <div class="align-h-space-between align-v-center">
          <div class="c-secondary fs-default fw-bold">
            {{paymentMethod.description}}
            <div *ngIf="paymentMethod.isDefault" class="status-badge-approved ml-10">Primary</div>
          </div>

          <div *ngIf="paymentMethod.logo; else bankIcon"
               class="payment-method-logo mr-10"
               [ngStyle]="{'backgroundImage': 'url(' + paymentMethod.logo + ')'}">
          </div>

          <ng-template #bankIcon>
            <div class="payment-method-logo align-v-center align-h-center mr-10">
              <i class="icon-bank ic-text"></i>
            </div>
          </ng-template>
        </div>
      </mat-radio-button>

      <mat-radio-button class="d-block pv-15" color="primary" [value]="null">Another payment method</mat-radio-button>
    </mat-radio-group>
  </ng-container>

  <form [class.d-none]="selectedPaymentMethod !== null" id="payment-form" class="p-15">
    <!-- Elements will create form elements here -->
    <div id="payment-element"></div>
  </form>
</div>

<div mat-dialog-actions>
  <div class="row g-3 align-h-right">
    <div class="col-6 col-sm-auto">
      <button mat-dialog-close class="btn basic w-100">Cancel</button>
    </div>
    <div class="col-6 col-sm-auto">
      <button id="submit" class="btn primary w-100" (click)="submit()" [disabled]="selectedPaymentMethod === undefined">
        Subscribe
      </button>
    </div>
  </div>
</div>
