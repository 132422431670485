import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FileType } from '../file.type';
import { MIME_TYPES } from '../mime-types.const';
import { AppFile, FileService, ToastService } from 'taxtank-core';
import { NgControl } from '@angular/forms';
import { AbstractControlComponent } from '../../abstract-control/abstract-control.component';
import { Observable, combineLatest } from 'rxjs';
import { FileUpload } from 'primeng/fileupload';
import { FILE_TYPE_LABELS } from '@shared/component/form-controls/file/file-type-labels';

/**
 * File upload component based on Prime file uploader
 * https://primeng.org/fileupload
 */
@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent extends AbstractControlComponent<AppFile | AppFile[]> implements OnInit, AfterViewInit {
  @ViewChild(FileUpload, { static: false }) pFileUpload: FileUpload;

  /**
   * Custom text listing types
   */
  acceptLabel?: string;

  /**
   * List of file types accepted to upload
   */
  @Input() fileTypes: FileType[] = ['image', 'document', 'csv', 'excel'];

  /**
   * Flag enable/disable multiple files upload
   */
  @Input() multiple = false;

  /**
   * Maximum file size in megabytes
   */
  @Input() maxFileSizeMb = 25;

  /**
   * Prime file uploader UI mode
   */
  @Input() mode: 'button' | 'dragdrop' | 'icon' = 'button';

  @Input() caption = 'link';
  @Output() changed: EventEmitter<void> = new EventEmitter<void>();
  @Input() chooseIcon = 'icon-upload-cloud';

  /**
   * Mime types accepted by file input
   */
  accept: string;

  /**
   * Maximum file size in bytes
   */
  maxFileSize: number;

  isDragging: boolean;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private fileService: FileService,
    private toastService: ToastService
  ) {
    super(ngControl, changeDetectorRef);
  }

  /**
   * set prime file uploader validators
   */
  ngOnInit(): void {
    this.accept = this.fileTypes.map((fileType: FileType) => MIME_TYPES[fileType]).join();
    this.acceptLabel = this.fileTypes.map((fileType: FileType) => FILE_TYPE_LABELS[fileType]).join(', ');
    this.maxFileSize = this.maxFileSizeMb * 1000000;
  }

  /**
   * Overwrite prime file uploader methods
   */
  ngAfterViewInit(): void {
    this.pFileUpload.upload = this.upload.bind(this);
    this.pFileUpload.validate = this.validate.bind(this);
  }

  /**
   * Custom file validation method for prime file uploader
   */
  private validate(file: File): boolean {
    this.pFileUpload.msgs = [];

    // validate file type
    if (this.accept && !this.accept.includes(file.type)) {
      const summary = `${file.name}: Invalid file type`;
      this.pFileUpload.msgs.push({
        severity: 'error',
        summary: summary
      });

      // basic mode has no place for error messages, so we show toasts
      if (this.mode === 'button') {
        this.toastService.error(summary);
      }

      return false;
    }

    // validate file size
    if (this.maxFileSize && file.size > this.maxFileSize) {
      const summary = `${file.name}: Invalid file size`;
      this.pFileUpload.msgs.push({
        severity: 'error',
        summary: summary
      });

      // basic mode has no place for error messages, so we show toasts
      if (this.mode === 'button') {
        this.toastService.error(summary);
      }

      return false;
    }

    return true;
  }

  /**
   * Custom file upload method for prime file uploader
   */
  private upload(): void {
    // init requests batch
    // we are using batch because prime file uploader always works with array, even with single mode
    const batch: Observable<AppFile>[] = [];

    this.pFileUpload.files.forEach((file: File) => {
      batch.push(this.fileService.upload(file));
    });

    combineLatest(batch).subscribe((files: AppFile[]) => {
      this.value = this.multiple ? files : files[0];
      this.onChange(this.value);
      this.pFileUpload.clear();
      this.changeDetectorRef.markForCheck();
      this.changed.emit();
    })
  }

  clear(): void {
    this.value = null;
    this.onChange(null);
    this.pFileUpload.clear();
  }

  ondragenter(e) {
    this.isDragging = true;
    e.stopPropagation();
    e.preventDefault();
  }

  ondragleave(e) {
    this.isDragging = false;
    e.stopPropagation();
    e.preventDefault();
  }

  ondrop(e) {
    this.isDragging = false;
    this.pFileUpload.onDrop(e);
  }
}
