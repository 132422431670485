import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BankAccount,
  BankAccountCollection,
  BankAccountService,
  BankAccountTypeEnum,
  BankAccountsImportForm,
  BankConnection,
  BankConnectionMessagesEnum,
  BankConnectionService,
  BasiqService,
  BasiqTokenService,
} from 'taxtank-core';
import { BaseFormComponent } from '@shared/component/base/base-form.component';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { BankAccountImportFormDialogComponent } from '../bank-account-import-form-dialog/bank-account-import-form-dialog.component';

/**
 * Component with form with basiq bank accounts allowed to import
 */
@Component({
  selector: 'app-bank-accounts-import',
  templateUrl: './bank-accounts-import-form.component.html',
  styleUrls: ['./bank-accounts-import-form.component.scss']
})
export class BankAccountsImportFormComponent extends BaseFormComponent implements OnInit {
  @Input() connection: BankConnection;
  @Output() submitted: EventEmitter<BankAccount[]> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();
  bankAccounts: BankAccountCollection;
  savedAccounts: BankAccountCollection;
  form: BankAccountsImportForm;
  messages: typeof BankConnectionMessagesEnum = BankConnectionMessagesEnum;
  bankAccountTypes = BankAccountTypeEnum;

  nothingSelectedErrorMessage = 'Select at least one bank account';

  constructor(
    private bankAccountService: BankAccountService,
    private basiqService: BasiqService,
    private connectionService: BankConnectionService,
    private basiqTokenService: BasiqTokenService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.bankAccountService.get(), this.basiqService.get()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([bankAccounts, basiqBankAccounts]) => {
        this.savedAccounts = bankAccounts.filterBy('bankConnection.id', this.connection.id);
        this.bankAccounts = basiqBankAccounts.filterBy('bankConnection.id', this.connection.id)
          .removeBy('accountId', this.savedAccounts.mapBy('accountId'));
        this.buildForm();
      });
  }

  /**
   * for mobile only: Open separated dialog with single bank account import form
   */
  openBankAccountImportFormDialog(bankAccount: BankAccount): void {
    const dialogRef = this.dialog.open(BankAccountImportFormDialogComponent, {
      data: bankAccount
    });

    dialogRef.afterClosed().subscribe((bankAccounts: BankAccount[]) => {
      // dialog closed without submit
      if (!bankAccounts) {
        return;
      }

      // form submitted
      this.submitted.emit(bankAccounts);
    });
  }

  buildForm(): void {
    this.form = new BankAccountsImportForm(this.bankAccounts.toArray());
  }

  save(bankAccounts: BankAccount[]): void {
    this.submitted.emit(bankAccounts);
  }

  reconnect(connection: BankConnection): void {
    combineLatest([this.basiqService.deleteBasiq(connection), this.basiqTokenService.getFirst()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([connection, token]) => {
        this.basiqService.redirectToBasiqConsent(token.value, connection.bank.externalId, !!this.userService.getCacheFirst().clientDetails.basiqConsentId);
      });
  }
}
