<div class="dnd">
  <div class="dnd-label" *ngIf="label">{{label}}</div>

  <div class="dnd-area" (click)="fileInput.click()" appDragNDrop (onFileDropped)="onSelect($event)">

    <input #fileInput
           [multiple]="multiple"
           [accept]="mimeTypes"
           hidden
           type="file"
           (change)="onSelect($event.target.files); fileInput.value = null">

    <ng-container [ngSwitch]="true">
      <!-- Empty -->
      <ng-container *ngSwitchCase="!value">
        <div class="dnd-info">
          <div class="dnd-icon-container">
            <i class="icon-upload-cloud"></i>
          </div>

          <div class="dnd-details">
            <div class="dnd-caption">{{ caption }}</div>
            <div class="dnd-placeholder">{{ placeholder }}</div>
            <div class="dnd-description">
              Allowed types: {{ allowedTypes }}
              <app-validation-error [errors]="ngControl.errors"></app-validation-error>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Single selected file -->
      <ng-container *ngSwitchCase="value && !multiple">
        <div class="dnd-info">
          <div class="dnd-icon-container">
            <i class="icon-doc"></i>
          </div>

          <div class="dnd-details">
            <div class="dnd-caption">{{value['originalName'] || value.name}}</div>
            <div class="dnd-placeholder">{{ value.size | fileSizeConvert }}</div>
            <div class="dnd-description" *ngIf="ngControl.errors">
              <div class="c-error" *ngIf="ngControl.control.hasError('fileExtensions')">
                Allowed file types: {{ngControl.errors['fileExtension']['allowedExtensions']}}
              </div>

              <div class="c-error" *ngIf="ngControl.control.hasError('fileMaxSize')">
                Maximum file size: {{ngControl.errors['fileMaxSize']['maxSize'] | fileSizeConvert}}
              </div>
            </div>
          </div>

          <button appStopPropagation
                  class="btn dnd-remove-button icon-close"
                  type="button"
                  (click)="onSelect(null)"
                  title="Remove file">
          </button>
        </div>
      </ng-container>

      <!-- Multi selected files -->
      <ng-container *ngSwitchCase="value && multiple">
        <div class="dnd-info">
          <div class="dnd-icon-container">
            <i class="icon-doc"></i>
          </div>

          <div class="dnd-details">
            <div class="dnd-caption">{{value.length}} file{{value.length > 1 ? 's' : ''}} selected</div>
            <div class="dnd-description">
              <app-validation-error [errors]="ngControl.errors"></app-validation-error>
            </div>
          </div>

          <button appStopPropagation
                  class="btn dnd-remove-button icon-close"
                  type="button"
                  (click)="onSelect(null)"
                  title="Remove all files">
          </button>
        </div>

        <mat-chip-listbox *ngIf="multiple" class="mt-10" [selectable]="false">
          <mat-chip-option *ngFor="let file of value" [disableRipple]="true" class="pr-0" [class.invalid]="file['isInvalid']">
            {{value['originalName'] || file.name}} ({{file.size | fileSizeConvert}})
            <button appStopPropagation
                    class="btn dnd-remove-button icon-close"
                    type="button"
                    (click)="onSelect(null)"
                    title="Remove file">
            </button>
          </mat-chip-option>
        </mat-chip-listbox>
      </ng-container>
    </ng-container>

  </div>
</div>
