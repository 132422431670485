import {
  Directive, ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  inject
} from '@angular/core';
import { chart, color } from 'highcharts';
import { CustomCurrencyPipe } from '@shared/pipe/custom-currency/custom-currency.pipe';
import { COLORS, PALETTE_EQUITY } from '@shared/globals/colors';
import {
  BankAccountCollection,
  FinancialYear,
  LoanCollection,
  PropertyCollection,
  PropertyEquityChartData,
  PropertyValuationCollection, UserService
} from 'taxtank-core';

// Save component to variable to use it inside anonymous formatter functions
let self: PropertyEquityPositionChartDirective;

/**
 * Directive with properties equity position chart
 */
@Directive({
  selector: '[appPropertyEquityPositionChart]',
})
export class PropertyEquityPositionChartDirective implements OnChanges {
  // Array of properties to display equity position chart
  @Input() properties: PropertyCollection;
  @Input() bankAccounts: BankAccountCollection;
  @Input() loans: LoanCollection;
  @Input() valuations: PropertyValuationCollection;
  // Equity position collection based on passed properties
  equityPositionData: PropertyEquityChartData;
  // Chart object
  chart: any;
  // Chart settings
  chartOptions: object;
  // Chart data that contains series and their options
  chartData: any[];
  private userService: UserService;

  constructor(public customCurrencyPipe: CustomCurrencyPipe, private el: ElementRef) {
    this.userService = inject(UserService);
    self = this;
  }

  /**
   * Redraw chart when income data (properties list) changed
   */
  ngOnChanges(changes: SimpleChanges) {
    if (!changes.bankAccounts) {
      return;
    }

    const data = this.makeChartData();
    this.setOptions(data);
    !this.chart ? this.makeChart() : this.chart.update(this.chartOptions, true);
  }

  makeChartData() {
    return new PropertyEquityChartData(
      this.properties,
      this.valuations,
      this.bankAccounts,
      this.loans,
      this.userService.getCacheFirst().createdAt
    );
  }
  /**
   * Set options to chart object
   */
  setOptions(data: PropertyEquityChartData): void {
    this.chartOptions = {
      // Disable HighChart credit line on chart
      credits: {
        enabled: false
      },
      // Disable chart title
      title: null,
      // Disable chart legend
      legend: {
        enabled: false
      },
      // X axis settings
      xAxis: {
        labels: {
          // Labels style
          style: {
            color: COLORS.grey,
            fontWeight: 'bold',
            fontSize: '12px',
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'uppercase'
          }
        },
        // Disable short vertical line from xAxis line to xAxis label
        tickLength: 0,
        // Show label for each year and not for half years
        tickInterval: 1,
        // Show vertical dashed line on chart hover
        crosshair: {
          color: COLORS.grey,
          dashStyle: 'Dash'
        },
        plotLines: [
          {
            color: COLORS.grey,
            width: 1,
            value: new FinancialYear(new Date()).year,
            label: {
              text: 'Current Fin Year',
              color: COLORS.grey,
            }
          }
        ]
      },
      // Hide Y axis
      yAxis: {
        title: null,
        visible: false
      },
      // Common chart settings
      chart: {
        backgroundColor: 'transparent',
        type: 'areaspline',
      },
      // Colors used for chart
      colors: PALETTE_EQUITY,
      // Data line options
      plotOptions: {
        areaspline: {
          // Chart data point style
          marker: {
            fillColor: COLORS.white,
            lineWidth: 2,
            // Inherit from series settings
            lineColor: null,
            symbol: 'circle'
          }
        },
        // Chart data settings
        series: {
          point: {
            // Chart events settings
            events: {
              mouseOver: function (): void {
                // Show equity position point on hovered year
                const equity: number = self.chart.series[0].yData[this.index] - self.chart.series[1].yData[this.index];
                if (!self.chart.get('equityPosition')) {
                  self.chart.addSeries({
                    id: 'equityPosition',
                    name: 'Equity Position',
                    data: [[this.x, equity]],
                    color: PALETTE_EQUITY[2],
                    enableMouseTracking: false,
                    states: {
                      inactive: {
                        opacity: 1
                      }
                    }
                  });
                }
              },
              mouseOut: function (): void {
                // Remove equity position point from chart
                self.chart.get('equityPosition')?.remove();
              }
            }
          }
        }
      },
      // Settings for tooltip displayed by chart hover
      tooltip: {
        backgroundColor: COLORS.white,
        borderWidth: 1,
        borderRadius: 4,
        shadow: false,
        useHTML: true,
        shared: true,
        style: {
          padding: 15
        },
        formatter: function (): string {
          // Hover Tooltip content
          const mvPoint = this.points.find(p => p.series.name === 'Market Value');
          const lbPoint = this.points.find(p => p.series.name === 'Loan Balance');
          return `<div style="color: ${COLORS.grey}; font-size: 12px; line-height: 18px; font-weight: bold; text-transform: uppercase; font-family: Montserrat, sans-serif">
                    <span style="color:${mvPoint?.color}">●</span>
                    Market Value
                  </div><br>
                  <div style="font-size: 18px; font-weight: bold; font-family: Montserrat, sans-serif">${self.customCurrencyPipe.transform(mvPoint?.y)}</div><br>
                  <div style="color: ${COLORS.grey}; font-size: 12px; line-height: 18px; font-weight: bold; text-transform: uppercase; font-family: Montserrat, sans-serif; display: ${lbPoint ? 'block' : 'none'}">
                    <span style="color:${lbPoint?.color}">●</span>
                    Loan Balance
                  </div><br>
                  <div style="font-size: 18px; font-weight: bold; font-family: Montserrat, sans-serif; display: ${lbPoint ? 'block' : 'none'}">${self.customCurrencyPipe.transform(lbPoint?.y)}</div><br>
                  <div style="color: ${COLORS.grey}; font-size: 12px; line-height: 18px; font-weight: bold; text-transform: uppercase; font-family: Montserrat, sans-serif">
                    <span style="color:${PALETTE_EQUITY[2]}">●</span>
                    Equity Position
                  </div><br>
                  <div style="font-size: 18px; font-weight: bold; font-family: Montserrat, sans-serif">
                    ${self.customCurrencyPipe.transform(mvPoint?.y - (lbPoint?.y || 0))}
                  </div>`;
        }
      },
      // Set data for chart
      series: data.getChartData().map((d: any, index: number) => ({
        name: d.name,
        data: d.series,
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, color(PALETTE_EQUITY[index]).setOpacity(0.15).get('rgba')],
            [1, color(PALETTE_EQUITY[index]).setOpacity(0).get('rgba')]
          ]
        },
        zoneAxis: 'x',
        zones: d.zones
      }))
    };
  }

  /**
   * creates chart in directive's element
   */
  makeChart(): void {
    this.chart = chart(this.el.nativeElement, this.chartOptions);
  }
}
