import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AbstractControlComponent} from "@shared/component/form-controls/abstract-control/abstract-control.component";

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordInputComponent extends  AbstractControlComponent<string> {
  @Input() label = 'Password';
  @Input() feedback = true;
  @Input() toggleMask = true;
  @Input() placeholder = 'Enter password';
  @Input() required = true;
  @Input() minlength: number = 6;
}
