import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { VehicleClaim, VehicleClaimCollection, VehicleClaimService } from 'taxtank-core';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';
import { NgControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

/**
 * Vehicle claim work usage
 * @TODO Alex: think, maybe create abstract slider form control and move common logic there
 */
@Component({
  selector: 'app-vehicle-claim-work-usage-slider',
  templateUrl: './vehicle-claim-work-usage-slider.component.html',
  styleUrls: ['./vehicle-claim-work-usage-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleClaimWorkUsageSliderComponent extends AbstractControlComponent<number> implements OnInit, OnChanges {
  /**
   * Current vehicle claim handled by form
   */
  @Input() vehicleClaim?: VehicleClaim;

  /**
   * List of all user's vehicle claims
   */
  private vehicleClaims: VehicleClaimCollection;
  /**
   * The limit of work usage percent user could claim
   */
  workUsageLimit: number = VehicleClaim.totalWorkUsagePercent;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private vehicleClaimService: VehicleClaimService
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vehicleClaim) {
      this.updateCalculations();
    }
  }

  ngOnInit(): void {
    // can't get transactions and depreciations if claim does not exist (no claim id)
    if (!this.vehicleClaim) {
      return;
    }

    // get all user's vehicle claims
    this.vehicleClaimService.get()
      .pipe(takeUntil(this.destroy$))
      .subscribe((vehicleClaims: VehicleClaimCollection) => {
        this.vehicleClaims = vehicleClaims;
        this.updateCalculations();
      });
  }

  /**
   * Handle slider changes
   */
  onSlide(value: number) {
    // @TODO Alex: add 'this.value = value' when custom form controls refactoring finished
    this.onChange(value);
    this.updateCalculations();
  }

  /**
   * Format label for percent slider
   */
  formatPercentageLabel(value: number): string {
    return `${Math.round(value)}%`;
  }

  /**
   * Calculate additional data for user
   */
  private updateCalculations(): void {
    // nothing to calculate when current claim or all claims not exist
    if (!this.vehicleClaim || !this.vehicleClaims) {
      return;
    }

    this.workUsageLimit = this.vehicleClaims.getWorkUsageLimitForClaim(this.vehicleClaim);
    this.changeDetectorRef.markForCheck();
  }
}
