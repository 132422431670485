import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionChangeDialogComponent } from '@client/shared/components/subscription/subscription-change-dialog/subscription-change-dialog.component';
import { SubscriptionBannerUpgradeComponent } from '@components/subscription-banner/subscription-banner-upgrade/subscription-banner-upgrade.component';
import { ServiceProductIdEnum } from 'taxtank-core';

/**
 * Component with user's properties limit information
 */
@Component({
  selector: 'app-subscription-banner-upgrade-property',
  templateUrl: './subscription-banner-upgrade-property.component.html',
  styleUrls: ['./subscription-banner-upgrade-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionBannerUpgradePropertyComponent {
  constructor(private dialogRef: MatDialogRef<SubscriptionBannerUpgradeComponent>, public dialog: MatDialog) {}

  openSubscriptionDialog(): void {
    this.dialog.open(SubscriptionChangeDialogComponent, {
      panelClass: 'dialog-large',
      data: ServiceProductIdEnum.PROPERTIES
    });

    this.dialogRef.close();
  }
}
