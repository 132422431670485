<button id="toggle-sidenav-button"
        class="btn toggle-sidenav-button {{isBurger ? 'burger' : 'default'}}"
        [class.opened]="isSidebarOpened"
        (click)="clicked.emit()">

  <ng-container *ngIf="isBurger">
    <span class="toggle-sidenav-button-line"></span>
    <span class="toggle-sidenav-button-line"></span>
    <span class="toggle-sidenav-button-line"></span>
  </ng-container>

  <ng-container *ngIf="!isBurger">
    <i class="{{isSidebarOpened ? 'fa fa-chevron-left' : 'fa fa-chevron-right'}}"
       matTooltipPosition="above"
       matTooltip="{{isSidebarOpened ? '' : 'Toggle sidebar'}}">
    </i>

    <span *ngIf="isSidebarOpened" class="label">Collapse sidebar</span>
  </ng-container>
</button>
