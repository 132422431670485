<form [formGroup]="form" appFormErrorScroll>

  <div class="row">
    <!-- Address form component -->
    <div class="col-12">
      <app-address-input formControlName="address"></app-address-input>
    </div>

    <!-- Category select -->
    <div class="col-12 col-md-6">
      <mat-form-field [formGroup]="form.categoryMovementForm">
        <mat-label>Portfolio</mat-label>
        <mat-select id="onBoardingPropertyCategorySelect"
                    formControlName="propertyCategory"
                    placeholder="Select Category"
                    required>
          <mat-option *ngFor="let category of categories"
                      [value]="category">{{category.name}}</mat-option>
        </mat-select>

        <div matSuffix>
          <ng-container [appPreloader]="[endpoints.PROPERTIES_CATEGORIES_GET]"
                        [preloaderType]="preloaderTypes.ICON"
                        [preloaderSize]="20">
          </ng-container>
        </div>

        <mat-error *ngIf="form.categoryMovementForm.get('propertyCategory').invalid">
          <app-validation-error [errors]="form.categoryMovementForm.get('propertyCategory').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Property Share -->
    <div class="col-12 col-md-6">
      <app-property-share-form [form]="form.shareForm" [isTransparent]="true"></app-property-share-form>
    </div>

    <!-- Purchase date datepicker -->
    <div class="col-12 col-md-6">
      <app-datepicker label="Contract Date" [max]="now" formControlName="contractDate"></app-datepicker>
    </div>

    <!-- Settlement date datepicker -->
    <div class="col-12 col-md-6">
      <app-datepicker label="Settlement Date" formControlName="settlementDate"></app-datepicker>
    </div>

    <!-- Purchase price input -->
    <div class="col-12 col-md-6">
      <app-currency-input min="0"
                          inputId="onBoardingPropertyPurchaseAdd"
                          formControlName="purchasePrice"
                          label="Purchase Price"
                          placeholder="Enter Purchase Price">
      </app-currency-input>
    </div>

    <!-- Valuation form group -->
    <div class="col-12 col-md-6" [formGroup]="form.valuationForm">
      <app-currency-input min="0"
                          inputId="onBoardingPropertyCurrentAdd"
                          formControlName="marketValue"
                          label="Market Value"
                          placeholder="Enter Market Value">
      </app-currency-input>
    </div>

    <!-- Forecast form -->
    <div class="col-12">
      <h6 class="mb-15">Annual Forecasts (estimations are fine)</h6>
      <app-property-forecast-form [form]="form.forecastForm"
                                  [isTransparent]="true"
                                  [hideMarketValue]="true"
                                  [category]="form.categoryMovementForm.get('propertyCategory').value">
      </app-property-forecast-form>
    </div>
  </div>
</form>
