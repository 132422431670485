import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ColorTypeEnum } from '@shared/enums/color-type.enum';

/**
 * Progress bar component show scale filled by percent value
 */
@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements OnChanges {
  // progress bar value
  @Input() value: number;
  @Input() color: ColorTypeEnum;
  // total value (100%) for percent calculation
  @Input() total: number;
  @Input() label: string;
  percent: number;

  ngOnChanges(changes: SimpleChanges): void {
    // check @Input values and handle progress bar fill logic
    if (changes.value) {
      switch (true) {
        case this.total === 0 && !!this.value:
          this.percent = 100;
          break;
        case !this.total && !this.value:
          this.percent = 0;
          break;
        default:
          this.percent = Math.abs(this.value) / Math.abs(this.total) * 100;
      }
    }
  }
}
