import { Component, Input, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ClientMovementCollection, ClientMovementService, User, UserSwitcherService, sort } from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';

@Component({
  selector: 'app-user-switcher',
  templateUrl: './user-switcher.component.html',
  styleUrls: ['./user-switcher.component.scss']
})
export class UserSwitcherComponent extends BaseComponent implements OnInit {
  // @TODO waiting for clients api
  @Input() isSwitched: boolean;
  clients: User[];
  filteredClients: User[];
  user: User;

  constructor(private clientMovementService: ClientMovementService, private userSwitcherService: UserSwitcherService) {
    super();
  }

  ngOnInit(): void {
    this.clientMovementService.getActive().subscribe((clientMovements: ClientMovementCollection) => {
      // @TODO move to collection
      this.clients = this.filteredClients = clientMovements.clients.items;
      sort(this.clients, 'fullName', false);
    });
  }

  switch($event: MatAutocompleteSelectedEvent): void {
    this.user = $event.option.value;
    this.userSwitcherService.set(this.user.email);
  }

  filterUsers(value: string) {
    this.filteredClients = cloneDeep(this.clients);
    this.filteredClients = this.filteredClients
      .filter((client: User) => client.fullName.toLowerCase().includes(value.toLowerCase()));
  }
}
