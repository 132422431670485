<ng-container [formGroup]="form">
  <div class="col-12">
    <app-password-input [formControlName]="passwordFieldName"
                        label="Password"
                        placeholder="Password"
                        required
                        [toggleMask]="true">

      <ng-template pTemplate="footer">
                <p class="p-mt-2">Minimum 6 characters</p>
                <ul class="p-pl-2 p-ml-2 p-mt-0">
                  <li>At least one lowercase</li>
                  <li>At least one uppercase or one number</li>
                </ul>
      </ng-template>
    </app-password-input>
  </div>

  <div class="col-12">
    <app-password-input [formControlName]="confirmFieldName"
                        [toggleMask]="true"
                        required
                        placeholder="Confirm password"
                        label="Confirm password"
                        [feedback]="false">
    </app-password-input>
  </div>
</ng-container>
