import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { User } from 'taxtank-core';
import { Router } from '@angular/router';
import { NotificationsListComponent } from '../components/notifications-list/notifications-list.component';
import { BaseComponent } from '@shared/component/base/base.component';

// limit of notifications to show
const NOTIFICATIONS_AMOUNT_LIMIT = 5;

/**
 * Component of notifications popup
 */
@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent extends BaseComponent implements OnInit, AfterViewChecked {
  @ViewChild('notificationsListComponent') notificationsListComponent: NotificationsListComponent;
  @ViewChild('notificationsWrapper') notificationsWrapper: ElementRef;
  notificationsLimit: number = NOTIFICATIONS_AMOUNT_LIMIT;
  isOpened = false;
  // flag that shows if notifications should be read or not
  shouldBeRead = false;
  user: User;

  constructor(public router: Router, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.userService.getFirst().subscribe((user: User) => {
      this.user = user;
    });
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  /**
   * Listen to onClick method to handle popup opened/closed statement
   * @param targetElement that was clicked
   */
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement): void {
    // Don't close notifications if it's opened and click was inside notification popup
    if (this.isOpened &&
      this.notificationsWrapper.nativeElement.contains(targetElement) &&
      !targetElement.className.includes('redirection-link')
    ) {
      return;
    }

    this.isOpened = false;
    this.shouldBeRead = false;
  }

  /**
   * Toggle notifications popup and read notifications
   */
  toggleNotifications(notifications: NotificationsListComponent): void {
    this.isOpened = !this.isOpened;

    if (!notifications.unreadNotifications.length) {
      return;
    }
    this.shouldBeRead = true;
  }
}
