import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SoleBusinessActivity, SoleBusinessActivityService } from 'taxtank-core';
import { AbstractAutocompleteComponent } from '../abstract-autocomplete/abstract-autocomplete.component';

/**
 * Business activity autocomplete
 */
@Component({
  selector: 'app-business-activity-autocomplete',
  templateUrl: './business-activity-autocomplete.component.html',
  styleUrls: ['./business-activity-autocomplete.component.scss']
})
export class BusinessActivityAutocompleteComponent extends AbstractAutocompleteComponent<SoleBusinessActivity> implements OnInit {
  @Input() label = 'Business activity';
  @Input() placeholder = 'Start typing to select your main activity';
  @Input() searchBy: string | string[] = ['name', 'code'];

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private soleBusinessActivityService: SoleBusinessActivityService
  ) {
    super(ngControl, changeDetectorRef);
  }

  getOptions$(): Observable<SoleBusinessActivity[]> {
    return this.soleBusinessActivityService.get();
  }
}
