<form [formGroup]="form" appFormErrorScroll class="transparent">
  <div class="row">
    <div class="col-12 col-md-4">
      <mat-form-field>
        <mat-label>Loan Type</mat-label>
        <mat-select formControlName="type" placeholder="Select">
          <mat-option *ngFor="let loanType of loanTypes | ekeyvalue" [value]="+loanType.key">
            {{ loanType.value | translate }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('type').invalid">
          <app-validation-error [errors]="form.get('type').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6 col-lg-4" *ngIf="!form.initialValue.amount">
      <app-currency-input min="0"
                          formControlName="amount"
                          label="Original Loan Amount"
                          placeholder="Original Loan Amount">
      </app-currency-input>
    </div>

    <div class="col-6 col-lg-4" *ngIf="!form.initialValue.interestRate">
      <mat-form-field>
        <mat-label>Interest Rate</mat-label>
        <span matPrefix>%</span>
        <input matInput type="number" formControlName="interestRate" placeholder="Interest Rate" [min]="0" [max]="100"/>

        <mat-error *ngIf="form.get('interestRate').invalid">
          <app-validation-error [errors]="form.get('interestRate').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6 col-lg-4" *ngIf="!form.initialValue.availableRedraw">
      <app-currency-input min="0"
                          formControlName="availableRedraw"
                          label="Available Redraw"
                          placeholder="Available Redraw">
      </app-currency-input>
    </div>

    <div class="col-6 col-lg-4" *ngIf="!form.initialValue.repaymentFrequency">
      <mat-form-field>
        <mat-label>Repayment Frequency</mat-label>
        <mat-select formControlName="repaymentFrequency" placeholder="Select">
          <mat-option *ngFor="let frequency of loanRepaymentFrequencies | ekeyvalue" [value]="+frequency.key">
            {{ frequency.value | translate }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="form.get('repaymentFrequency').invalid">
          <app-validation-error [errors]="form.get('repaymentFrequency').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6 col-lg-4" *ngIf="!form.initialValue.commencementDate">
      <app-datepicker formControlName="commencementDate" label="Commencement Date"></app-datepicker>
    </div>

    <div class="col-6 col-lg-4" *ngIf="!form.initialValue.repaymentType">
      <mat-form-field>
        <mat-label>Repayment Type</mat-label>
        <mat-select formControlName="repaymentType" placeholder="Select">
          <mat-option *ngFor="let repaymentType of loanRepaymentTypes | ekeyvalue" [value]="+repaymentType.key">
            {{ repaymentType.value | translate }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="form.get('repaymentType').invalid">
          <app-validation-error [errors]="form.get('repaymentType').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6 col-lg-4" *ngIf="!form.initialValue.interestType">
      <mat-form-field>
        <mat-label>Interest Type</mat-label>
        <mat-select formControlName="interestType" placeholder="Select">
          <mat-option *ngFor="let interestType of loanInterestTypes | ekeyvalue" [value]="+interestType.key">
            {{ interestType.value | translate }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="form.get('interestType').invalid">
          <app-validation-error [errors]="form.get('interestType').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

  </div>
</form>
