<mat-form-field *ngIf="mode === 'select'">
  <mat-label>Tank type</mat-label>
  <mat-select [disabled]="disabled"
              #matSelect
              [errorStateMatcher]="errorStateMatcher"
              [placeholder]="placeholder"
              [(ngModel)]="value"
              (selectionChange)="onChange($event.value)"
              (blur)="onTouched()">
    <mat-option *ngIf="hasEmptyOption" [value]="false">{{emptyOptionLabel}}</mat-option>
    <mat-option *ngFor="let type of types" [value]="type">{{ tankTypes[type] | translate }}</mat-option>
  </mat-select>
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>

<mat-radio-group *ngIf="mode === 'radioIcons'"
                 class="radio-icons"
                 [formControl]="ngControl.control"
                 [errorStateMatcher]="errorStateMatcher"
                 (change)="onChange($event.value)">
  <mat-radio-button *ngFor="let type of types"
                    name="radio-button-{{ type }}"
                    [matTooltip]="tankTypes[type] | translate"
                    [value]="type">
    <i class="icon icon-{{ tankTypes[type] | lowercase }}"></i>
  </mat-radio-button>
</mat-radio-group>

<mat-radio-group *ngIf="mode === 'radioLabels'"
                 (change)="onChange($event.value)"
                 [(ngModel)]="value"
                 class="primary">
  <mat-radio-button *ngIf="hasEmptyOption" [value]="false">{{ emptyOptionLabel }}</mat-radio-button>
  <mat-radio-button *ngFor="let type of types" [value]="type">
    {{ tankTypes[type] | translate }}
  </mat-radio-button>
</mat-radio-group>
