import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  HoldingTypeFormDialogComponent
} from '@client/holding-tank/components/holding-type-form/holding-type-form-dialog/holding-type-form-dialog.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Collection,
  Dictionary,
  HoldingType,
  HoldingTypeCategoryEnum,
  HoldingTypeExchange,
  HoldingTypeExchangeService,
  HoldingTypeService
} from 'taxtank-core';
import { AbstractAutocompleteComponent } from '../abstract-autocomplete/abstract-autocomplete.component';

@Component({
  selector: 'app-holding-type-autocomplete',
  templateUrl: './holding-type-autocomplete.component.html',
  styleUrls: ['./holding-type-autocomplete.component.scss']
})
export class HoldingTypeAutocompleteComponent extends AbstractAutocompleteComponent<HoldingType> implements OnInit {
  @Input() categories: HoldingTypeCategoryEnum[] = [];
  @Input() searchBy: string | string[] = ['ticker', 'name'];
  dialogPanelClass = 'dialog-small';
  dialogComponent = HoldingTypeFormDialogComponent;
  exchangesById: Dictionary<HoldingTypeExchange>;

  constructor(
    private holdingTypeService: HoldingTypeService,
    private holdingTypeExchangeService: HoldingTypeExchangeService,
    protected dialog: MatDialog,
    protected changeDetectorRef: ChangeDetectorRef,
    public ngControl: NgControl,
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnInit() {
    this.exchangesById = this.holdingTypeExchangeService.getCache().indexBy('id');
    super.ngOnInit();
  }

  getOptions$(): Observable<HoldingType[]> {
    return this.holdingTypeService.get().pipe(
      map((holdingTypes: Collection<HoldingType>) => {
        if (this.categories.length) {
          return holdingTypes.filterBy('category', this.categories).toArray();
        }

        return holdingTypes.toArray();
      })
    );
  }
}
