import { Component, Input } from '@angular/core';
import { BankAccountPropertiesForm, compareMatOptions } from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * Bank account business allocations form component.
 * Allow user to add one or multiple business allocations to bank account.
 * Used in bank accounts add/edit/import process.
 * @TODO Alex: use it also in Bank account manage properties dialog
 */
@Component({
  selector: 'app-bank-account-business-allocations-form',
  templateUrl: './bank-account-business-allocations-form.component.html',
  styleUrls: ['./bank-account-business-allocations-form.component.scss']
})
export class BankAccountBusinessAllocationsFormComponent extends BaseComponent {
  @Input() form: BankAccountPropertiesForm;
  compareFn = compareMatOptions;
}
