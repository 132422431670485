<ng-container *ngFor="let product of products">
  <ng-container *ngIf="subscriptionsByProduct.get(product.id) as subscription">
    <i [class]="tankIcons[product.role]" 
       [class.ic-success]="subscription.isActive && !subscription.isTrial"
       [class.ic-warning]="subscription.isActive && subscription.isTrial"
       [class.ic-error]="!subscription.isActive"
       [matTooltip]="getTooltip(product, subscription)">
    </i>
  </ng-container>
</ng-container>
