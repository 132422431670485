import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart, Options, PointOptionsType, chart } from 'highcharts';

@Directive({
  selector: '[appChart]',
  standalone: true,
})
export class ChartDirective implements OnChanges {
  @Input() options: Options;
  @Input() data: Array<PointOptionsType>;
  chart?: Chart;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue && this.options) {
      if (!this.chart) {
        this.chart = chart(this.el.nativeElement, this.options);
      }

      this.chart.series[0].setData(this.data);
    }
  }
}
