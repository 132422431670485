import { AfterViewInit, Directive, ElementRef, HostListener, OnInit } from '@angular/core';

const DRAG_THRESHOLD = 5;
/**
 * Directive allows to make the content scroll horizontally if all its elements do not fit in the block.
 * It is necessary to wrap the block in which the elements are iterated into an element (for example, a div) and put a directive on it.
 * Adds shadow effect for hidden elements
 */
@Directive({
  selector: '[appHorizontalScroll]'
})
export class HorizontalScrollDirective implements OnInit, AfterViewInit {
  wrapperElement: HTMLElement;
  isDragging = false;
  isMouseMoveTracking = false;
  lastX = 0;
  lastY = 0;

  constructor(
    private el: ElementRef
  ) {
  }

  /**
   * Create a wrapper, put the content of the directive there and put the wrapper in place of the content.
   */
  ngOnInit() {
    const elementContent: HTMLElement = this.el.nativeElement;

    this.wrapperElement = document.createElement('div');
    this.wrapperElement.classList.add('scrollable-wrapper');
    elementContent.classList.add('scrollable-content');

    elementContent.parentNode.insertBefore(this.wrapperElement, elementContent);
    this.wrapperElement.appendChild(elementContent);
  }

  /**
   * Add a shadow for scrollable content, when after initialization the scroll is in the extreme left / right position
   */
  ngAfterViewInit() {
    if (this.el.nativeElement.scrollWidth > this.el.nativeElement.clientWidth) {
      this.wrapperElement.classList.add('right-shadow');
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this.isMouseMoveTracking = true;
    this.lastX = event.clientX;
    this.lastY = event.clientY;
  }
  // @todo: taxtankit https://taxtank.atlassian.net/browse/TT-4355
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.isDragging) {
      event.preventDefault();
      event.stopPropagation();
      this.isDragging = false;
    }
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  onMouseUpOrLeave(): void {
    this.isMouseMoveTracking = false;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (!this.isMouseMoveTracking) {
      return;
    }

    const deltaX = event.clientX - this.lastX;
    const deltaY = event.clientY - this.lastY;

    if (!this.isDragging && (Math.abs(deltaX) > DRAG_THRESHOLD || Math.abs(deltaY) > DRAG_THRESHOLD)) {
      this.isDragging = true;
    }

    this.el.nativeElement.scrollLeft -= deltaY + deltaX;

    this.lastX = event.clientX;
    this.lastY = event.clientY;
  }

  @HostListener('wheel', ['$event'])
  onWheel(event): void {
    event.preventDefault();
    this.el.nativeElement.scrollLeft += event.deltaY + event.deltaX;
  }

  @HostListener('scroll', ['$event'])
  onScroll($event) {
    // remove all shadow classes when there is no scroll
    if (this.el.nativeElement.scrollWidth <= this.el.nativeElement.clientWidth) {
      this.wrapperElement.classList.remove('left-shadow', 'right-shadow');
      return;
    }

    $event.target.scrollLeft > 0 ? this.wrapperElement.classList.remove('right-shadow') : this.wrapperElement.classList.add('right-shadow');
    $event.target.scrollLeft + $event.target.offsetWidth === $event.target.scrollWidth ?
      this.wrapperElement.classList.add('left-shadow') :
      this.wrapperElement.classList.remove('left-shadow');
  }
}
