<app-preloader *ngIf="!playlist" [type]="preloaderTypes.OVERLAY"></app-preloader>

<div *ngIf="playlist" class="row" [class.f-column-reverse]="viewPortService.isMobile">
  <div class="col-12 col-md-4">
    <ul class="video-list" [ngStyle]="{'height': viewPortService.isMobile ? 'calc(100% - ' +videoHeight + 'px)' : videoHeight + 'px'}">
      <li *ngFor="let video of playlist"
          [class.active]="video.id === selectedVideo.id"
          class="video-list-item pointer"
          (click)="selectVideo(video)">
        {{video.title}}
      </li>
    </ul>
  </div>

  <div  class="col-12 col-md-8">
    <div class="video-container">
      <iframe #videoElement
              class="video-iframe"
              [ngStyle]="{'height': viewPortService.isMobile ? '100%' : videoHeight + 'px'}"
              [src]="('https://www.youtube.com/embed/' + selectedVideo.id + '?rel=0') | safeUrl"
              frameborder="0"
              allowfullscreen>
      </iframe>
    </div>
  </div>
</div>
