<ng-container [appPreloader]="[endpoints.HOLDING_TYPES_POST, endpoints.HOLDING_TYPES_PUT]"
              [preloaderType]="preloaderTypes.OVERLAY">
</ng-container>

<form [formGroup]="form" (ngSubmit)="submit()" class="transparent">
  <div class="row">
    <!-- Name -->
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>Name</mat-label>

        <input matInput formControlName="name" type="text" placeholder="Enter name">

        <mat-error *ngIf="form.get('name').invalid">
          <app-validation-error [errors]="form.get('name').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Category -->
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>Category</mat-label>

        <mat-select formControlName="category" placeholder="Select category">
          <mat-option [value]="categories.COLLECTIBLE">Collectible</mat-option>
          <mat-option [value]="categories.UNLISTED_STOCK">Unlisted stock</mat-option>
          <mat-option [value]="categories.UNLISTED_UNIT">Unlisted unit</mat-option>
          <mat-option [value]="categories.OTHER">Other</mat-option>
        </mat-select>

        <mat-error *ngIf="form.get('name').invalid">
          <app-validation-error [errors]="form.get('name').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Price -->
    <div class="col-12 col-md-6">
      <app-currency-input min="0"
                          maxFractionDigits="15"
                          formControlName="price"
                          label="Current Price/unit"
                          placeholder="Enter price">
      </app-currency-input>
    </div>

    <!-- Dividend percent -->
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>% dividend</mat-label>

        <input matInput formControlName="dividendPercent" type="number" placeholder="Enter percent">

        <span matPrefix>%</span>

        <mat-error *ngIf="form.get('dividendPercent').invalid">
          <app-validation-error [errors]="form.get('dividendPercent').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Description -->
    <div class="col-12">
      <mat-form-field>
        <mat-label>Description</mat-label>

        <input matInput formControlName="description" type="text" placeholder="Enter description">

        <mat-error *ngIf="form.get('description').invalid">
          <app-validation-error [errors]="form.get('description').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
