<mat-form-field [class]="inputClass">

  <mat-label>{{label}}</mat-label>

  <input #searchInput
         matInput
         [errorStateMatcher]="errorStateMatcher"
         [matAutocomplete]="auto"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         type="text"
         (input)="onInput($event.target.value)"
         (blur)="onTouched()">

  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="onSelect($event.option.value)"
                    [displayWith]="displayFn">

    <ng-container *ngIf="filteredOptions">
      <mat-option *ngFor="let country of filteredOptions" [value]="country">
        {{ showCallingCode ? country.countryWithCode : country.name }}
      </mat-option>

      <mat-option *ngIf="searchInput.value.length >= minSearchLength && !filteredOptions.length" [disabled]="true">
        No results found
      </mat-option>
    </ng-container>

  </mat-autocomplete>

  <div matSuffix>
    <ng-container [appPreloader]="[endpoints.COUNTRIES_GET]"
                  [preloaderType]="preloaderTypes.ICON"
                  [preloaderSize]="20">
    </ng-container>
  </div>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
