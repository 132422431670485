import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from '@shared/component/layout/menu/menu-item';
import { User } from 'taxtank-core';
import { MatSidenav } from '@angular/material/sidenav';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * sidenav component
 */
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  @Input() menuItems: MenuItem[];
  user: User;

  ngOnInit(): void {
    this.user = this.userService.getCacheFirst();
    this.userService.getFirst().subscribe((user) => {
      this.user = user;
    });
  }

  toggle(): void {
    this.sidenav.toggle();
  }
}
