<div [formGroup]="form" class="transparent">
  <div class="row">

    <div *ngIf="showTypeField" class="col-12 col-sm-3">
      <mat-form-field>
        <mat-label>Phone type</mat-label>
        <mat-select formControlName="type">
          <mat-option [value]="phoneTypes.MOBILE">Mobile</mat-option>
          <mat-option [value]="phoneTypes.OFFICE">Office</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12" [class]="showTypeField ? 'col-sm-4' : 'col-sm-6'">
      <app-country-autocomplete label="Country code"
                                formControlName="country"
                                [showCallingCode]="true"
                                [searchBy]="['name', 'callingCode']">
      </app-country-autocomplete>
    </div>

    <div class="col-12" [class]="showTypeField ? 'col-sm-5' : 'col-sm-6'">
      <mat-form-field [hideRequiredMarker]="hideRequiredMarker">
        <mat-label>{{ label }}</mat-label>

        <input matInput
               formControlName="number"
               placeholder="Enter phone"
               [errorStateMatcher]="errorStateMatcher"
               (blur)="onTouched()"/>

        <mat-error>
          <app-validation-error [errors]="form.get('number').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
