<div id="notification" #notificationsWrapper class="notifications-wrapper">
  <button *ngIf="!viewPortService.isMobile && notifications.unreadNotificationsCount$"
          matTooltip="Show notifications"
          class="btn header-button icon-bell"
          [appNotifications]="notifications.unreadNotificationsCount$"
          (click)="toggleNotifications(notifications)">
  </button>
  <button *ngIf="viewPortService.isMobile"
          class="btn icon-bell header-button"
          (click)="user.isClient() ? router.navigate(['/client/notifications']) : router.navigate(['/firm/notifications'])">
  </button>
  <div class="notifications-container p-absolute" [hidden]="!isOpened">
    <app-notifications-list #notifications
                            [notificationsLimit]="notificationsLimit"
                            [isPopup]="true"
                            [shouldBeRead]="shouldBeRead">
    </app-notifications-list>
  </div>
</div>
