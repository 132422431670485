<mat-form-field [class]="inputClass">
  <mat-label>{{ label }}</mat-label>

  <input
    matInput
    [disabled]="disabled"
    [errorStateMatcher]="errorStateMatcher"
    [placeholder]="placeholder"
    type="number"
    [(ngModel)]="value"
    (input)="onChange($event.target.value)"
    (blur)="onTouched()"
  />

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
