import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  SubscriptionBannerUpgradeComponent
} from '@components/subscription-banner/subscription-banner-upgrade/subscription-banner-upgrade.component';
import {
  SubscriptionChangeDialogComponent
} from '@client/shared/components/subscription/subscription-change-dialog/subscription-change-dialog.component';
import {ServiceProductIdEnum} from 'taxtank-core';

@Component({
  selector: 'app-subscription-banner-upgrade-property-limit',
  templateUrl: './subscription-banner-upgrade-property-limit.component.html',
  styleUrls: ['./subscription-banner-upgrade-property-limit.component.scss']
})
export class SubscriptionBannerUpgradePropertyLimitComponent {
  constructor(private dialogRef: MatDialogRef<SubscriptionBannerUpgradeComponent>, public dialog: MatDialog) {}
  openSubscriptionDialog(): void {
    this.dialog.open(SubscriptionChangeDialogComponent, {
      panelClass: 'dialog-large',
      data: ServiceProductIdEnum.PROPERTIES
    });

    this.dialogRef.close();
  }
}
