import { ChartAccounts, Transaction } from 'taxtank-core';
import { MatTableDataSource } from '@angular/material/table';

/**
 * Class helper for transactions summary component
 */
export class TransactionSummaryItem {
  chartAccounts: Readonly<ChartAccounts>;
  transactions: Readonly<Array<Readonly<Transaction>>>;
  amount: Readonly<number>;
  claimAmount: Readonly<number>;
  tableDataSource: MatTableDataSource<Transaction>;

  constructor(
    transactions: Transaction[]
  ) {
    this.chartAccounts = transactions[0].chartAccounts;
    this.transactions = transactions;
    this.setAmount();
    this.setClaimAmount();
    this.tableDataSource = new MatTableDataSource<Transaction>(transactions);
  }

  /**
   * calculate and set sum of transactions amounts
   */
  private setAmount(): void {
    this.amount = this.transactions.reduce((sum: number, transaction: Transaction): number => sum + transaction.amount, 0);
  }

  /**
   * calculate and set sum of transactions claim amounts
   */
  private setClaimAmount(): void {
    this.claimAmount = this.transactions.reduce((sum: number, transaction: Transaction): number => sum + transaction.claimAmount, 0);
  }
}
