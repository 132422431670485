import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import {
  CHART_ACCOUNTS_CATEGORIES,
  ChartAccounts,
  HomeOfficeClaimService,
  TankTypeEnum,
  VehicleClaimService
} from 'taxtank-core';
import { combineLatest } from 'rxjs';
import { AbstractControlComponent } from '../../abstract-control/abstract-control.component';
import { startWith } from 'rxjs/operators';

/**
 * Claim percent input form field.
 * Need to work with chart accounts and reacts to their changes
 */
@Component({
  selector: 'app-claim-percent-input',
  templateUrl: './claim-percent-input.component.html',
  styleUrls: ['./claim-percent-input.component.scss']
})
export class ClaimPercentInputComponent extends AbstractControlComponent<number> implements OnInit {
  @Input() control: AbstractControl;
  @Input() isCompact: boolean;
  @Input() label = 'Claim percent';
  @Input() placeholder = 'Claim percent';

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private vehicleClaimService: VehicleClaimService,
    private homeOfficeClaimService: HomeOfficeClaimService,
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnInit(): void {
    const batch = [
      this.vehicleClaimService.get(),
      this.homeOfficeClaimService.get(),
      this.ngControl.control.parent.get('chartAccounts').valueChanges,
    ];
    if (this.ngControl.control.parent.get('business')) {
      batch.push(this.ngControl.control.parent.get('business').valueChanges.pipe(startWith(null)));
    }
    // @TODO remove when transaction forms refactored
    combineLatest(batch).subscribe(([vehicleClaims, homeOfficeClaims, chartAccounts, business]) => {
      if (!(chartAccounts instanceof ChartAccounts)) {
        return;
      }

      let tankType: TankTypeEnum;
      if (CHART_ACCOUNTS_CATEGORIES.work.includes(chartAccounts.category)) {
        tankType = TankTypeEnum.WORK;
      } else if (CHART_ACCOUNTS_CATEGORIES.sole.includes(chartAccounts.category)) {
        tankType = TankTypeEnum.SOLE;
      }

      if (chartAccounts.isHomeOfficeExpense() && (business || tankType === TankTypeEnum.WORK)) {
        const claim = homeOfficeClaims.findByBusiness(business);
        if (!claim) {
          this.value = chartAccounts.taxablePercent;
          this.ngControl.control.enable();
          return;
        }

        const claims = claim.getChartAccountsClaims();
        this.value = claims.get(chartAccounts.id);
        this.ngControl.control.disable();
      }

      if (chartAccounts.isVehicleExpense() || chartAccounts.isVehicleLoanPrinciple()) {
        this.value = vehicleClaims.filterBy('tankType', tankType).first?.workUsage || 0;
      }

      this.onChange(this.value);
    });
  }
}
