import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

/**
 * UI component: Up/Down green/red arrow
 */
@Component({
  selector: 'app-comparison-arrow',
  templateUrl: './comparison-arrow.component.html',
  styleUrls: ['./comparison-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparisonArrowComponent implements OnChanges {
  // Comparison operand 1
  @Input() a: number;
  // Comparison operand 2
  @Input() b: number;
  // true if up arrow should be negative and down arrow should be positive
  @Input() isInverted: boolean;
  // Arrow direction (up or down)
  isUp: boolean;
  // Arrow color (green or red)
  isPositive: boolean;

  ngOnChanges(): void {
    this.isUp = this.a > this.b;
    this.isPositive = this.isUp && !this.isInverted || !this.isUp && this.isInverted;
  }
}
