import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Notification } from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * Component with list of whole user notifications
 */
@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationMessageComponent extends BaseComponent implements AfterViewChecked {
  @Input() notification: Notification;

  ngAfterViewChecked(): void {
    // detect changes if profile-photo component was changed
    this.changeDetectorRef.markForCheck();
  }
}
