<div class="accepted-promocode">
  <div class="fs-small c-text">
    <i class="icon-check ic-text"></i>
    promo code
    <!--<ng-container *ngIf="!subscription.promoCode.isDurationForever() && subscription">-->
    <!--  until {{(subscription.promoCodeEndDate() | date)}}-->
    <!--</ng-container>-->
  </div>
  <div>
    {{subscription.promoCode | call:'getLabel'}}
  </div>
</div>
