import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * UI component: Block with several data-cards
 * @TODO rename to InnerCard after current InnerCard component replaced everywhere
 */
@Component({
  selector: 'app-data-card-group',
  templateUrl: './data-card-group.component.html',
  styleUrls: ['./data-card-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCardGroupComponent {
  // UI flag: decrease font-size, margin, etc.
  @Input() isCompact: boolean;
}
