// match displayed fields for other
export const incomeSourceFields: {[key: string]: string[]} = {
  'Bonuses': ['incomeSourceType', 'amount', 'tax'],
  'Capital Gains Tax': ['incomeSourceType', 'amount', 'tax'],
  'Director Fees': ['incomeSourceType', 'amount', 'tax'],
  'Dividends': ['incomeSourceType', 'amount', 'frankingCredits'],
  'Interest': ['incomeSourceType', 'amount', 'tax'],
  'Pensions & Gov Allowances': ['incomeSourceType', 'amount', 'tax'],
  'PSI': ['incomeSourceType', 'amount', 'tax'],
  'Attributed PSI': ['incomeSourceType', 'amount', 'tax'],
  'Sole-Trader': ['incomeSourceType', 'amount', 'taxInstalments'],
  'Superannuation Incomes (taxed element)': ['incomeSourceType', 'amount', 'tax'],
  'Partnerships': ['incomeSourceType', 'amount', 'frankingCredits'],
  'Trusts': ['incomeSourceType', 'trustType', 'amount', 'frankingCredits'],
  'Other Income': ['incomeSourceType', 'amount', 'tax', 'taxInstalments', 'frankingCredits']
};
