import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-banner-feedback',
  templateUrl: './subscription-banner-feedback.component.html',
  styleUrls: ['./subscription-banner-feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionBannerFeedbackComponent {

  constructor(
    public dialogRef: MatDialogRef<SubscriptionBannerFeedbackComponent>
  ) { }

  // @Todo implement logic
  leaveFeedback(): void {
  }
}
