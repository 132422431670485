import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HeaderTitleService } from 'taxtank-core';
import { ActivatedRoute } from '@angular/router';
import { IHeaderActionsInterface } from '@shared/component/layout/header/header-actions.interface';

/**
 * Component that handle app title
 */
@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  /**
   * Current app title
   */
  title: string;

  /**
   * Back button link
   */
  backLink: string;

  // @TODO implement submenu on the sidebar and move it there
  /**
   * Actions may be used in title
   */
  actions: IHeaderActionsInterface;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private headerTitleService: HeaderTitleService
  ) {
    this.handleHeaderContent();
  }

  /**
   * update app title on router events
   */
  handleHeaderContent(): void {
    this.headerTitleService.handleTitle().subscribe((event: any) => {
      this.title = event['title'];
      this.backLink = event['backLink'];
      // @TODO implement submenu on the sidebar and move it there
      this.actions = event['actions'];
      this.titleService.setTitle(event['title']);
    });
  }
}
