import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-property-growth-percentage-corelogic-dialog',
  templateUrl: './property-growth-percentage-corelogic-dialog.component.html',
  styleUrls: ['./property-growth-percentage-corelogic-dialog.component.scss']
})
export class PropertyGrowthPercentageCorelogicDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PropertyGrowthPercentageCorelogicDialogComponent>
  ) { }

  ngOnInit(): void {
  }

}
