<mat-card>
  <mat-card-content>
    <div class="align-h-space-between">
      <div class="align-v-center">
        <i class="icon {{getDocIcon(file)}} ic-primary mr-10 is-big"></i>
        <div>
          <div>{{file.originalName}}</div>
          <div class="c-text fs-small">{{file.createdAt | date}}</div>
        </div>
      </div>

      <div class="align-v-center">
        <div class="mr-10">{{file.size | fileSizeConvert}}</div>
        <i class="icon icon-button icon-download ic-text cursor-pointer" [appDownload]="file"></i>
      </div>
    </div>
  </mat-card-content>
</mat-card>
