import { Component, OnInit } from '@angular/core';
import {
  ChartAccounts, ChartAccountsCategoryEnum, ChartAccountsCollection,
  ChartAccountsForm,
  ChartAccountsMessagesEnum,
  ChartAccountsService,
} from 'taxtank-core';
import { BaseFormComponent } from '@shared/component/base/base-form.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-chart-accounts-form',
  templateUrl: './chart-accounts-form.component.html',
  styleUrls: ['./chart-accounts-form.component.scss']
})
export class ChartAccountsFormComponent extends BaseFormComponent implements OnInit {
  form: ChartAccountsForm;
  chartAccountsCategories = ChartAccountsCategoryEnum;
  chartAccountsList: ChartAccountsCollection;
  selectedChartAccounts: ChartAccounts;
  messages = ChartAccountsMessagesEnum;

  constructor(private chartAccountsService: ChartAccountsService) {
    super();
  }

  ngOnInit() {
    this.chartAccountsService.get().subscribe(chartAccounts => {
      this.chartAccountsList = chartAccounts.getManual();
    });

    super.ngOnInit();
  }

  buildForm(): void {
    this.form = new ChartAccountsForm(this.selectedChartAccounts);
  }

  save(formValue: ChartAccounts): void {
    if (formValue.id) {
      this.chartAccountsService.put(formValue).subscribe(() => {
        this.toastService.success(this.messages.UPDATED);
        this.reset();
      });
    } else {
      this.chartAccountsService.post(formValue).subscribe(() => {
        this.toastService.success(this.messages.UPDATED);
        this.reset();
      });
    }
  }

  delete(chartAccounts: ChartAccounts): void {
    this.chartAccountsService.delete(chartAccounts).subscribe(() => {
      this.toastService.success('Chart Accounts successfully deleted');
    }, (error: HttpErrorResponse) => {
      this.toastService.error('This category has allocated transactions, You’ll need to unallocate these transactions too make changes');
    });
  }

  toggle(chartAccounts: ChartAccounts): void {
    this.selectedChartAccounts = this.selectedChartAccounts?.id === chartAccounts.id ? undefined : chartAccounts;
    this.buildForm();
  }

  reset(): void {
    this.selectedChartAccounts = undefined;
    this.buildForm();
  }
}
