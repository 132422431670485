/**
 * @TODO Alex no way we keep that
 */
export const FEATURES_BY_PRODUCT = {
  common: [
    'Dashboard (Main)', 'Transactions reporting', 'MyTax reporting', 'Spare Tank', 'Invite Accountant/Advisor', 'Tax Summary'
  ],
  'Sole Tank': [
    'Dashboard (Main)', 'Invite Accountant/Advisor', 'Spare Tank', 'Bank Feeds', 'Sole Tank', 'Tax Summary',
    'Vehicle reporting', 'General Pool reporting', 'Depreciation reporting', 'Business schedule reporting', 'Transactions reporting', 'MyTax reporting'
  ],
  'Work Tank': [
    'Dashboard (Main)', 'Invite Accountant/Advisor', 'Dashboard (Budget)', 'Work Tank', 'Spare Tank', 'Bank Feeds', 'Tax Summary',
    'Vehicle reporting', 'Depreciation reporting', 'LVP reporting', 'Transactions reporting', 'MyTax reporting'
  ],
  'Property Tank': [
    'Dashboard (Main)', 'Invite Accountant/Advisor', 'Dashboard (Property)', 'Property Tank', 'Spare Tank', 'Bank Feeds', 'Tax Summary',
    'Property Schedules reporting', 'Depreciation reporting', 'CGT reporting', 'LVP reporting', 'Transactions reporting', 'Invite Co-owner', 'MyTax reporting'
  ],
  'Holdings Tank': [
    'Dashboard (Main)', 'Invite Accountant/Advisor', 'Spare Tank', 'Holdings Tank', 'Tax Summary', 'CGT reporting'
  ]
};

export const ALL_FEATURES = ['Dashboard (Main)', 'Invite Accountant/Advisor',  'Dashboard (Budget)', 'Dashboard (Property)', 'Work Tank',
  'Property Tank', 'Spare Tank', 'Holdings Tank', 'Bank Feeds', 'Sole Tank', 'Tax Summary', 'Property Schedules reporting',
  'Vehicle reporting', 'General Pool reporting', 'Depreciation reporting',  'CGT reporting',
  'LVP reporting', 'Business schedule reporting', 'Transactions reporting', 'Invite Co-owner', 'MyTax reporting'];
