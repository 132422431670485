<ng-container [appPreloader]="[endpoints.USER_CURRENT_PASSWORD_PUT]" [preloaderType]="preloaderTypes.OVERLAY"></ng-container>

<div class="row">
  <div class="col-12 col-md-6">
    <form [formGroup]="form" (ngSubmit)="changePassword()">
      <!-- @TODO move to subscription page? -->
      <!--<div class="description align-v-center mb-25">-->
      <!--  <div>-->
      <!--    <i class="description-icon icon icon-lock ic-primary is-big"></i>-->
      <!--  </div>-->
      <!--  <div>-->
      <!--    Your payment details are stored and encrypted by Stripe, who have a stellar record for high security!-->
      <!--  </div>-->
      <!--</div>-->

      <mat-form-field>
        <mat-label>Current password</mat-label>
        <input matInput type="password" formControlName="currentPassword" placeholder="Enter Your Current Password"
               required>
        <mat-error *ngIf="form.get('currentPassword').invalid">
          <app-validation-error [errors]="form.get('currentPassword').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>

      <app-password-matcher [form]="form" passwordFieldName="newPassword"></app-password-matcher>

      <div class="text-right">
        <button type="button" class="btn link c-danger" [appConfirm]="messages.DELETE" (accepted)="delete()">
          Close Account
        </button>
        <button class="btn primary inverted icon" type="submit">
          <i class="icon icon-revert"></i>
          Change Password
        </button>
        <!-- @Todo Implement Reset MFA logic -->
        <!--<button class="btn primary inverted icon">-->
        <!--  <i class="icon icon-security"></i>-->
        <!--  Reset MFA-->
        <!--</button>-->
      </div>
    </form>
  </div>
</div>
