import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CustomCurrencyPipe } from '@shared/pipe/custom-currency/custom-currency.pipe';
import { monthNames } from '@shared/globals/month-names';
import { BaseChartComponent } from '@shared/component/charts/base-chart/base-chart.component';
import { chart } from 'highcharts';
import { COLORS } from '@shared/globals/colors';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent extends BaseChartComponent implements OnInit, OnChanges {
  // all bars in one (stacked columns)
  @Input() stacked: boolean;
  @Input() compare: boolean;

  constructor(
    public customCurrencyPipe: CustomCurrencyPipe
  ) {
    super(customCurrencyPipe);
  }

  ngOnInit(): void {
    this.initChart();
  }

  ngOnChanges(): void {
    this.initChart();
  }

  initChart(): void {
    if (!this.data.length) {
      return;
    }

    this.buildData();
    // set options declared in base class
    this.setDefaultOptions(this.customCurrencyPipe);
    // set options
    this.setOptions(this.customCurrencyPipe);
    // hack for dynamic containers
    setTimeout(() => {
      this.showChart();
    });
  }

  buildData(): void {
    this.chartData = this.data.map((item: any) => ({
      name: item[this.bindSeriesName],
      series: item[this.bindSeriesData].map((serie: any) => {
        if (this.isDateChart) {
          // if (!isNaN(new Date(serie[this.bindX]).getTime())) {
          const date: Date = new Date(serie[this.bindX]);
          return [Date.UTC(date.getFullYear(), date.getMonth()), serie[this.bindY]];
          // }
        }
        return [serie[this.bindX], serie[this.bindY]];
      })
    }));
  }

  setOptions(customCurrencyPipe: CustomCurrencyPipe): void {
    // show/hide Y axis
    this.chartOptions.yAxis.plotLines = [{
      value: 0,
      width: 1,
      color: '#aaa',
      zIndex: 10
    }];
    this.chartOptions.yAxis.gridLineWidth = 0;
    this.chartOptions.yAxis.minorGridLineWidth = 0;
    this.chartOptions.yAxis.labels.enabled = false;

    // set x axis labels type
    this.chartOptions.xAxis.type = !this.isDateChart ? 'category' : 'datetime';

    // set chart type
    this.chartOptions.chart.type = 'column';

    // set columns stacking
    this.chartOptions.plotOptions.series.stacking = this.stacked ? 'normal' : '';
    // set top border radius
    this.chartOptions.plotOptions.series.borderRadius = 6;
    // set max bar width
    this.chartOptions.plotOptions.series.maxPointWidth = 40;
    // set chart colors
    // this.chartOptions.colors = this.compare ? PALETTE_COMPARISON : PALETTE_PRIMARY;

    // set tooltip template
    this.chartOptions.tooltip.formatter = function(): string {
      const date: Date = new Date(this.point.x);
      const label: string = isNaN(this.point.x) ? `${monthNames[date.getMonth()]} ${date.getFullYear()}` : this.point.name;
      return `<span style="color: ${COLORS.grey}; font-size: 12px; line-height: 18px; font-weight: bold; text-transform: uppercase; font-family: Montserrat, sans-serif">
                    ${label}
                  </span>
                  <br>
                  <span style="color:${this.point.color}">●</span>
                  <span style="font-size: 12px; ">
                    ${this.point.series.name}: ${customCurrencyPipe.transform(this.point.y, true)}
                  </span>`;
    };

    // set series to chart
    this.chartOptions.series = this.chartData.map((d: any) => ({
      name: d.name,
      data: d.series,
    }));
  }

  showChart(): void {
    chart(this.containerId, this.chartOptions);
  }
}
