import { Component, Input } from '@angular/core';
import { Badge } from '@shared/component/badge/badge';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input() badge: Badge;
  // CSS format for width
  @Input() width = '240px';
}
