import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  IncomeSource,
  IncomeSourceForecast,
  IncomeSourceService,
  IncomeSourceTypeEnum,
  SalaryForecast,
} from 'taxtank-core';
import {
  IncomeSourceDialogComponent
} from '@client/shared/components/income-source/income-source-dialog/income-source-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AbstractAutocompleteComponent } from '../abstract-autocomplete/abstract-autocomplete.component';
import { plainToClass } from 'class-transformer';
import isEqual from 'lodash/isEqual';

/**
 * Income source autocomplete
 */
@Component({
  selector: 'app-income-source-autocomplete',
  templateUrl: './income-source-autocomplete.component.html',
  styleUrls: ['./income-source-autocomplete.component.scss']
})
export class IncomeSourceAutocompleteComponent extends AbstractAutocompleteComponent<IncomeSource> implements OnInit {
  @Input() label = 'Payer';
  @Input() placeholder = 'Select payer';
  @Input() canManage = true;
  @Input() minSearchLength = 0;
  @Input() isHolding = false;
  @Input() incomeSourceTypes: IncomeSourceTypeEnum[] = [IncomeSourceTypeEnum.WORK, IncomeSourceTypeEnum.OTHER];

  /**
   * Use the additional type property to display buttons correctly
   */
  addButtonType: 'holding' | 'work' | 'sole';
  incomeSourceTypesEnum: typeof IncomeSourceTypeEnum = IncomeSourceTypeEnum;
  dialogComponent = IncomeSourceDialogComponent;
  protected dialogPanelClass = 'dialog-small';

  constructor(
    private incomeSourceService: IncomeSourceService,
    protected dialog: MatDialog,
    protected changeDetectorRef: ChangeDetectorRef,
    public ngControl: NgControl,
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    switch (true) {
      case isEqual([IncomeSourceTypeEnum.HOLDING], this.incomeSourceTypes):
        this.addButtonType = 'holding';
        break;
      case isEqual([IncomeSourceTypeEnum.WORK, IncomeSourceTypeEnum.OTHER], this.incomeSourceTypes):
        this.addButtonType = 'work';
        break;
      case isEqual([IncomeSourceTypeEnum.SOLE], this.incomeSourceTypes):
        this.addButtonType = 'sole';
        break;
    }
  }

  getOptions$(): Observable<IncomeSource[]> {
    return this.incomeSourceService.getByTypes(this.incomeSourceTypes);
  }

  /**
   * @TODO vik factory?
   * @param type
   */
  openAddDialog(type: IncomeSourceTypeEnum): void {
    const incomeSource = plainToClass(IncomeSource, { type: type });
    switch (type) {
      case IncomeSourceTypeEnum.WORK:
        incomeSource.salaryForecasts = [plainToClass(SalaryForecast, {})];
        break;
      case IncomeSourceTypeEnum.OTHER:
      case IncomeSourceTypeEnum.HOLDING:
        incomeSource.incomeSourceForecasts = [plainToClass(IncomeSourceForecast, {})];
        break;
    }

    this.openManageDialog({ incomeSource, isSalary: type === IncomeSourceTypeEnum.WORK });
  }
}
