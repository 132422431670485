import { Component, Input } from '@angular/core';
import { BaseFormComponent } from '@shared/component/base/base-form.component';
import { HoldingType, HoldingTypeCategoryEnum, HoldingTypeForm, HoldingTypeMessagesEnum, HoldingTypeService } from 'taxtank-core';

/**
 * Holding type form
 */
@Component({
  selector: 'app-holding-type-form',
  templateUrl: './holding-type-form.component.html',
  styleUrls: ['./holding-type-form.component.scss']
})
export class HoldingTypeFormComponent extends BaseFormComponent {
  @Input() holdingType: HoldingType;
  form: HoldingTypeForm;
  categories: typeof HoldingTypeCategoryEnum = HoldingTypeCategoryEnum;

  constructor(private holdingTypeService: HoldingTypeService) {
    super();
  }

  buildForm(): void {
    this.form = new HoldingTypeForm(this.holdingType);
  }

  save(holdingType: HoldingType): void {
    if (holdingType.id) {
      this.put(holdingType);
    } else {
      this.post(holdingType);
    }
  }

  private post(holdingType: HoldingType): void {
    this.holdingTypeService.post(holdingType).subscribe(() => {
      this.toastService.success(HoldingTypeMessagesEnum.CREATED);
      this.saved.emit();
    });
  }

  private put(holdingType: HoldingType): void {
    this.holdingTypeService.put(holdingType).subscribe(() => {
      this.toastService.success(HoldingTypeMessagesEnum.UPDATED);
      this.saved.emit();
    });
  }
}
