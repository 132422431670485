import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BankAccount, BankAccountImportForm, BankAccountTypeEnum, TankTypeEnum, User } from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * Component with form for import single bank account from basiq
 */
@Component({
  selector: 'app-bank-account-import-form',
  templateUrl: './bank-account-import-form.component.html',
  styleUrls: ['./bank-account-import-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankAccountImportFormComponent extends BaseComponent implements OnInit {
  @Input() form: BankAccountImportForm;
  bankAccount: BankAccount;
  bankAccountTypes = BankAccountTypeEnum;
  user: User;

  ngOnInit(): void {
    this.user = this.userService.getCacheFirst();

    if (!this.form) {
      this.form = new BankAccountImportForm(this.bankAccount);
    }

    this.bankAccount = this.form.model;
  }

  /**
   * Handle bank accounts selection
   */
  toggleSelection(checked: boolean): void {
    if (checked) {
      this.form.enable();
    } else {
      // {emitEvent: false} is used because when we enable/disable BankAccountForm it toggle also inner BankAccountLoanForm and BankAccountPropertiesForm.
      // so first BankAccountForm toggled, then inned forms toggled and this inner toggle emits BankAccountForm valueChanges which enable BankAccountForm.
      // this is angular form logic
      this.form.disable({ emitEvent: false });
    }
  }
}
