import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CAPITAL_COSTS_ITEMS, CalculationFormItem } from 'taxtank-core';
import round from 'lodash/round';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Calculator for property percentage of claim.
 * Works with CalculationForm component
 */
@Component({
  selector: 'app-property-claim-percentage-calculator',
  templateUrl: './property-claim-percent-calculator.component.html',
  styleUrls: ['./property-claim-percent-calculator.component.scss']
})
export class PropertyClaimPercentCalculatorComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() isCompact: boolean;
  // capital costs calculation form items
  capitalCostsFormItems: CalculationFormItem[];
  // form control, for which calculation will be made
  control: UntypedFormControl;

  ngOnInit(): void {
    this.capitalCostsFormItems = cloneDeep(CAPITAL_COSTS_ITEMS);
  }

  /**
   * Change values for capital costs values depends on calculation changes
   * @param changedItems: items, which will be used to change capitalCostsFormItems fields
   */
  toggleFields(changedItems: CalculationFormItem): void {
    this.capitalCostsFormItems = cloneDeep(this.capitalCostsFormItems);

    const changedCalculationItem: CalculationFormItem = this.capitalCostsFormItems.find((item: CalculationFormItem) => item.name === changedItems.name);
    changedCalculationItem.dependentFields.forEach((fieldName: string) => {
      this.capitalCostsFormItems.filter((capitalCostsFormItem: CalculationFormItem) =>
        capitalCostsFormItem.name === fieldName).map((item: CalculationFormItem) =>
        item.isDisabled = !!changedItems.value);
    });
  }

  /**
   * @TODO business logic, move outside of component
   * Set claim percentage value depends on calculator fields
   */
  setClaimPercentageValue(): void {
    let claimPercentage: number;

    switch (true) {
      // set claim percent as 100 if rentingWholeProperty & rentingWholeYear controls has true values
      case this.form.get('rentingWholeProperty').value && this.form.get('rentingWholeYear').value:
        claimPercentage = 100;
        break;
      // calculate claim percent if only rentingWholeProperty control has true value
      case this.form.get('rentingWholeProperty').value && !this.form.get('rentingWholeYear').value:
        claimPercentage = Math.round(this.form.get('howManyDays').value / 365 * 100);
        break;
      default:
        const amountOfProperty: number = this.form.get('howBigIsProperty').value;
        const amountOfLetArea: number = this.form.get('howBigIsLetArea').value;
        const amountOfCommonAreas: number = this.form.get('howBigIsCommonAreas').value;
        const howManyDays: number = !this.form.get('howManyDays').disabled ? this.form.get('howManyDays').value : 365;

        const leftArea = amountOfLetArea / amountOfProperty * howManyDays / 365 * 100;
        const commonArea = amountOfCommonAreas / amountOfProperty * howManyDays / 365 * 0.5 * 100;
        claimPercentage = round(leftArea + commonArea, 2);
    }

    this.control.setValue(claimPercentage);
  }
}
