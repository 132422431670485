<footer>
    <div *ngIf="isWithLogout" class="logout" (click)="logout()">
      <i class="icon icon-arrow-left"></i>
      Logout
    </div>
    <div>TaxTank © {{ currentYear }}</div>
    <div (click)="openTermsPrivacyDialog()" class="terms-privacy text-center">
      Terms & Conditions  |  Privacy policy
    </div>
</footer>
