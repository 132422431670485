import { Component } from '@angular/core';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';

/**
 * Custom form control for sole invoice template term
 */
@Component({
  selector: 'app-sole-invoice-term-select',
  templateUrl: './sole-invoice-term-select.component.html',
  styleUrls: ['./sole-invoice-term-select.component.scss']
})
export class SoleInvoiceTermSelectComponent extends AbstractControlComponent<number> {
  label = 'Invoice terms';
  placeholder = 'Select terms';

  /**
   * Flag to hide/display additional input for custom terms value (if user does not want use predefined values)
   */
  isCustom: boolean;

  /**
   * List of most popular terms values
   */
  options: number[] = [0, 7, 14, 30];
}
