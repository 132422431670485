import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { IncomeSourceComponent } from '@client/shared/components/income-source/income-source.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IncomeSource, IncomeSourceTypeEnum } from 'taxtank-core';

interface DialogData {
  isSalary: boolean;
  incomeSource: IncomeSource;
}

/**
 * Component with income source dialog to interact with
 */
@Component({
  selector: 'app-income-source-dialog',
  templateUrl: './income-source-dialog.component.html',
  styleUrls: ['./income-source-dialog.component.scss']
})
export class IncomeSourceDialogComponent {
  // salary / other income source component
  @ViewChild('incomeSourceComponent', { static: false }) incomeSourceComponent: IncomeSourceComponent;
  incomeSourceTypes: typeof IncomeSourceTypeEnum = IncomeSourceTypeEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<IncomeSourceDialogComponent>,
  ) {
  }

  cancel(incomeSources: Array<IncomeSource[]>): void {
    this.dialogRef.close(incomeSources[0]);
  }
}
