import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AddressService, Country } from 'taxtank-core';
import { NgControl } from '@angular/forms';
import { AbstractAutocompleteComponent } from '../abstract-autocomplete/abstract-autocomplete.component';
import { Observable } from 'rxjs';

/**
 * Country autocomplete
 */
@Component({
  selector: 'app-country-autocomplete',
  templateUrl: './country-autocomplete.component.html',
  styleUrls: ['./country-autocomplete.component.scss']
})
export class CountryAutocompleteComponent extends AbstractAutocompleteComponent<Country> implements OnInit {
  @Input() label = 'Country';
  @Input() placeholder = 'Search country';
  @Input() showCallingCode: boolean;
  @Input() minSearchLength = 0;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private addressService: AddressService
  ) {
    super(ngControl, changeDetectorRef);
  }

  getOptions$(): Observable<Country[]> {
    return this.addressService.getCountries();
  }
}
