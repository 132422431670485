import { Component, Input } from '@angular/core';
import { InnerCardValueColorEnum } from '@shared/component/inner-card/inner-card-value-color.enum';

@Component({
  selector: 'app-inner-card',
  templateUrl: './inner-card.component.html',
  styleUrls: ['./inner-card.component.scss']
})
export class InnerCardComponent {
  @Input() title: string;
  @Input() value: string | number;
  @Input() isInline?: boolean = false;
  @Input() isPrimary?: boolean = false;
  // Show percent with arrow
  @Input() growthValue: string | number;
  @Input() isPrice: boolean = false;
  @Input() isSmallText = false;
  // Reverse arrow colors
  // @Todo move arrow-displaying logic from component
  @Input() isArrowColorReversed = false;
  @Input() color: InnerCardValueColorEnum = InnerCardValueColorEnum.DEFAULT;
}
