import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFormComponent } from '@shared/component/base/base-form.component';
import {
  SoleBusiness, SoleBusinessForm, SoleBusinessService,
} from 'taxtank-core';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-sole-business-form',
  templateUrl: './sole-business-form.component.html',
  styleUrls: ['./sole-business-form.component.scss']
})
export class SoleBusinessFormComponent extends BaseFormComponent {
  static createdMessage = 'Business created';
  static updatedMessage = 'Business updated';

  @Input() business: SoleBusiness;
  @Output() saved: EventEmitter<SoleBusiness> = new EventEmitter();
  form: SoleBusinessForm;

  constructor(private soleBusinessService: SoleBusinessService) {
    super();
  }

  buildForm(): void {
    this.form = new SoleBusinessForm(this.business || plainToClass(SoleBusiness, {}));
  }

  save(business: SoleBusiness): void {
    if (business.id) {
      this.soleBusinessService.put(business).subscribe(() => {
        this.saved.emit(business);
        this.toastService.success(SoleBusinessFormComponent.updatedMessage);
      });
    } else {
      this.soleBusinessService.post(business).subscribe(() => {
        this.saved.emit(business);
        this.toastService.success(SoleBusinessFormComponent.createdMessage);
      });
    }
  }
}
