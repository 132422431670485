import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  SubscriptionBannerUpgradeComponent
} from '@components/subscription-banner/subscription-banner-upgrade/subscription-banner-upgrade.component';
import {DialogData} from '@client/shared/components/document/upload-document-dialog/upload-document-dialog.component';
import {
  SubscriptionChangeDialogComponent
} from '@client/shared/components/subscription/subscription-change-dialog/subscription-change-dialog.component';
import { ServiceProductIdEnum } from 'taxtank-core';

/**
 * @TODO copypast, one component with different params is enough for all tanks
 */
@Component({
  selector: 'app-subscription-banner-upgrade-holding',
  templateUrl: './subscription-banner-upgrade-holding.component.html',
  styleUrls: ['./subscription-banner-upgrade-holding.component.scss']
})
export class SubscriptionBannerUpgradeHoldingComponent {
  constructor(
    private dialogRef: MatDialogRef<SubscriptionBannerUpgradeComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  openSubscriptionDialog(): void {
    this.dialog.open(SubscriptionChangeDialogComponent, {
      panelClass: 'dialog-large',
      data: ServiceProductIdEnum.HOLDINGS_TANK
    });

    this.dialogRef.close();
  }
}
