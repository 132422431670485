import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {AppFile, AssetsService, FileService, IAsset} from 'taxtank-core';

/**
 * Directive to download assets
 */
@Directive({
  selector: '[appDownload]'
})
export class DownloadDirective {
  // @TODO remove IAsset after refactoring
  @Input('appDownload') asset: IAsset | AppFile;

  @HostListener('click') onClick(): void {
    if (this.asset instanceof AppFile) {
      this.el.nativeElement.disabled = true;
      this.fileService.download(this.asset).subscribe((blob: Blob) => this.download(blob));
    } else {
      window.open(this.assetsService.download(this.asset), '_self');
    }
  }

  constructor(private assetsService: AssetsService, private fileService: FileService, private el: ElementRef) {
  }

  private download(blob: Blob) {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = this.asset.originalName;
    downloadLink.dispatchEvent(new MouseEvent('click'));
    this.el.nativeElement.disabled = false;
  }
}
