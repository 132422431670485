import { Directive, HostListener, Input } from '@angular/core';
import { ToastService } from 'taxtank-core';
import { Clipboard } from '@angular/cdk/clipboard';

/**
 * Copy provided content to clipboard.
 * Directive can be placed on any element and will copy the data by click on it
 */
@Directive({
  selector: '[appCopyToClipboard]'
})
export class CopyToClipboardDirective {
  @Input('appCopyToClipboard') content: string | number;

  constructor(
    private clipboard: Clipboard,
    private toastService: ToastService
  ) {
  }

  @HostListener('click') onClick(): void {
    this.content = this.content.toString();

    this.clipboard.copy(this.content);
    this.toastService.success('Copied to clipboard');
  }
}
