<nav mat-tab-nav-bar
     [ngClass]="{
        'primary': color === 'primary',
        'secondary': color === 'secondary',
      }"
     [disablePagination]="true"
     [tabPanel]="tabPanel">

  <swiper #swiper class="navigation-carousel" [config]="carouselConfig">
    <ng-template *ngFor="let link of links; let i = index" swiperSlide>
      <a (click)="slideToActiveTab(i)"
         mat-tab-link
         routerLinkActive
         #rla="routerLinkActive"
         (isActiveChange)="slideToActiveTab(i)"
         [attr.id]="link.id"
         [active]="rla.isActive"
         [routerLink]="[link.url]">
        {{link.label}}
      </a>
    </ng-template>
  </swiper>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
