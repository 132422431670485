import { Directive, Input, OnDestroy, Optional, Self, SkipSelf } from '@angular/core';
import { FormGroupDirective as AngularFormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * extends base FormGroupDirective functionality
 * handles submits of sub-forbs
 */
@Directive({
  selector: '[formGroup]'
})
export class FormGroupDirective implements OnDestroy {
  @Input('formGroup') form: UntypedFormGroup = null!;
  private destroyed = new Subject<void>();

  constructor(
    @Self() formGroupDirective: AngularFormGroupDirective,
    @Optional() @SkipSelf() parentFormGroupDirective: AngularFormGroupDirective
  ) {
    if (parentFormGroupDirective) {
      parentFormGroupDirective.ngSubmit.pipe(takeUntil(this.destroyed)).subscribe((event: any) => {
        formGroupDirective.onSubmit(event);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
