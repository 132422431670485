import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NgControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Collection, HoldingType, HoldingTypeCategoryEnum, HoldingTypeService } from 'taxtank-core';
import { AbstractControlComponent } from '../../abstract-control/abstract-control.component';

@Component({
  selector: 'app-holding-type-multiselect',
  templateUrl: './holding-type-multiselect.component.html',
  styleUrls: ['./holding-type-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HoldingTypeMultiselectComponent extends AbstractControlComponent<HoldingType[]> implements OnChanges, OnInit {
  @Input() holdingTypes: Collection<HoldingType>;
  @Input() categories: HoldingTypeCategoryEnum[] = [];
  options: HoldingType[] = [];

  constructor(
    private holdingTypeService: HoldingTypeService,
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categories) {
      this.updateOptions();
    }

    if (changes.holdingTypes) {
      this.options = this.holdingTypes.toArray();
    }
  }

  ngOnInit(): void {
    if (!this.holdingTypes) {
      this.holdingTypeService.get()
        .pipe(takeUntil(this.destroy$))
        .subscribe((holdingTypes: Collection<HoldingType>) => {
          this.holdingTypes = holdingTypes;
          this.updateOptions();
        });
    }
  }

  private updateOptions(): void {
    if (!this.holdingTypes) {
      return;
    }

    let holdingTypes: Collection<HoldingType> = this.holdingTypes;

    if (this.categories.length) {
      holdingTypes = holdingTypes.filterBy('category', this.categories);
    }

    this.options = holdingTypes.toArray();
  }
}
