<ng-container [appPreloader]="[endpoints.SOLE_BUSINESSES_POST, endpoints.SOLE_BUSINESSES_PUT]"
              [preloaderType]="preloaderTypes.OVERLAY">
</ng-container>

<form [formGroup]="form" (ngSubmit)="submit()" appFormErrorScroll>
  <div class="row">
    <div class="col-12 col-lg-6">
      <mat-form-field>
        <mat-label>Business name</mat-label>
        <input matInput formControlName="name" placeholder="Enter your business name">
        <mat-error>
          <app-validation-error [errors]="form.get('name').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <app-business-activity-autocomplete formControlName="activity"></app-business-activity-autocomplete>
    </div>

    <div class="col-12">
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" placeholder="Enter a brief description of your business">
        <mat-error>
          <app-validation-error [errors]="form.get('description').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <!-- @TODO Alex: create separated form control -->
      <mat-form-field>
        <mat-label>Website</mat-label>
        <input matInput formControlName="website" placeholder="Business website (if applicable)">
        <mat-error>
          <app-validation-error [errors]="form.get('website').errors"></app-validation-error>
        </mat-error>
      </mat-form-field>
    </div>


    <div class="col-12 col-lg-6" *ngIf="form.contains('losses')" >
      <ng-container formArrayName="losses">
        <ng-container [formGroup]="form.lossFormGroup">
          <app-currency-input min="0"
                              formControlName="openBalance"
                              label="Prior year losses"
                              placeholder="Enter annual amount if applicable (or $0 to add later)">
          </app-currency-input>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="form.contains('incomeSource')" formGroupName="incomeSource">
      <!-- @TODO Alex (TT-2304): move to separated form component -->
      <ng-container formArrayName="soleForecasts">
        <ng-container [formGroup]="form.forecastFormGroup">
          <div class="col-12 col-lg-6">
            <app-currency-input formControlName="amount"
                                label="Forecasted business Profit/(Loss)"
                                placeholder="Enter annual amount">
            </app-currency-input>
          </div>

          <div class="col-12 col-lg-6">
            <app-currency-input min="0"
                                formControlName="taxInstalments"
                                label="Forecasted tax Instalments"
                                placeholder="Enter annual amount (or $0 to add later)">
            </app-currency-input>
          </div>

        </ng-container>
      </ng-container>
    </ng-container>

    <div class="col-12 col-lg-6 align-v-center">
      <mat-checkbox formControlName="isPrimaryProduction">This is a Primary Production Business</mat-checkbox>
    </div>
  </div>
</form>
