import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';

/**
 * Component that display photo of object that contains photo
 */
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
// todo: TT-4288 wait for wrap app-image in app-avatar and replace. check for the same todo
export class ImageComponent extends BaseComponent implements OnInit {
  @Input() src: string;
  /**
   * @TODO replace with size types (xs, s, m, l, xl)
   */
  @Input() size = 40;
  @Input() format: 'circle' | 'square' = 'circle';
  /**
   * @TODO Alex: add {required: true} and remove default value when Angular updated to v16
   */
  @Input(/* { required: true } */) placeholder: 'user' | 'property' | 'firm' | 'business' | 'bank' = 'user';
  @Input() toBase64 = false;
  base64: string;

  ngOnInit() {
    if (this.toBase64 && this.src) {
      this.getBase64ImageFromUrl(this.src)
        .then((result: string) => this.base64 = result)
        .catch(err => console.error(err));
    }
  }

  async getBase64ImageFromUrl(imageUrl: string) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader  = new FileReader();
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => reject(this);
      reader.readAsDataURL(blob);
    })
  }
}
