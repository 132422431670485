<mat-form-field [class]="inputClass">

  <mat-label class="with-action">
    <div class="align-h-space-between">
      <span>{{ label }}</span>

      <button *ngIf="canAdd"
              appStopPropagation
              type="button"
              class="btn icon icon-plus link"
              (click)="openAddItemDialog()">
        Add Business
      </button>
    </div>
  </mat-label>

  <mat-select [multiple]="multiple"
              [disabled]="disabled"
              [errorStateMatcher]="errorStateMatcher"
              [placeholder]="placeholder"
              [compareWith]="compareOptions"
              [(ngModel)]="value"
              (selectionChange)="onSelect($event.value)"
              (blur)="onTouched()">

    <ng-container *ngIf="options">

      <!-- Group mode -->
      <ng-container *ngIf="groupBy">
        <mat-option *ngIf="hasEmptyOption" [value]="false">{{emptyOptionLabel}}</mat-option>
        <mat-optgroup *ngFor="let groupName of groupedOptions.keys" [label]="groupName">
          <mat-option *ngFor="let business of groupedOptions.get(groupName)" [value]="business">
            {{business.name}}
          </mat-option>
        </mat-optgroup>
      </ng-container>

      <!-- List mode -->
      <ng-container *ngIf="!groupBy">
        <mat-option *ngIf="hasEmptyOption" [value]="false">{{emptyOptionLabel}}</mat-option>
        <mat-option [value]="business" *ngFor="let business of options">
          {{business.name}}
        </mat-option>
      </ng-container>

      <!-- Empty list -->
      <mat-option [value]="false" *ngIf="!options.length" [disabled]="true">
        {{emptyMessage}}
      </mat-option>
    </ng-container>

  </mat-select>

  <div matSuffix>
    <app-preloader *ngIf="isLoading" [type]="preloaderTypes.ICON" [size]="20"></app-preloader>
  </div>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
