import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CustomCurrencyPipe } from '@shared/pipe/custom-currency/custom-currency.pipe';
import { BaseChartComponent } from '@shared/component/charts/base-chart/base-chart.component';
import { COLORS, PALETTE_PRIMARY } from '@shared/globals/colors';
import { DashStyleEnum } from '@shared/component/charts/line-chart/dash-style.enum';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent extends BaseChartComponent implements OnInit, OnChanges {
  // first point displayed on chart
  @Input() pointStart: Date;
  // optional tooltip title
  @Input() pointName: string;
  // optional line style
  @Input() dashStyle: DashStyleEnum = DashStyleEnum.SOLID;

  constructor(
    public customCurrencyPipe: CustomCurrencyPipe
  ) {
    super(customCurrencyPipe);
  }

  ngOnInit(): void {
    this.initChart();
  }

  ngOnChanges(): void {
    this.initChart();
  }

  initChart(): void {
    if (!this.data.length) {
      return;
    }

    this.buildData();
    // set options declared in base class
    this.setDefaultOptions(this.customCurrencyPipe);
    // set options s
    this.setOptions(this.pointName, this.customCurrencyPipe);
    // hack for dynamic containers
    setTimeout(() => {
      this.showChart();
    });
  }

  buildData(): void {
    // @TODO Alex: check and refactor logic since we have new API (TT-648)
    this.chartData = this.data.map((item: object, index: number) => ({
      name: item[this.bindSeriesName],
      series: item[this.bindSeriesData].map((serie: any) => {
        if (!isNaN(new Date(serie[this.bindX]).getTime())) {
          const date: Date = new Date(serie[this.bindX]);
          return [Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()), serie[this.bindY]];
        }
        return [serie[this.bindX], serie[this.bindY]];
      }),
      dashStyle: item['ui'] ? item['ui'].getDashStyle() : this.dashStyle,
      // @TODO Alex create class for colors palette and use it everywhere (TT-649)
      lineColor: item['ui'] ? item['ui'].getLineColor() : PALETTE_PRIMARY[index]
    }));
  }

  setOptions(pointName: string, customCurrencyPipe: CustomCurrencyPipe): void {
    // show/hide Y axis
    this.chartOptions.yAxis.visible = false;

    // set x axis labels type
    this.chartOptions.xAxis.type = isNaN(new Date(this.data[0]?.[this.bindSeriesData][0]?.[this.bindX]).getTime()) ? 'category' : 'datetime';

    // set chart type
    this.chartOptions.chart.type = 'areaspline';

    // set tooltip text alignment
    this.chartOptions.tooltip.style.textAlign = 'center';

    // set tooltip template
    this.chartOptions.tooltip.formatter = function (): string {
      return `<span style="color: ${COLORS.grey}; font-size: 12px; line-height: 18px; font-weight: bold; text-transform: uppercase; font-family: Montserrat, sans-serif">
                    ${pointName || this.point.series.name}
                  </span>
                  <br>
                  <span style="font-size: 18px">
                    ${customCurrencyPipe.transform(this.point.y, true)}
                  </span>`;
    };

    // set series to chart
    this.chartOptions.series = this.chartData.map((dataItem: object, index: number) => ({
      name: dataItem['name'],
      data: dataItem['series'],
      pointStart: this.pointStart ? this.pointStart : 0,
      pointInterval: 3600 * 1000 * 24,
      fillColor: {
        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
        stops: [
          [0, Highcharts.color(dataItem['lineColor']).setOpacity(0.15).get('rgba')],
          [1, Highcharts.color(dataItem['lineColor']).setOpacity(0).get('rgba')]
        ]
      },
      dashStyle: dataItem['dashStyle'],
      lineColor: dataItem['lineColor'],
      color: dataItem['lineColor']
    }));
  }

  showChart(): void {
    Highcharts.chart(this.containerId, this.chartOptions);
  }
}
