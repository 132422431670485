import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractSelectComponent } from '@shared/component/form-controls/select/abstract-select/abstract-select.component';
import { BankAccount, BankAccountService, compareMatOptions } from 'taxtank-core';
import { NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BankConnectionsAddDialogComponent } from '@client/bank-feeds/components/bank-connections/bank-connections-add-dialog/bank-connections-add-dialog.component';

@Component({
  selector: 'app-bank-account-select',
  templateUrl: './bank-account-select.component.html',
  styleUrls: ['./bank-account-select.component.scss'],
})
export class BankAccountSelectComponent extends AbstractSelectComponent<BankAccount> {
  @Input() emptyMessage = 'Please, add bank account';
  @Input() emptyOptionLabel = 'All bank accounts';
  @Input() placeholder = 'Select bank account';
  @Input() label = 'Bank account';
  @Input() canAdd = true;
  compareWith = compareMatOptions;
  dialogComponent = BankConnectionsAddDialogComponent;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    protected dialog: MatDialog,
    private bankAccountService: BankAccountService
  ) {
    super(ngControl, changeDetectorRef);
  }

  getOptions$(): Observable<BankAccount[]> {
    return this.bankAccountService.getActive().pipe(map((bankAccounts) => bankAccounts.toArray()));
  }
}
