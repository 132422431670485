import { Component, Input, OnInit } from '@angular/core';
import {
  ClientCouponService,
  ServicePromoCode,
  ServiceSubscription,
  SubscriptionService,
  ToastService
} from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MessagesEnum } from './messages.enum';

/**
 * Stripe promo code component
 */
@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent extends BaseComponent implements OnInit {
  @Input() subscription: ServiceSubscription;
  /**
   * first payment per registered user could come with rewardful coupon
   */
  @Input() firstPayment: boolean;
  errorMessage: string;

  /**
   * @TODO Artem: remove and use preloader via endpoints
   */
  isLoading: boolean;

  constructor(
    private subscriptionService: SubscriptionService,
    private clientCouponService: ClientCouponService,
    private toastService: ToastService) {
    super();
  }

  ngOnInit() {
    // look for rewardful coupon for the first payment only
    if (this.firstPayment) {
      this.isLoading = true;
      this.clientCouponService.getFirst().subscribe((promoCode) => {
        this.isLoading = false;
        this.subscription.applyPromoCode(promoCode);
      });
    }
  }

  apply(promoCode: string): void {
    this.isLoading = true;
    // @TODO waiting for additional param isAnnual on backend
    this.subscriptionService.getCoupon(promoCode).subscribe(
      (promoCode: ServicePromoCode) => {
        this.isLoading = false;
        if (this.subscription.applyPromoCode(promoCode)) {
          this.errorMessage = null;
        } else {
          this.errorMessage = MessagesEnum.PROMO_CODE_NOT_FOUND;
          this.toastService.error(MessagesEnum.PROMO_CODE_NOT_FOUND);
        }
      },
      // Handle error when user applied incorrect code (code does not exist)
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        if (error.status === 404) {
          this.errorMessage = MessagesEnum.PROMO_CODE_NOT_FOUND;
          this.toastService.error(MessagesEnum.PROMO_CODE_NOT_FOUND);
        }
      });
  }

  remove(): void {
    this.subscription.promoCode = null;
  }
}
