<header mat-dialog-title>
  <div class="dialog-caption">{{ !!invoiceTemplate.id ? 'Edit invoice template' : 'New invoice template' }}</div>
  <button mat-dialog-close class="btn icon-close"></button>
</header>

<div mat-dialog-content>
  <app-invoice-template-form #invoiceTemplateFormComponent
                             [invoiceTemplate]="invoiceTemplate"
                             (saved)="dialogRef.close()">
  </app-invoice-template-form>
</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-sm-auto">
      <button mat-dialog-close class="btn basic w-100">Cancel</button>
    </div>
    <div class="col-6 col-sm-auto">
      <button type="submit" class="btn primary w-100" [appFormSubmit]="invoiceTemplateFormComponent">Save</button>
    </div>
  </div>
</div>
