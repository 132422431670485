import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import {
  ChartAccounts,
  ChartAccountsCategoryEnum,
  ChartAccountsCollection,
  ChartAccountsService,
  Collection
} from 'taxtank-core';
import {
  AbstractAutocompleteComponent
} from '@shared/component/form-controls/autocomplete/abstract-autocomplete/abstract-autocomplete.component';
import { Observable } from 'rxjs';
import uniqBy from 'lodash/uniqBy';
import intersection from 'lodash/intersection'
import {
  ChartAccountsManageDialogComponent
} from '@client/shared/components/chart-accounts/chart-accounts-manage-dialog/chart-accounts-manage-dialog.component';

/**
 * Position of the autocomplete panel relative to the trigger element
 */
declare type PositionType = 'auto' | 'above' | 'below';

/**
 * @TODO Alex what's about showExpenseTooltip input?
 * Chart accounts autocomplete
 */
@Component({
  selector: 'app-chart-accounts-autocomplete',
  templateUrl: './chart-accounts-autocomplete.component.html',
  styleUrls: ['./chart-accounts-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartAccountsAutocompleteComponent extends AbstractAutocompleteComponent<ChartAccounts> implements OnChanges {
  @Input() categories: ChartAccountsCategoryEnum[];
  @Input() ids: number[];
  @Input() skipIds: number[] = ChartAccounts.systemList;
  @Input() matAutocompletePosition: PositionType = 'auto';
  @Input() label = 'Category';
  @Input() placeholder = 'Select category';
  @Input() groupBy = 'heading.name';
  @Input() minSearchLength = 0;
  dialogComponent = ChartAccountsManageDialogComponent;
  chartAccounts: ChartAccountsCollection;
  sortedKeys: string[];

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private chartAccountsService: ChartAccountsService
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.categories && !changes.categories.isFirstChange()) {
      this.filter();
      this.groupOptions();
    }
  }

  getOptions$(): Observable<ChartAccounts[]> {
    return this.chartAccountsService.get()
      .pipe(
        map((chartAccounts: ChartAccountsCollection) => {
          this.chartAccounts = chartAccounts;
          this.filter();

          return this.options.toArray();
        })
      );
  }

  private filter() {
    let chartAccounts = this.chartAccounts;
    if (this.categories) {
      chartAccounts = chartAccounts.filterBy('category', this.categories);
    }

    if (this.ids) {
      chartAccounts = chartAccounts.filterBy('id', this.ids);
    }

    if (this.skipIds) {
      chartAccounts = chartAccounts.filter((ca) => !this.skipIds.includes(ca.id));
    }

    this.filteredOptions = this.options = chartAccounts;
  }

  protected groupOptions(): void {
    super.groupOptions();

    // chart accounts now has sorting by heading.order.
    // we can not sort dictionary directly, so we sort keys separately
    const sortedKeys = new Collection(uniqBy(this.options.mapBy('heading'), 'id')).sortBy('order', 'asc').mapBy('name');
    // use only sorted keys from filtered options
    this.sortedKeys = intersection(sortedKeys, this.groupedOptions.keys);
  }
}
