<app-preloader *ngIf="isLoading" [type]="preloaderTypes.OVERLAY"></app-preloader>

<div mat-dialog-title>
  <div class="dialog-caption">Payment details</div>

  <button mat-dialog-close class="btn icon-close ic-secondary"></button>
</div>

<div mat-dialog-content>
  <div *ngIf="errorMessage" id="error-message" class="form-group-error">
    {{errorMessage}}
  </div>

  <form id="payment-form">
    <div id="payment-element">
      <!-- Stripe will put form here -->
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <div class="row g-3 align-h-right">
    <div class="col-12 col-sm-auto">
      <button mat-dialog-close class="btn basic">Cancel</button>
    </div>

    <div class="col-12 col-sm-auto">
      <button id="submit" class="btn primary" (click)="submit()">Save</button>
    </div>
  </div>
</div>
