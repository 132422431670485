import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { BankAccount, BankConnection } from 'taxtank-core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * @TODO rename
 * add bank connection/bankAccounts process for both flows: manual and basiq
 */
@Component({
  selector: 'app-bank-connections-add-dialog',
  templateUrl: './bank-connections-add-dialog.component.html',
  styleUrls: ['./bank-connections-add-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankConnectionsAddDialogComponent extends BaseComponent {
  /**
   * New imported/created bank accounts
   */
  bankAccounts: BankAccount[];

  /**
   * This dialog could be opened directly from bank login step when we need to reconnect invalid bank connection
   */
  constructor(@Inject(MAT_DIALOG_DATA) public connection: BankConnection) {
    super();
  }

  /**
   * reset filled data, move to the first step
   * @TODO Alex takes too long to reset (probably because of images)
   */
  restart(): void {
    this.connection = null;
    this.bankAccounts = null;
  }
}
