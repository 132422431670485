import { Component, Input, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { PropertyShare, PropertyShareForm } from 'taxtank-core';

@Component({
  selector: 'app-property-share-form',
  templateUrl: './property-share-form.component.html',
  styleUrls: ['./property-share-form.component.scss']
})
export class PropertyShareFormComponent implements OnInit {
  @Input() share: PropertyShare;
  @Input() form: PropertyShareForm;
  // UI flag: remove paddings and background at the form
  @Input() isTransparent: boolean;

  ngOnInit(): void {
    if (!this.share) {
      this.share = plainToClass(PropertyShare, {});
    }

    if (!this.form) {
      this.buildForm();
    }
  }

  buildForm(): void {
    this.form = new PropertyShareForm(this.share);
  }
}
