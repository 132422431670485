<ng-container [appPreloader]="[endpoints.PROPERTIES_CATEGORIES_MOVEMENTS_GET]" [preloaderType]="preloaderTypes.OVERLAY"></ng-container>
<form *ngIf="form" [formGroup]="form" [class.transparent]="isTransparent" appFormErrorScroll>
  <div class="row">
    <!-- Income input -->
    <div class="col-12 col-md-6" *ngIf="showFinancialYear">
      <mat-form-field>
        <mat-label>Financial Year</mat-label>
        <input matInput
               placeholder="Financial Year"
               formControlName="financialYear"
               [disabled]="true"
               required>
      </mat-form-field>
    </div>

    <!-- Income input -->
    <div class="col-12 col-md-6" *ngIf="form.get('income').enabled">
      <app-currency-input min="0"
                          inputId="onBoardingPropertyRentalIncAdd"
                          formControlName="income"
                          label="Rental Income"
                          placeholder="Enter annual Income">
      </app-currency-input>
    </div>

    <!-- Expenses input -->
    <div class="col-12 col-md-6">
      <app-currency-input min="0"
                          inputId="onBoardingPropertyRentalExpAdd"
                          formControlName="expense"
                          [label]="expenseLabel"
                          placeholder="Enter annual Expenses">
      </app-currency-input>
    </div>

    <!-- Market Value input -->
    <div class="col-12 col-md-6" *ngIf="!hideMarketValue">
      <app-currency-input min="0"
                          formControlName="marketValue"
                          label="Current Value"
                          placeholder="Enter Current Value">
      </app-currency-input>
    </div>

    <!-- Interest input -->
    <div class="col-12 col-md-6">
      <app-currency-input min="0"
                          inputId="onBoardingPropertyRentalLoanAdd"
                          formControlName="interest"
                          [label]="interestLabel"
                          placeholder="Enter annual Interest">
      </app-currency-input>
    </div>

    <!-- Depreciation input -->
    <div class="col-12 col-md-6" *ngIf="form.get('depreciation').enabled">
      <app-currency-input min="0"
                          inputId="onBoardingPropertyRentalDepAdd"
                          formControlName="depreciation"
                          label="Rental Depreciation"
                          placeholder="Enter annual Depreciation">
      </app-currency-input>
    </div>

    <!-- ClaimPercent input -->
    <div class="col-12 col-md-6" *ngIf="form.get('claimPercent').enabled">
      <app-property-claim-percentage-calculator [form]="form"></app-property-claim-percentage-calculator>
    </div>
  </div>
</form>
