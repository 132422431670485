<div class="p-password-wrapper">
  <p-password [toggleMask]="toggleMask"
              [feedback]="feedback"
              [required]="required"
              [minlength]="minlength"
              [placeholder]="placeholder"
              [formControl]="ngControl.control">
        <ng-template pTemplate="footer" class="panel-css">
          <p class="mt-2">Minimum 6 characters</p>
          <ul class="pl-2 ml-2 mt-0">
            <li>At least one lowercase</li>
            <li>At least one uppercase or one number</li>
          </ul>
        </ng-template>
  </p-password>
  <label>{{label}}</label>
  <app-validation-error *ngIf="ngControl.touched" [errors]="ngControl.errors"></app-validation-error>
</div>
