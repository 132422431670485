import { Component, Inject } from '@angular/core';
import {  MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { SoleContact } from 'taxtank-core';

@Component({
  selector: 'app-sole-contact-form-dialog',
  templateUrl: './sole-contact-form-dialog.component.html',
  styleUrls: ['./sole-contact-form-dialog.component.scss']
})
export class SoleContactFormDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SoleContactFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public soleContact: SoleContact
  ) { }
}
