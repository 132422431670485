import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from 'taxtank-core';

/**
 * hides some interface elements for co-owner with view-only access
 * the implementation is very similar to *ngIf directive
 */
@Directive({
  selector: '[appAccess]'
})
export class AccessDirective {
  private hasAccess: boolean;
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private userService: UserService) {}

  @Input() set appAccess(userId: number) {
    this.hasAccess = userId === this.userService.getCacheFirst().id;
    if (this.hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  @Input() set appAccessElse(templateRef: TemplateRef<any>) {
    if (!this.hasAccess) {
      this.viewContainer.createEmbeddedView(templateRef);
    }
  }
}
