import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/component/base/base.component';
import { AreaTypeEnum } from '@shared/enums/area-type.enum';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService, User, UserStatusEnum } from 'taxtank-core';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * @TODO wrong place
 */
@Component({
  selector: 'app-subscription-banner-onboarding',
  templateUrl: './subscription-banner-onboarding.component.html',
  styleUrls: ['./subscription-banner-onboarding.component.scss']
})
export class SubscriptionBannerOnboardingComponent extends BaseComponent implements OnInit {
  user: User;
  areaType: AreaTypeEnum;

  constructor(
    protected router: Router,
    protected toastService: ToastService,
    protected dialogRef: MatDialogRef<SubscriptionBannerOnboardingComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.userService.getFirst().subscribe((user: User) => {
      this.user = user;
      this.areaType = this.user.isEmployee() ? AreaTypeEnum.FIRM : AreaTypeEnum.CLIENT;
    });
  }

  startOnboarding() {
    this.dialogRef.close();
    this.router.navigateByUrl(`/${this.areaType}/onboarding`);
  }

  skipOnboarding(): void {
    this.user.status = UserStatusEnum.ACTIVE;

    this.userService.finishOnboarding(this.user).subscribe(
      (): void => {
        this.dialogRef.close(true);
        this.router.navigateByUrl(`/${this.areaType}/dashboard`);
      },
      (error: HttpErrorResponse): void => {
        this.toastService.error(error.error);
      }
    );
  }
}
