import { UntypedFormArray } from '@angular/forms';
import { plainToClass } from 'class-transformer';
import { Property, PropertyAddForm } from 'taxtank-core';

/**
 * Form class for array of new properties
 */
export class PropertiesForm extends UntypedFormArray {
  /**
   * Add a new property form
   */
  addProperty(): void {
    this.push(new PropertyAddForm());
  }

  /**
   * Submit form and return array of properties from form value
   */
  submit(): Property[] {
    return this.controls.map((propertyForm: PropertyAddForm): Property => plainToClass(Property, propertyForm.value));
  }
}
