import { Component, Input, OnInit } from '@angular/core';
import Timeout = NodeJS.Timeout;

/**
 * Progress bar auto filled by interval.
 * @TODO Alex: move functionality to app-preloader or app-progressbar
 * @TODO Alex: make it more dynamic (different time between messages, smooth loading, etc.)
 * @TODO Alex (TT-2431): fix all review notes from here (https://gitlab.com/TaxTank/taxtank_app/-/merge_requests/1375)
 */
@Component({
  selector: 'app-progress-preloader',
  templateUrl: './progress-preloader.component.html',
  styleUrls: ['./progress-preloader.component.scss']
})
export class ProgressPreloaderComponent implements OnInit {
  /**
   * Total progress duration in milliseconds
   */
  @Input() duration: number;

  /**
   * List of messages to display during progress
   */
  @Input() messages: string[];

  /**
   * Total progress bar value
   */
  @Input() total = 100;

  @Input() autoStart: boolean;

  /**
   * Current progress bar value
   */
  value = 0;

  /**
   * Time elapsed since start
   */
  progressTime = 0;

  /**
   * Index of message displayed
   */
  currentMessageIndex = 0;

  /**
   * Keep interval id to stop it
   */
  interval: Timeout;

  ngOnInit(): void {
    if (this.autoStart) {
      this.start();
    }
  }

  start(): void {
    this.reset();

    // @TODO Alex (TT-2431): move to separated method with comments and better names
    const messageSwitchPercent = Math.floor(this.total / (this.messages.length - 1));

    this.interval = setInterval(() => {
      if (this.value === 100) {
        return;
      }

      this.progressTime += 1000;

      this.value = (this.progressTime / this.duration) * 100;

      if (this.value > messageSwitchPercent * this.currentMessageIndex && this.currentMessageIndex < this.messages.length - 1) {
        this.currentMessageIndex++;
      }
    }, 1000);
  }

  stop(): void {
    clearInterval(this.interval);
  }

  reset(): void {
    this.value = 0;
    this.progressTime = 0;
    this.currentMessageIndex = 0;
  }
}
