import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { IncomeSourceComponent } from '@client/shared/components/income-source/income-source.component';
import { Observable, forkJoin } from 'rxjs';
import { lessThanValidator } from '@shared/validators/less-than.validator';
import { plainToClass } from 'class-transformer';
import {
  FinancialYear,
  IncomeSource, IncomeSourceTypeEnum,
  SalaryForecast,
  SalaryForecastFrequencyEnum,
  User,
} from 'taxtank-core';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * @TODO refactor everything
 * Component that contains salary incomes form and actions to work with it
 */
@Component({
  selector: 'app-income-source-salary',
  templateUrl: './income-source-salary.component.html',
  styleUrls: ['./income-source-salary.component.scss', '../income-source.component.scss']
})
export class IncomeSourceSalaryComponent extends IncomeSourceComponent implements OnInit {
  // is common for all forms
  isAustralianResident: boolean;
  form: UntypedFormArray;
  user: User;
  paymentFrequencyTypes = SalaryForecastFrequencyEnum;
  // edit mode for manual change
  isNetAmountEdit = false;
  isTaxCalculationLoading = false;
  urlFragment = 'salary';

  /**
   * Add salary form group with controls
   */
  addFormGroup(incomeSource: IncomeSource = plainToClass(IncomeSource, {
    type: IncomeSourceTypeEnum.WORK,
    salaryForecasts: [plainToClass(SalaryForecast, {})]
  })): void {
    const newGroup: UntypedFormGroup = this.fb.group({
      name: [incomeSource.name, Validators.required],
      type: [incomeSource.type],
      dateFrom: [incomeSource.dateFrom || new FinancialYear().startDate, Validators.required],
      dateTo: [incomeSource.dateTo],
      // flag that means whether the job is current or not
      isCurrent: [incomeSource.id ? !incomeSource.dateTo : true],
      closeReason: [incomeSource.closeReason],
      salaryForecasts: this.fb.array(
        incomeSource.salaryForecasts.map((salaryForecast: SalaryForecast) => this.fb.group({
          paygIncome: [salaryForecast.paygIncome, Validators.required],
          // for non-australian resident 'isTaxFree' will be 'false'
          isTaxFree: [salaryForecast.isTaxFree ?? (this.user.clientDetails.isAustralianResident ? null : false), Validators.required],
          frequency: [salaryForecast.frequency, Validators.required],
          tax: [salaryForecast.tax],
          netPay: [salaryForecast.netPay, Validators.required],
          grossAmount: [salaryForecast.grossAmount],
          financialYear: [salaryForecast.financialYear || new FinancialYear().year]
        },
        {
          validators: [
            lessThanValidator(
              'netPay',
              'grossAmount',
              'Net amount',
              'Gross amount',
              true)
          ]
        }))
      )
    });

    this.form.push(newGroup);
    this.form.updateValueAndValidity();
    newGroup.get('isCurrent').valueChanges.subscribe((isCurrent) => {
      if (!isCurrent) {
        return;
      }

      newGroup.get('dateTo').setValue(null);
      newGroup.get('closeReason').setValue(null);
    });
    this.subscribeForSalaryForecastControlsChanges(newGroup, this.form.controls.indexOf(newGroup));
    this.isAustralianResident = this.user.clientDetails.isAustralianResident;

    // Select created salary form
    this.selectForm(this.form.controls.length - 1);
  }

  /**
   * Subscribe to incomeSourceType form control
   * @param formGroup with desired salaryForecast form controls
   * @param groupIndex index of provided form group
   */
  subscribeForSalaryForecastControlsChanges(formGroup: UntypedFormGroup, groupIndex: number): void {
    const salaryForecastFormArray: UntypedFormArray = formGroup.get('salaryForecasts') as UntypedFormArray;
    const salaryForecastFormGroup: UntypedFormGroup = salaryForecastFormArray.controls[0] as UntypedFormGroup;

    salaryForecastFormGroup.valueChanges.subscribe(() => {
      if (!this.isNetAmountEdit) {
        this.setTaxCalculation(salaryForecastFormGroup, groupIndex);
      }
    });
  }

  /**
   * Calculate tax for salary
   * @param formGroup
   * @param index
   */
  setTaxCalculation(formGroup: UntypedFormGroup, index: number): void {
    const controlsArray: AbstractControl[] = [formGroup.get('paygIncome'), formGroup.get('frequency'), formGroup.get('isTaxFree')];
    // allow request only if all required has not empty values
    if (!controlsArray.every((control: AbstractControl) => control.value !== null)) {
      return;
    }

    formGroup.value.isAustralianResident = this.isAustralianResident;

    this.incomeSourceService.getTaxCalculation(formGroup.value).subscribe((salaryForecast: SalaryForecast) => {
      formGroup.get('grossAmount').setValue(salaryForecast.netPay + salaryForecast.tax, { emitEvent: false });
      formGroup.get('netPay').setValue(salaryForecast.netPay, { emitEvent: false });
      formGroup.get('tax').setValue(salaryForecast.tax, { emitEvent: false });
    });
  }

  /**
   * Handle this change separately from form because tax residency is common for all income-source sources
   */
  onResidencyChanged(): void {
    this.form.controls.forEach((group: UntypedFormGroup, index: number) => {
      const salaryForecastFormArray: UntypedFormArray = group.get('salaryForecasts') as UntypedFormArray;
      // isTaxFreeThreshold always false for non-residents
      salaryForecastFormArray.controls[0].get('isTaxFree').setValue(false);
    });
  }

  /**
   * Start edit net amount value
   */
  startEditNetAmount(): void {
    this.isNetAmountEdit = true;
  }

  /**
   * Finish edit net amount value
   */
  finishEditNetAmount(salaryControl: UntypedFormGroup): void {
    if (!salaryControl.get('netPay').valid) {
      return;
    }

    // set 'tax' form control value
    salaryControl.get('tax')
      .setValue(salaryControl.get('grossAmount').value - salaryControl.get('netPay').value);

    this.isNetAmountEdit = false;
  }

  /**
   * Update user and send income sources to add/update
   */
  sendData(): void {
    // income sources to add/update
    const incomeSources: IncomeSource[] = [];

    this.form.value
      .forEach((tabValue: object, index: number) => {
        // push into 'incomeSources' array tab value and initial income source (if it exists)
        incomeSources.push(Object.assign(plainToClass(IncomeSource, {}), this.incomeSources[index], tabValue));

        // Add forecast id to all updating incomeSources
        const incomeSourceIndex = incomeSources.findIndex((incomeItem: IncomeSource) => incomeItem?.id === this.incomeSources[index]?.id);
        if (incomeSourceIndex >= 0 && this.incomeSources[index]) {
          incomeSources[incomeSourceIndex].salaryForecasts[0].id = this.incomeSources[index].salaryForecasts[0].id;
        }
      });

    const batch: Array<Observable<IncomeSource[]> | Observable<User>> = this.setupBatchToSend(incomeSources);

    // Update user and send incomes to add and update on the backend
    forkJoin(batch)
      .subscribe((incomeSourceResponse: Array<IncomeSource[]>) => {
        // @TODO Alex: show message via toast service, remove string (waiting for services refactoring)
        this.toastService.success('Income source data saved');
        this.submitted.emit(incomeSourceResponse);
      }, (errorResponse: HttpErrorResponse) => {
        if (errorResponse.status !== 400) {
          return;
        }

        this.toastService.error(errorResponse.error.violations[0].message);
      });
  }

  /**
   * Setup batch with observables based on income sources / forecasts array length
   * @param incomeSources
   */
  private setupBatchToSend(incomeSources: IncomeSource[]): Array<Observable<IncomeSource[]> | Observable<User>> {
    const batch: Array<Observable<IncomeSource[]> | Observable<User>> = [];

    const incomeSourcesToAdd: IncomeSource[] = incomeSources.filter((incomeSource: IncomeSource) => !incomeSource.id);
    const incomeSourcesToUpdate: IncomeSource[] = incomeSources.filter((incomeSource: IncomeSource) => incomeSource.id);

    // if new income sources exist - push it to the batch
    if (incomeSourcesToAdd.length) {
      batch.push(this.incomeSourceService.addBatch(incomeSourcesToAdd));
    }

    // if income sources to update exist - push it to the batch
    if (incomeSourcesToUpdate.length) {
      batch.push(this.incomeSourceService.updateBatch(incomeSourcesToUpdate));
    }

    // if isAustralianResident value was changed - set it as user's resident value and add to the batch
    if (this.isAustralianResident !== this.user.clientDetails.isAustralianResident) {
      this.user.clientDetails.isAustralianResident = this.isAustralianResident;
      batch.push(this.userService.put(this.user));
    }

    return batch;
  }
}
