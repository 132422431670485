import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnInit,
  Output
} from '@angular/core';
import {
  ServiceSubscription
} from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * Stripe promo code information component
 */
@Component({
  selector: 'app-accept-promocode',
  templateUrl: './accept-promocode.component.html',
  styleUrls: ['./accept-promocode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcceptPromocodeComponent extends BaseComponent implements OnInit {
  @Input() subscription: ServiceSubscription;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.cd.detectChanges();
  }
}
