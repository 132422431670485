<div mat-dialog-title>
  <div class="dialog-caption">{{ data.incomeSource.id ? 'Edit Payer' : 'Add Payer' }}</div>
  <button mat-dialog-close class="btn icon-close"></button>
</div>

<div mat-dialog-content class="p-0">
  <div [id]="'incomeSource' + (incomeSourceTypes[data.incomeSource.type] | translate)">
    <app-income-source-salary #incomeSourceComponent
                              *ngIf="data.isSalary"
                              [incomeSource]="data.incomeSource"
                              (submitted)="cancel($event)">
    </app-income-source-salary>

    <app-income-source-other-income #incomeSourceComponent
                                    *ngIf="!data.isSalary"
                                    [incomeSource]="data.incomeSource"
                                    (submitted)="cancel($event)">
    </app-income-source-other-income>
  </div>

  <div mat-dialog-actions>
    <div class="row gx-3 align-h-right">
      <div class="col-6 col-sm-auto">
        <button mat-dialog-close class="btn basic w-100">Cancel</button>
      </div>
      <div class="col-6 col-sm-auto">
        <button id="saveIncomeSourceButton" class="btn primary w-100" (click)="incomeSourceComponent.submit()">
          {{ data.incomeSource.id ? 'Update Payer' : 'Add Payer' }}
        </button>
      </div>
    </div>
  </div>
</div>
