<mat-form-field [class]="inputClass">
  <mat-label>{{ label }}</mat-label>

  <mat-select [multiple]="multiple"
              [disabled]="disabled"
              [errorStateMatcher]="errorStateMatcher"
              [placeholder]="placeholder"
              [compareWith]="compareWith"
              [(ngModel)]="value"
              (selectionChange)="onSelect($event.value)"
              (blur)="onTouched()">

    <ng-container *ngIf="options">

      <!-- Group mode -->
      <ng-container *ngIf="groupBy">
        <mat-option *ngIf="hasEmptyOption" [value]="false">{{emptyOptionLabel}}</mat-option>
        <mat-optgroup *ngFor="let groupName of groupedOptions.keys" [label]="groupName">
          <mat-option *ngFor="let bankAccount of groupedOptions.get(groupName)" [value]="bankAccount">
            {{bankAccount.accountName}}
          </mat-option>
        </mat-optgroup>
      </ng-container>

      <!-- List mode -->
      <ng-container *ngIf="!groupBy">
        <mat-option *ngIf="hasEmptyOption" [value]="false">{{emptyOptionLabel}}</mat-option>
        <mat-option [value]="bankAccount" *ngFor="let bankAccount of options">
          {{bankAccount.accountName}}
        </mat-option>
      </ng-container>

      <!-- Empty list -->
      <mat-option [value]="false" *ngIf="!options.length" [disabled]="true">
        {{emptyMessage}}
      </mat-option>

      <!-- Add account button-->
      <mat-option *ngIf="canAdd" [routerLink]="['/client/bank-feeds']" class="c-primary">
        <i class="icon icon-plus ic-primary"></i>
        Add bank account
      </mat-option>
    </ng-container>

  </mat-select>

  <div matSuffix>
    <app-preloader *ngIf="isLoading" [type]="preloaderTypes.ICON" [size]="20"></app-preloader>
  </div>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
