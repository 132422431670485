import { Component, Input } from '@angular/core';
import { BankAccount, BankAccountTypeEnum } from 'taxtank-core';

/**
 * UI Component: box with main bank account information
 */
@Component({
  selector: 'app-bank-account-preview-card',
  templateUrl: './bank-account-preview-card.component.html',
  styleUrls: ['./bank-account-preview-card.component.scss']
})
export class BankAccountPreviewCardComponent {
  @Input() bankAccount: BankAccount;
  bankAccountTypes: typeof BankAccountTypeEnum = BankAccountTypeEnum;
}
