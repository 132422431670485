<div [class.bank-card]="isBankCard" [class.text-left]="isTextLeft" class="data-card">
  <div class="data-card-title">
    <ng-container *ngIf="header">{{header}}</ng-container>
    <ng-content *ngIf="!header" select="[slot='header']"></ng-content>
  </div>
  <div class="data-card-tooltip">
    <ng-content select="[slot='tooltip']"></ng-content>
  </div>
  <div class="data-card-value">
    <ng-content select="[slot='content']"></ng-content>
  </div>
  <div class="data-card-footer">
    <ng-content select="[slot='footer']"></ng-content>
  </div>
</div>
