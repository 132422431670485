import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Bank,
  BankConnection,
  BasiqService,
  BasiqTokenService
} from 'taxtank-core';
import { plainToClass } from 'class-transformer';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * @TODO vik/alex waiting for basiq refactoring
 */
@Component({
  selector: 'app-basiq-connect',
  templateUrl: './basiq-connect.component.html',
  styleUrls: ['./basiq-connect.component.scss']
})
export class BasiqConnectComponent extends BaseComponent {
  @Input() bank: Bank;
  @Output() connected: EventEmitter<BankConnection> = new EventEmitter();

  constructor(private basiqTokenService: BasiqTokenService, private basiqService: BasiqService) {
    super();
  }

  connect(): void {
    if (this.bank.isManual()) {
      this.connected.emit(plainToClass(BankConnection, { bank: this.bank }));
      return;
    }

    const basiqConsentId = this.userService.getCacheFirst().clientDetails.basiqConsentId;
    // @TODO Alex refactor to interceptor
    this.basiqTokenService.getFirst().subscribe((token) => {
      this.basiqService.redirectToBasiqConsent(token.value, this.bank.externalId, !!basiqConsentId);
    });
  }
}
