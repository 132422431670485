import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Collection,
  ServicePaymentMethod, ServicePaymentMethodService,
  ServiceSubscription,
  SubscriptionMessagesEnum,
  ToastService
} from 'taxtank-core';
import { PaymentIntent, StripeElements, StripeElementsOptions } from '@stripe/stripe-js';
import { BaseComponent } from '@shared/component/base/base.component';
import { map } from 'rxjs/operators';
import {
  StripeResponseStatusEnum
} from '@client/my-account/my-account-subscription/components/current-subscription/stripe-response-status.enum';
import { HttpErrorResponse } from '@angular/common/http';
import {
  STRIPE_ELEMENTS_OPTIONS
} from '@client/my-account/my-account-subscription/components/payment-methods/stripe-elements-options.const';
import { Observable } from 'rxjs';

interface DialogData {
  subscription: ServiceSubscription,
  stripeClientSecret: string
}

/**
 * Component with Stripe payment form
 */
@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent extends BaseComponent implements OnInit {
  /**
   * Stripe elements using for payment form
   */
  stripeElements: StripeElements;

  /**
   * User's saved payment methods
   */
  paymentMethods$: Observable<Collection<ServicePaymentMethod>>;

  /**
   * Selected saved payment method. Show payment form when false
   */
  selectedPaymentMethod: ServicePaymentMethod | null;

  /**
   * Error message sent by Stripe if something wrong
   */
  errorMessage: string;

  /**
   * Flag show preloader when stripe is loading
   */
  isLoading: boolean;

  constructor(
    private servicePaymentMethodService: ServicePaymentMethodService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<PaymentDialogComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.paymentMethods$ = this.servicePaymentMethodService.get()
      .pipe(
        map((paymentMethods: Collection<ServicePaymentMethod>) => {
          if (paymentMethods.length) {
            this.selectedPaymentMethod = paymentMethods.findBy('isDefault', true);
          } else {
            this.selectedPaymentMethod = null;
          }

          return paymentMethods;
        })
      );

    // Show preloader until Stripe elements are not ready
    this.isLoading = true;

    this.initStripeElements(this.data.stripeClientSecret);
  }

  submit(): void {
    // reset error text for each submit request
    this.errorMessage = null;
    this.isLoading = true;

    this.getStripeRequest().then((response) => {
      // Stop preloader and show error if payment failed
      this.isLoading = false;

      if (response.error) {
        this.errorMessage = response.error.message;
        return;
      }

      // @TODO Alex: move to separated component (payment status page)
      switch (response.paymentIntent.status) {
        case StripeResponseStatusEnum.SUCCEEDED:
          this.toastService.success(SubscriptionMessagesEnum.SUCCEEDED);
          this.dialogRef.close();
          break;

        case StripeResponseStatusEnum.PROCESSING:
          this.toastService.success(SubscriptionMessagesEnum.PROCESSING);
          this.dialogRef.close();
          break;

        case StripeResponseStatusEnum.REQUIRES_PAYMENT_METHOD:
          this.errorMessage = SubscriptionMessagesEnum.REQUIRES_PAYMENT_METHOD;
          break;

        default:
          this.errorMessage = SubscriptionMessagesEnum.ERROR;
      }
    });
  }

  private getStripeRequest(): Promise<{ paymentIntent?: PaymentIntent, error?: HttpErrorResponse }> {
    // Stripe is using different requests for different operations
    if (this.selectedPaymentMethod) {
      if (this.selectedPaymentMethod.isCard()) {
        // confirm payment with saved card
        return window['stripe'].confirmCardPayment(this.data.stripeClientSecret, {
          payment_method: (this.selectedPaymentMethod as ServicePaymentMethod).stripeId
        });
      }

      return window['stripe'].confirmAuBecsDebitPayment(this.data.stripeClientSecret, {
        payment_method: (this.selectedPaymentMethod as ServicePaymentMethod).stripeId
      });
    }

    // confirm payment with form data
    return window['stripe'].confirmPayment({
      elements: this.stripeElements,
      confirmParams: {
        // redirect URL after payment process finish
        // https://stripe.com/docs/api/payment_intents/create#create_payment_intent-return_url
        return_url: window.location.href,
      },
      redirect: 'if_required'
    });
  }

  /**
   * Init Stripe elements and show form
   * @TODO Alex: copypast, move to separated component or something
   */
  private initStripeElements(clientSecret: string): void {
    // Setup Stripe elements
    // available options: https://stripe.com/docs/elements/appearance-api
    const options: StripeElementsOptions = Object.assign(STRIPE_ELEMENTS_OPTIONS, { clientSecret });

    // Add Stripe payment element to the page
    // https://stripe.com/docs/billing/subscriptions/build-subscriptions?ui=elements#collect-payment
    this.stripeElements = window['stripe'].elements(options);

    const payment = this.stripeElements.create('payment');

    // hide preloader when Stripe payment element is ready
    payment.on('ready', () => {
      this.isLoading = false;
    });

    payment.mount('#payment-element');
  }
}
