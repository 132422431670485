import { Component, Inject } from '@angular/core';
import { BaseComponent } from '@shared/component/base/base.component';
import {  MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { SoleInvoiceTemplate } from 'taxtank-core';

/**
 * Dialog component with Sole Invoice Template add/edit form
 */
@Component({
  selector: 'app-invoice-template-form-dialog',
  templateUrl: './invoice-template-form-dialog.component.html',
  styleUrls: ['./invoice-template-form-dialog.component.scss']
})
export class InvoiceTemplateFormDialogComponent extends BaseComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public invoiceTemplate: SoleInvoiceTemplate,
    public dialogRef: MatDialogRef<InvoiceTemplateFormDialogComponent>
  ) {
    super();
  }
}
