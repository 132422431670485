import { ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AbstractModel, Collection, CollectionDictionary, displayMatOptions } from 'taxtank-core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';

/**
 * Abstract custom autocomplete
 * @TODO vik/alex add findBy input
 */
@Component({
  template: '',
})
export abstract class AbstractAutocompleteComponent<Model extends AbstractModel> extends AbstractControlComponent<Model | string> implements OnInit {
  /**
   * Minimal search query length required for options search
   */
  @Input() minSearchLength = 2;

  /**
   * Model's field name options should be grouped by
   */
  @Input() groupBy: string;

  /**
   * Search query for options filtering
   */
  @Input() searchBy: string | string[] = 'name';
  @Input() searchDelay = 500;
  @Input() searchLimit = 30;


  /**
   * Input placeholder
   */
  @Input() placeholder = 'Start typing to search';

  /**
   * @TODO Alex u don't use it as input
   * List of all autocomplete options
   */
  @Input() options: Collection<Model>;

  /**
   * Flag hide/show manage item option
   */
  @Input() canManage: boolean;

  /**
   * List of options filtered by search query
   */
  filteredOptions: Collection<Model> = new Collection<Model>([]);

  /**
   * List of filtered options grouped by 'groupBy' value
   */
  groupedOptions: CollectionDictionary<Collection<Model>>;

  /**
   * Mat autocomplete function: how to display selected options
   */
  displayFn = displayMatOptions;

  search$: Subject<string> = new Subject<string>();

  /**
   * Flag for multiple mode - indeterminate state of 'select all' checkbox
   */
  isIndeterminate: boolean;

  /**
   * Flag for preloader during options are loading
   */
  isLoading: boolean;

  /**
   * Dialog component class to manage items
   */
  protected dialogComponent: ComponentType<unknown>;

  /**
   * Class for manage item dialog
   */
  protected dialogPanelClass = 'dialog-medium';
  protected dialog: MatDialog = inject(MatDialog);
  protected changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  /**
   * @TODO Alex (TT-1777): refactor when all services refactored with the new rest
   * inject here service and call this.service.get() here instead of abstract method
   */
  abstract getOptions$(): Observable<Model[]>;

  ngOnInit() {
    // Convert single searchBy value to array for filter logic
    if (!Array.isArray(this.searchBy)) {
      this.searchBy = [this.searchBy];
    }

    this.search$
      .pipe(debounceTime(this.searchDelay), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((search: string) => this.search(search));

    this.isLoading = true;

    this.initOptions();
  }

  /**
   * Get list of default options (List displayed with empty or too short search query)
   */
  private get defaultOptions(): Model[] {
    return this.showListOnInit ? this.options.toArray() : [];
  }

  /**
   * Get options and prepare default list to display
   */
  private initOptions(): void {
    this.getOptions$()
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((options: Model[]) => {
        this.options = new Collection<Model>(options);

        const initialModel: Model = this.ngControl.value ? this.options.findBy('id', this.ngControl.value.id) : null;

        this.value = initialModel;

        // Show list with 1 selected item or show initial list (empty or whole, depended on showListOnInit flag)
        this.filteredOptions = new Collection<Model>(this.ngControl.value ? [initialModel] : this.defaultOptions);
        this.groupOptions();
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  /**
   * Handle search input changes. Filter options without form value changes
   */
  onInput(searchQuery: string): void {
    this.search$.next(searchQuery);
  }

  onSelect(option: Model): void {
    this.value = option;
    this.onChange(this.value);
    this.filteredOptions = this.filteredOptions.create([this.value]);
  }

  openManageDialog(data?: unknown): void {
    if (!this.dialogComponent) {
      // Throw error when manage option enabled but dialog not specified
      if (this.canManage) {
        console.error(`${this.constructor.name}: property 'dialogComponent' is not defined`);
      }

      return;
    }

    this.dialog.open(this.dialogComponent, {
      data,
      panelClass: this.dialogPanelClass
    });
  }


  /**
   * Search options by search query
   */
  protected search(searchQuery: string): void {
    if (!this.options) {
      return;
    }

    // Prepare searchQuery for filtering
    searchQuery = searchQuery.toLowerCase().trim();

    if (searchQuery.length < this.minSearchLength) {
      this.filteredOptions = new Collection<Model>(this.defaultOptions);
      return;
    }

    this.filteredOptions = this.options.filter((option: Model) => {
      for (const searchKey of this.searchBy as Array<string>) {
        // ToString for number values
        if (option[searchKey].toString().toLowerCase().includes(searchQuery)) {
          return true;
        }
      }

      return false;
    }).slice(0, this.searchLimit);

    this.groupOptions();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Group dropdown list options
   */
  protected groupOptions(): void {
    // Don't group if grouping path not specified
    if (!this.groupBy) {
      return;
    }

    this.groupedOptions = this.filteredOptions.groupBy(this.groupBy);
  }

  get showListOnInit(): boolean {
    return !this.minSearchLength;
  }
}
