import { AsyncValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import { AddressForm, Property, PropertyCollection } from 'taxtank-core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

/**
 * Validator that checks is property address is already in use or not
 * @TODO Alex (TT-1777): move to core
 *
 * @param properties$
 * @param corelogicRefId current property (should be excluded from validator)
 */
export function propertyAddressAsyncValidator(properties$: Observable<PropertyCollection>, corelogicRefId: number = null): AsyncValidatorFn {
  return (addressForm: AddressForm): Observable<ValidationErrors> => {
    const corelogicRefIdControlValue: number = (addressForm.get('corelogicRefId') as FormControl)?.value;

    return properties$.pipe(
      map((properties: PropertyCollection) => {
        const matchedProperty: Property = properties.find((property: Property): boolean => (+property.address.corelogicRefId !== corelogicRefId) && (+property.address.corelogicRefId === corelogicRefIdControlValue));
        // @TODO TT-1254 return text error message instead of true
        return matchedProperty ? {addressIsUsed: true} : null;
      }),
      first()
    );
  };
}
