import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit {
  @Input() a: number;
  @Input() b: number;
  // Reverse colors
  @Input() isReversed = false;
  rateValue: number;
  rateClass: 'status-badge-approved' | 'status-badge-rejected'

  ngOnInit(): void {
    this.rateValue = this.a ? (this.a - this.b) / this.a * 100 : 0;
    this.rateClass = (this.rateValue > 0 && !this.isReversed) || (this.rateValue < 0 && this.isReversed) ? 'status-badge-approved' : 'status-badge-rejected';
  }
}
