import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroupDirective } from '@angular/forms';
import { ToastService } from 'taxtank-core';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class FileComponent implements OnInit {
  @Input() control: AbstractControl;
  @Output() updated: EventEmitter<File> = new EventEmitter<File>();

  constructor(protected toastService: ToastService) {}

  ngOnInit(): void {
    this.control.valueChanges.subscribe((file: File) => {
      if (!this.control.errors) {
        this.updated.emit(file);
        return;
      }

      Object.keys(this.control.errors).forEach((key: string) => {
        this.toastService.error(this.control.errors[key]);
      });
    });
  }
}
