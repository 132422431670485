import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TermsTypeEnum } from '@components/terms/terms-privacy-dialog/terms-type.enum';

@Component({
  selector: 'app-terms-privacy-dialog',
  templateUrl: './terms-privacy-dialog.component.html',
  styleUrls: ['./terms-privacy-dialog.component.scss']
})

export class TermsPrivacyDialogComponent {
  currentTermsType = TermsTypeEnum.TERMS;
  termsTypes = TermsTypeEnum;
  srcTerms = '/assets/terms-privacy-information/taxtank_saas_agreement.pdf';
  srcPrivacy = '/assets/terms-privacy-information/taxtank_privacy_policy.pdf';

  constructor(public dialogRef: MatDialogRef<TermsPrivacyDialogComponent>) {}

  selectType(type: TermsTypeEnum): void {
    this.currentTermsType = type;
  }

  download(): void {
    switch (this.currentTermsType) {
      case TermsTypeEnum.TERMS:
        window.open(this.srcTerms);
        break;
      case TermsTypeEnum.PRIVACY:
        window.open(this.srcPrivacy);
        break;
    }
  }
}
