<ng-container [appPreloader]="[endpoints.PROPERTIES_PUT]"
              [preloaderType]="preloaderTypes.OVERLAY">
</ng-container>

<div mat-dialog-title>
  <div class="dialog-caption">Forecasted Property Growth</div>
  <div class="dialog-subtitle">Select your growth percentage for forecasts.</div>
  <button mat-dialog-close class="btn icon-close"></button>
</div>

<div mat-dialog-content>
  <div class="wrapper">
    <h4>Select Range</h4>

    <ng-container *ngIf="data.properties">
      <div *ngFor="let property of data.properties" class="property-wrapper">
        <div class="address">{{ property.address.name }}</div>

        <!-- If property has no growth-percentage value -->
        <ng-container *ngIf="!property.growthPercent">
          <div class="c-danger fs-default text-center mt-10 fw-600">We didn't find growth percentage value for current property in Corelogic. Please, select it manually</div>
          <div class="slider-container">
            <mat-slider
              [min]="defaultMinPercent"
              [max]="defaultMaxPercent"
              [discrete]="true"
              [step]="percentageStep">
              <input matSliderThumb [value]="defaultMinPercent" (valueChange)="changePercent($event, property)"/>
            </mat-slider>

            <div class="slider-labels">
              <span class="label">{{ defaultMinPercent | number: '1.1-2' }}%</span>
              <span class="label">{{ defaultMaxPercent | number: '1.1-2' }}%</span>
            </div>
          </div>
        </ng-container>

        <!-- If property has growth-percentage value -->
        <div *ngIf="property.growthPercent" class="slider-container">
          <mat-slider
            [min]="property.growthPercent - percentageRange"
            [max]="property.growthPercent + percentageRange"
            [discrete]="true"
            [step]="percentageStep">
            <input matSliderThumb [value]="property.growthPercent" (valueChange)="changePercent($event, property)"/>
          </mat-slider>

          <div class="slider-labels">
            <span class="label">{{ (property.growthPercent ? property.growthPercent - percentageRange : defaultMinPercent) | number: '1.1-2' }}%</span>
            <span class="label active">{{ (property.growthPercent ? property.growthPercent : null) | number: '1.1-2' }}%</span>
            <span class="label">{{ (property.growthPercent ? property.growthPercent + percentageRange : defaultMaxPercent) | number: '1.1-2' }}%</span>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="notice">
      <div class="corelogic-description">
        <span class="text-primary">Taxtank</span> has partnered with <span class="text-error">Corelogic</span> to
        provide greater insight into potential future property performance. We use Corelogic data to show you the suburb
        growth percentage of your property which is based upon the 10-year suburb averages for houses and units. <span
        *ngIf="!isFullNotice" class="show-more" (click)="showMore()">show more</span>
        <div class="more-notice" *ngIf="isFullNotice">However, the average growth percentage does not take into
          consideration any market conditions or variables between properties so Taxtank provides a range ± 3% of the
          Corelogic growth percentage so you have the flexibility to select if your property sits above or below the
          average.
          After all, no-one knows your property better than you!
          <div *ngIf="isFullNotice" class="show-more" (click)="showLess()">show less</div>
        </div>
      </div>

      <div (click)="openLearnAboutCorelogicDialog()" class="learn-about-link">Learn about corelogic</div>
    </div>
  </div>

</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-sm-auto">
      <button mat-dialog-close class="btn basic w-100">Cancel</button>
    </div>
    <div class="col-6 col-sm-auto">
      <button type="submit" class="btn primary w-100" (click)="applyPercentage()">Apply</button>
    </div>
  </div>
</div>
