<p-fileUpload *ngIf="mode === 'button'"
              [accept]="accept"
              [disabled]="disabled"
              [maxFileSize]="maxFileSize"
              [auto]="true"
              [multiple]="false"
              [chooseLabel]="label"
              class="p-fileupload-button"
              chooseIcon="icon-attachment">
</p-fileUpload>

<p-fileUpload *ngIf="mode === 'icon'"
              [accept]="accept"
              [disabled]="disabled"
              [maxFileSize]="maxFileSize"
              [auto]="true"
              [multiple]="false"
              class="p-fileupload-icon"
              [chooseIcon]="chooseIcon">
</p-fileUpload>

<ng-container *ngIf="mode === 'dragdrop'">
  <div *ngIf="caption" class="c-secondary mb-10">{{caption}}</div>

  <p-fileUpload [accept]="accept"
                [disabled]="disabled"
                [maxFileSize]="maxFileSize"
                [auto]="true"
                [multiple]="false"
                [class.uploading]="value"
                [class.dragging]="isDragging"
                class="p-fileupload-dragdrop">

    <ng-template pTemplate="toolbar">
      <div class="drop-area"
           (click)="pFileUpload.choose()"
           (dragover)="$event.preventDefault()"
           (dragenter)="ondragenter($event)"
           (dragleave)="ondragleave($event)"
           (drop)="ondrop($event)">
      </div>

      <div class="buttonbar-content">
        <i *ngIf="!value" class="upload-icon icon-upload-cloud"></i>
        <div *ngIf="value">
          <button appStopPropagation class="btn icon-close ic-text delete-file-button" (click)="clear()"></button>
        </div>
        <div class="uploader-content-wrapper">
          <div>
            <div *ngIf="!value" class="choose-label">{{label}}</div>
            <div *ngIf="value" class="choose-label">{{value.originalName}}</div>

            <div *ngIf="!value" class="choose-description">[Choose a file or drag it here]</div>
            <div *ngIf="value" class="choose-description">{{value.size | fileSizeConvert}}</div>

            <div *ngIf="!value" class="choose-allowed-types">Allowed types: {{acceptLabel}}</div>
            <div *ngIf="value && pFileUpload?.msgs?.length">
              <div *ngFor="let msg of pFileUpload.msgs" class="error-message">
                {{msg.summary}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-fileUpload>
</ng-container>
