import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SoleContact, SoleContactService } from 'taxtank-core';
import { MatDialog } from '@angular/material/dialog';
import { NgControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SoleContactFormDialogComponent } from '@client/sole/components/sole-contact-form-dialog/sole-contact-form-dialog.component';
import { AbstractAutocompleteComponent } from '../abstract-autocomplete/abstract-autocomplete.component';

/**
 * Sole contact autocomplete
 */
@Component({
  selector: 'app-sole-contact-autocomplete',
  templateUrl: './sole-contact-autocomplete.component.html',
  styleUrls: ['./sole-contact-autocomplete.component.scss']
})
export class SoleContactAutocompleteComponent extends AbstractAutocompleteComponent<SoleContact> implements OnInit {
  @Input() label = 'Payer';
  @Input() placeholder = 'Select payer';
  @Input() minSearchLength = 0;
  @Input() canManage = true;
  @Input() searchBy = 'name';
  dialogComponent = SoleContactFormDialogComponent;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    protected dialog: MatDialog,
    private soleContactService: SoleContactService
  ) {
    super(ngControl, changeDetectorRef);
  }

  getOptions$(): Observable<SoleContact[]> {
    return this.soleContactService.get();
  }
}
