<div [class.smaller-text]="isSmallText"
     [class.primary]="isPrimary"
     [class.inline] = "isInline"
     class="inner-card">
  <h6 class="inner-card-title">{{title}}</h6>

  <div class="percent-with-arrow" *ngIf="growthValue || growthValue === 0">
    <h1 class="inner-card-value {{color}}">{{ growthValue || growthValue === 0 ? growthValue + '%' : 'N/A' }}</h1>
    <i *ngIf="growthValue || growthValue !== 0"
       class="icon icon-arrow-{{ growthValue > 0 ? 'up rate-up' : 'down rate-down'}}"
       [class.reversed]="isArrowColorReversed">
    </i>
  </div>

  <h1 class="inner-card-value {{color}}" *ngIf="!growthValue">{{ isPrice ? (value | customCurrency: true) : value }}</h1>

  <div class="inner-card-ng-content">
    <ng-content></ng-content>
  </div>
</div>
