<form [formGroup]="form" [class.transparent]="isTransparent" appFormErrorScroll>
  <mat-form-field>
    <mat-label>Ownership %</mat-label>
    <input id="onBoardingPropertyOwnershipAdd"
           type="number"
           matInput
           formControlName="percent"
           placeholder="Enter Ownership Percentage"
           required>
    <mat-error *ngIf="form.get('percent').invalid">
      <app-validation-error [errors]="form.get('percent').errors"></app-validation-error>
    </mat-error>
  </mat-form-field>
</form>
