export enum DashStyleEnum {
 DASH = 'Dash',
 DASH_DOT = 'DashDot',
 DOT = 'Dot',
 LONG_DASH = 'LongDash',
 LONG_DASH_DOT = 'LongDashDot',
 LONG_DASH_DOT_DOT = 'LongDashDotDot',
 SHORT_DASH = 'ShortDash',
 SHORT_DASH_DOT = 'ShortDashDot',
 SHORT_DASH_DOT_DOT = 'ShortDashDotDot',
 SHORT_DOT = 'ShortDot',
 SOLID = 'Solid'
}
