import { Component } from '@angular/core';
import { SubscriptionBannerOnboardingComponent } from '@components/subscription-banner/subscription-banner-onboarding/subscription-banner-onboarding.component';

@Component({
  selector: 'app-subscription-banner-onboarding-user',
  templateUrl: './subscription-banner-onboarding-user.component.html',
  styleUrls: ['./subscription-banner-onboarding-user.component.scss']
})
export class SubscriptionBannerOnboardingUserComponent extends SubscriptionBannerOnboardingComponent {
}
