import { Component } from '@angular/core';
import { SubscriptionBannerOnboardingComponent } from '@components/subscription-banner/subscription-banner-onboarding/subscription-banner-onboarding.component';

/**
 * @TODO Alex it has nothing to do with subscription, employees don't care about them, rename
 */
@Component({
  selector: 'app-subscription-banner-onboarding-employee',
  templateUrl: './subscription-banner-onboarding-employee.component.html',
  styleUrls: ['./subscription-banner-onboarding-employee.component.scss']
})
export class SubscriptionBannerOnboardingEmployeeComponent extends SubscriptionBannerOnboardingComponent {
}
