import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, forkJoin } from 'rxjs';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Submittable } from '@shared/interfaces/submittable.interface';
import { BaseComponent } from '@shared/component/base/base.component';
import { plainToClass } from 'class-transformer';
import {
  Collection,
  IncomeSource,
  IncomeSourceForecast,
  IncomeSourceForecastService,
  IncomeSourceService,
  IncomeSourceType,
  IncomeSourceTypeEnum, IncomeSourceTypeService,
  SalaryForecast,
  SalaryForecastService,
  SoleForecast,
  SoleForecastService, ToastService,
  User,
} from 'taxtank-core';
import { HasUrlFragment } from '@shared/interfaces/has-url-fragment.interface';

/**
 * Base income-source component from which inherited Salary & Other Income components
 */
@Component({
  selector: 'app-income-source',
  templateUrl: './income-source.component.html',
  styleUrls: ['./income-source.component.scss']
})
export abstract class IncomeSourceComponent extends BaseComponent implements OnInit, Submittable, HasUrlFragment {
  @Input() incomeSource: IncomeSource;
  // should we get all income sources
  @Input() isWithFullList = false;
  incomeTypes: Collection<IncomeSourceType>;
  incomeSources: IncomeSource[];
  @Output() submitted: EventEmitter<IncomeSource[][]> = new EventEmitter<IncomeSource[][]>();
  @Output() previousStep: EventEmitter<any> = new EventEmitter<any>();
  form: UntypedFormArray;
  user: User;
  // current visible form
  displayedFormIndex = 0;
  // array with not assigned Income sources for the form controls
  notAssignedFormControls: AbstractControl[];
  types: IncomeSourceTypeEnum[];
  isAustralianResident: boolean;
  currentUrl = '/client/onboarding';
  urlFragment: string;

  constructor(
    protected fb: UntypedFormBuilder,
    protected router: Router,
    protected incomeSourceService: IncomeSourceService,
    protected incomeSourceTypeService: IncomeSourceTypeService,
    protected salaryForecastService: SalaryForecastService,
    protected soleForecastService: SoleForecastService,
    protected incomeSourceForecastService: IncomeSourceForecastService,
    protected toastService: ToastService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.incomeSources = [];
    this.notAssignedFormControls = [];
    this.incomeTypes = this.incomeSourceTypeService.getCache().filter(
      (type) => this.incomeSource.isHolding() ? type.isHolding() : type.isWork() || type.isOther()
    );
    this.user = this.userService.getCacheFirst();

    if (this.isWithFullList) {
      this.getIncomeSourceData();
    } else {
      this.incomeSources.push(this.incomeSource);
      this.buildForm();
    }
  }

  /**
   * Get data for income sources
   */
  getIncomeSourceData(): void {
    combineLatest([
      this.incomeSourceService.getByTypes(this.types),
      this.salaryForecastService.get(),
      this.soleForecastService.get(),
      this.incomeSourceForecastService.get(),
    ])
      .subscribe(([incomeSources, salaryForecasts, soleForecasts, incomeSourceForecasts]:
                    [IncomeSource[], SalaryForecast[], SoleForecast[], IncomeSourceForecast[]]) => {
        incomeSources.forEach((incomeSource: IncomeSource) => {
          incomeSource.salaryForecasts = salaryForecasts
            .filter((salaryForecast: SalaryForecast) => incomeSource.id === salaryForecast.incomeSource.id);
          incomeSource.soleForecasts = soleForecasts
            .filter((soleForecast: SoleForecast) => incomeSource.id === soleForecast.incomeSource.id);
          incomeSource.incomeSourceForecasts = incomeSourceForecasts
            .filter((incomeSourceForecast: IncomeSourceForecast) => incomeSource.id === incomeSourceForecast.incomeSource.id);
        });

        this.incomeSources = incomeSources.filter((incomeSource: IncomeSource) => incomeSource.forecasts.length);
        this.buildForm();
      });
  }

  /**
   * Create salary form array
   */
  buildForm(): void {
    this.form = this.fb.array([]);

    this.incomeSources.forEach((incomeSource: IncomeSource) => {
      this.addFormGroup(incomeSource);
    });

    this.notAssignedFormControls.forEach((formGroup: UntypedFormGroup) => this.form.push(formGroup));
    this.notAssignedFormControls = [];
  }

  abstract addFormGroup(incomeSource: IncomeSource): void

  /** Remove current salary form by index
   * @param index
   */
  removeForm(index: number): void {
    // Switch active form by index
    switch (true) {
      // If removing form is the 1st one, than select next form
      case index === 0:
        this.selectForm(this.displayedFormIndex === 0 ? 0 : this.displayedFormIndex - 1);
        break;
      // If removing form is the current one, than select previous form
      case this.displayedFormIndex === index:
        this.selectForm(index - 1);
        break;
      // If removing index is the one before current, than select 1st form
      case index === (this.displayedFormIndex - 1):
      case this.displayedFormIndex === 0:
        this.selectForm(0);
        break;
      default:
        this.selectForm(this.displayedFormIndex - 1);
    }

    this.form.removeAt(index);
  }

  /**
   * Select salary form
   * @param index
   */
  selectForm(index: number): void {
    this.displayedFormIndex = index;
  }

  /**
   * Submit form and pass values to backend
   */
  submit(): void {
    this.form.markAllAsTouched();

    // check if form is valid
    if (!this.form.valid) {
      // @TODO Alex: show message via toast service, remove string (waiting for services refactoring)
      this.toastService.error('You have invalid fields for added income source employers. Please check fields before adding!');
      return;
    }

    this.sendData();
  }

  /**
   * Send income sources data
   */
  sendData(): void {
    const incomeSources: IncomeSource[] = this.form.getRawValue().map((groupValue: any) => plainToClass(IncomeSource, groupValue));

    // Send incomes to add and update on the backend
    forkJoin([
      this.incomeSourceService.addBatch(incomeSources.filter((incomeSource: IncomeSource) => !incomeSource.id)),
      this.incomeSourceService.updateBatch(incomeSources.filter((incomeSource: IncomeSource) => incomeSource.id)),
    ]).subscribe(() => {
      // @TODO Alex: show message via toast service, remove string (waiting for services refactoring)
      this.toastService.success('Income source data saved');
      this.submitted.emit();
    });
  }

  /**
   * add hash url fragment
   */
  setFragment(): void {
    this.router.navigate([this.currentUrl], {fragment: this.urlFragment});
  }
}
