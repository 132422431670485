import { Directive, HostListener, Input } from '@angular/core';
import { MixpanelService } from 'taxtank-core/common';

@Directive({
  selector: '[appTrack]'
})
export class TrackDirective {
  @Input('appTrack') event: string;
  constructor(private mpService: MixpanelService) {}

  @HostListener('click')
  onClick(): void {
    this.mpService.track(this.event);
  }
}
