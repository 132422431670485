import { ColorTypeEnum } from '@shared/enums/color-type.enum';

/**
 * tax summary tank metrics (expense/income/etc)
 */
export class Metric {
  label: string;
  value: number;
  total: number;
  color: ColorTypeEnum;

  get percent(): number {
    return this.value ? this.value / this.total * 100 : 0;
  }
}
