<div mat-dialog-content class="bank-connections-dialog-content">

<div class="bank-account-manual-form-container">
  <div class="align-v-center mb-10">
    <i class="icon-info align-v-center mr-10"></i>
    Live feeds are not currently available for this bank, Bank transactions must be imported manually via csv.
  </div>

  <form [formGroup]="form" (ngSubmit)="submit()" appFormErrorScroll>
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field>
          <mat-label>Account name</mat-label>
          <input matInput formControlName="accountName" placeholder="Account Name">
          <mat-error *ngIf="form.get('accountName').invalid">
            <app-validation-error [errors]="form.get('accountName').errors"></app-validation-error>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-6">
        <mat-form-field>
          <mat-label>BSB & account number</mat-label>
          <input matInput formControlName="accountNumber" placeholder="123456 12345678">
          <mat-error *ngIf="form.get('accountNumber').invalid">
            <app-validation-error [errors]="form.get('accountNumber').errors"></app-validation-error>
            <!-- @TODO Front who implemment common error sulotion: remove when validation error directive created (custom error text) -->
            <ng-container *ngIf="form.get('accountNumber').hasError('pattern')">
              BSB & Account Number format is invalid. Required format: 123456 12345678
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-6">
        <app-currency-input formControlName="currentBalance"
                            label="Balance amount"
                            placeholder="Balance amount">
        </app-currency-input>
      </div>

      <div class="col-12 col-md-6">
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select formControlName="type" placeholder="Select type">
            <mat-option *ngFor="let type of (bankAccountTypes | ekeyvalue)" [value]="+type.key">
              {{ type.value | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('type').invalid">
            <app-validation-error [errors]="form.get('type').errors"></app-validation-error>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-6">
        <app-tank-type-select [exclude]="[tankTypes.HOLDING]" formControlName="tankType"></app-tank-type-select>
      </div>
    </div>

    <div class="additional-data-forms">
      <ng-container *ngIf="form.contains('bankAccountProperties')">
        <h5 class="additional-data-title">Properties information</h5>
        <app-bank-account-properties-form [form]="form.get('bankAccountProperties')"></app-bank-account-properties-form>
      </ng-container>

      <ng-container *ngIf="form.contains('businessAllocations')">
        <h5 class="additional-data-title">Businesses information</h5>
        <app-bank-account-business-allocations-form [form]="form.get('businessAllocations')"></app-bank-account-business-allocations-form>
      </ng-container>

      <ng-container *ngIf="form.contains('loan')">
        <h5 class="additional-data-title">Loan information</h5>
        <app-bank-account-loan-form [form]="form.get('loan')"></app-bank-account-loan-form>
      </ng-container>
    </div>
  </form>
</div>
</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-sm-auto">
      <button (click)="back.emit()" class="btn basic back w-100" type="submit">Back</button>
    </div>
    <div class="col-6 col-sm-auto">
      <button class="btn primary w-100" (click)="formRef.ngSubmit.emit()">Submit</button>
    </div>
  </div>
</div>
