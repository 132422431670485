import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDragNDrop]'
})
export class DragNDropDirective {

  @Output() onFileDropped: EventEmitter<File> = new EventEmitter<File>();

  @HostBinding('style.border')
  private border = '2px dashed #d8d8d8';

  @HostBinding('style.outline')
  private outline = 'none';

  // Dragover listener
  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    event.target['class'] = 'dnd-area dragover';
    this.border = '2px solid #00b7f0';
    this.outline = '2px dashed #00b7f0';
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.border = '2px dashed #d8d8d8';
    this.outline = 'none';
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.border = '2px solid #d8d8d8';
    this.outline = 'none';
    this.onFileDropped.emit(event.dataTransfer.files);
  }
}
