<div mat-dialog-title>
  <div class="dialog-caption">{{ data.caption }}</div>
  <button mat-dialog-close class="btn icon-close"></button>
</div>

<div mat-dialog-content>
  <div class="dialog-subtitle" [innerHTML]="data.message"></div>
</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-sm-auto">
      <button mat-dialog-close class="btn basic w-100">No</button>
    </div>
    <div class="col-6 col-sm-auto">
      <button class="btn primary w-100" (click)="confirm()">Yes</button>
    </div>
  </div>
</div>
