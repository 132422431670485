<!-- @Todo TT-2340 Mikhail Create common component with markup and use in each banner via slots -->
<header mat-dialog-title>
  <div>
    <h1 class="c-white mb-15">Property made simple</h1>
    <span class="fs-big">
      Powerful tax and equity tools for property investors. Monitor your whole property in one place -
      including investment properties, share properties, you home and properties held in trusts.
    </span>
  </div>
  <button mat-dialog-close class="btn icon-close"></button>
</header>

<div mat-dialog-content>
  <div class="d-flex align-v-center">
    <div class="banner-image"></div>
    <div class="content-wrapper">
      <div class="mb-10">
        <i class="icon mr-10 icon-check ic-primary"></i>
        <span class="text-center fw-600 c-secondary">Smart Forecasting</span>
      </div>
      <div class="mb-10">
        <i class="icon mr-10 icon-check ic-primary"></i>
        <span class="text-center fw-600 c-secondary">Automated Deprecation</span>
      </div>
      <div class="mb-10">
        <i class="icon mr-10 icon-check ic-primary"></i>
        <span class="text-center fw-600 c-secondary">Real-time Reporting</span>
      </div>
      <button class="btn secondary w-100">Start free trial</button>
    </div>
  </div>
</div>
