import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SoleBusiness, SoleBusinessService } from 'taxtank-core';
import { MatDialog } from '@angular/material/dialog';
import { SoleBusinessFormDialogComponent } from '@client/sole/components/sole-business-form-dialog/sole-business-form-dialog.component';
import { AbstractSelectComponent } from '@shared/component/form-controls/select/abstract-select/abstract-select.component';
import { Observable } from 'rxjs';

/**
 * Component with dropdown for sole business select
 */
@Component({
  selector: 'app-business-select',
  templateUrl: './business-select.component.html',
  styleUrls: ['./business-select.component.scss'],
})
export class BusinessSelectComponent extends AbstractSelectComponent<SoleBusiness> {
  @Input() emptyMessage = 'You haven\'t added any sole businesses yet.';
  @Input() emptyOptionLabel = 'All sole businesses';
  @Input() placeholder = 'Sole business';
  @Input() label = 'Sole business';
  dialogComponent = SoleBusinessFormDialogComponent;

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    protected dialog: MatDialog,
    private soleBusinessService: SoleBusinessService
  ) {
    super(ngControl, changeDetectorRef);
  }

  getOptions$(): Observable<SoleBusiness[]> {
    return this.soleBusinessService.getArray();
  }

  compareOptions(optionValue: SoleBusiness | false, value: SoleBusiness | false): boolean {
    if (!optionValue && !value) {
      return true;
    }

    return (optionValue as SoleBusiness).id === (value as SoleBusiness).id;
  }
}
