<header mat-dialog-title>
  <div class="dialog-caption">Terms & Privacy information</div>
  <button mat-dialog-close class="btn icon-close"></button>
</header>

<div mat-dialog-content>
  <mat-tab-group class="primary" (selectedTabChange)="selectType($event.tab.textLabel)">
    <mat-tab [label]="termsTypes.TERMS">
      <pdf-viewer [src]="srcTerms" [original-size]="false"></pdf-viewer>
    </mat-tab>

    <mat-tab [label]="termsTypes.PRIVACY">
      <pdf-viewer [src]="srcPrivacy" [original-size]="false"></pdf-viewer>
    </mat-tab>
  </mat-tab-group>
</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-sm-auto">
      <button class="btn link icon additional-action" (click)="download()">
        <i class="icon icon-download"></i>
        Download
      </button>
    </div>
    <div class="col-6 col-sm-auto">
      <button mat-dialog-close class="btn basic w-100">Close</button>
    </div>
  </div>
</div>
