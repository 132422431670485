import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {
  SubscriptionChangeDialogComponent
} from '@client/shared/components/subscription/subscription-change-dialog/subscription-change-dialog.component';
import { BaseComponent } from '@shared/component/base/base.component';
import {ServiceProductCollection} from 'taxtank-core';

@Component({
  selector: 'app-subscription-banner-trial',
  templateUrl: './subscription-banner-trial.component.html',
  styleUrls: ['./subscription-banner-trial.component.scss']
})
export class SubscriptionBannerTrialComponent extends BaseComponent {
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public products: ServiceProductCollection) {
    super();
  }

  openSubscriptionDialog(): void {
    this.dialog.open(SubscriptionChangeDialogComponent, {
      panelClass: 'dialog-large'
    });
  }
}
