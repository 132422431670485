import { Pipe, PipeTransform } from '@angular/core';

/**
 * enum values (fix duplicated enum keys problem)
 */
@Pipe({
  name: 'eValues'
})
export class EValuesPipe implements PipeTransform {
  transform(object: object): number[] | string[] {
    return Object.values(object).splice(Object.values(object).length / 2);
  }
}
