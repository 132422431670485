<ng-container [formGroup]="form">
  <mat-form-field [class.compact]="isCompact">
    <mat-label>Percentage of claim</mat-label>
    <i #menuTrigger="matMenuTrigger"
       matSuffix class="icon icon-calculator"
       [matMenuTriggerFor]="menu">
    </i>
    <input matInput
           type="number"
           formControlName="claimPercent"
           placeholder="Enter Claim Percentage"
           required>
    <mat-error *ngIf="form.get('claimPercent').invalid">
      <app-validation-error [errors]="form.get('claimPercent').errors"></app-validation-error>
    </mat-error>

    <mat-menu #menu="matMenu">
      <app-calculation-form
        (keydown.tab)="$event.stopPropagation()"
        title="Claim calculator"
        [menuTrigger]="menuTrigger"
        [formItems]="capitalCostsFormItems"
        [submit]="setClaimPercentageValue"
        [control]="form.get('claimPercent')"
        (controlChanged)="toggleFields($event)">
      </app-calculation-form>
    </mat-menu>
  </mat-form-field>
</ng-container>
