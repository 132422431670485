import { Component, Input } from '@angular/core';

/**
 * UI component: Card component usually displayed on dashboards
 */
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() expandable = true;
  @Input() isCollapsed: boolean;
  @Input() longTitle: boolean;
  // disabled style: gray background
  @Input() disabled: boolean;
  // @TODO implement title, subtitle and other simple elements via @Input(), but also via ng-template (mat-tab-label as example)

  /**
   * Toggle collapse state
   */
  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
}
