<div mat-dialog-title>
  <div class="dialog-caption">CoreLogic Disclaimer</div>
  <button mat-dialog-close class="btn icon-close"></button>
</div>

<div mat-dialog-content>
  <div class="text-container">
    <div class="corelogic-disclaimer">AVM Disclaimer</div>

    <div class="corelogic-information">
      An automated valuation model estimate (AVM Estimate) is a statistically derived estimate of the value of the subject property. An AVM Estimate is generated (i) by a computer driven mathematical model in reliance on available data; (ii) without the physical inspection of the subject property; (iii) without taking into account any market conditions (including building, planning, or economic), and/or (iv) without identifying observable features or risks (including adverse environmental issues, state of repair, improvements, renovations, aesthetics, views or aspect) which may, together or separately, affect the value.
    </div>
    <div class="corelogic-information">
      An AVM Estimate is current only at the date of publication or supply. An AVM Estimate must not be relied upon as a professional valuation or an accurate representation of the market value of the subject property as determined by a valuer. CoreLogic expressly excludes any warranties and representations that an AVM Estimate is an accurate representation as to the market value of the subject property.
    </div>
    <div class="corelogic-information">
      To the full extent permitted by law, CoreLogic excludes all liability for any loss or damage howsoever arising or suffered by the recipient, whether as a result of the recipient's reliance on the accuracy of an AVM Estimate or otherwise arising in connection with an AVM Estimate.
    </div>

    <div class="corelogic-disclaimer">Copyright Notice</div>

    <div class="corelogic-information">
      This publication reproduces materials and content owned or licenced by RP Data Pty Ltd trading as CoreLogic Asia Pacific (CoreLogic) and may include data, statistics, estimates, indices, photographs, maps, tools, calculators (including their outputs), commentary, reports and other information (CoreLogic Data).
    </div>
    <div class="corelogic-information">
      © Copyright 2018. CoreLogic and its licensors are the sole and exclusive owners of all rights, title and interest (including intellectual property rights) the CoreLogic Data contained in this publication. All rights reserved.
    </div>

    <div class="corelogic-disclaimer">State Disclaimers</div>

    <div class="corelogic-information">
      QLD - Based on or contains data provided by the State of Queensland (Department of Natural Resources and Mines) 2018. In consideration of the State permitting use of this data you acknowledge and agree that the State gives no warranty in relation to the data (including accuracy, reliability, completeness, currency or suitability) and accepts no liability (including without limitation, liability in negligence) for any loss, damage or costs (including consequential damage) relating to any use of the data. Data must not be used for direct marketing or be used in breach of the privacy laws.
    </div>
    <div class="corelogic-information">
      SA - © 2018 Copyright in this information belongs to the South Australian Government and the South Australian Government does not accept any responsibility for the accuracy or completeness of the information or its suitability for purpose.
    </div>
    <div class="corelogic-information">
      NSW - Contains property sales information provided under licence from the Land and Property Information (“LPI”). RP Data Pty Ltd trading as CoreLogic is authorised as a Property Sales Information provider by the LPI.
    </div>
    <div class="corelogic-information">
      VIC - The State of Victoria owns the copyright in the Property Sales Data and reproduction of that data in any way without the consent of the State of Victoria will constitute a breach of the Copyright Act 1968 (Cth). The State of Victoria does not warrant the accuracy or completeness of the Property Sales Data and any person using or relying upon such information does so on the basis that the State of Victoria accepts no responsibility or liability whatsoever for any errors, faults, defects or omissions in the information supplied.
    </div>
    <div class="corelogic-information">
      WA - Based on information provided by and with the permission of the Western Australian Land Information Authority (2018) trading as Landgate.
    </div>
    <div class="corelogic-information">
      ACT - The Territory Data is the property of the Australian Capital Territory. No part of it may in any form or by any means (electronic, mechanical, microcopying, photocopying, recording or otherwise) be reproduced, stored in a retrieval system or transmitted without prior written permission. Enquiries should be directed to: Director, Customer Services ACT Planning and Land Authority GPO Box 1908 Canberra ACT 2601.
    </div>
    <div class="corelogic-information">
      TAS - This product incorporates data that is copyright owned by the Crown in Right of Tasmania. The data has been used in the product with the permission of the Crown in Right of Tasmania. The Crown in Right of Tasmania and its employees and agents:<br>
      1.	(a) give no warranty regarding the data's accuracy, completeness, currency or suitability for any particular purpose; and<br>
      2.	(b) do not accept liability howsoever arising, including but not limited to negligence for any loss resulting from the use of or reliance upon the data.<br>
      Base data from the LIST © State of Tasmania <a target="_blank" href="http://www.thelist.tas.gov.au">http://www.thelist.tas.gov.au</a>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <div class="row align-h-right">
    <div class="col-12 col-sm-auto">
      <button mat-dialog-close class="btn basic">Close</button>
    </div>
  </div>
</div>
