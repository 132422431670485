import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Directive that show notification badge
 */
@Directive({
  selector: '[appNotifications]'
})
export class NotificationsDirective implements OnInit {
  @Input() appNotifications: Observable<number>;

  /**
   * maximum number of notifications displayed in the indicator
   */
  static maxAmount = 99;

  constructor(
    private el: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.el.nativeElement.style.position = 'relative';
    const badgeElement: HTMLElement = document.createElement('div');
    badgeElement.classList.add('notification-indicator');
    badgeElement.classList.add('with-value');
    badgeElement.classList.add('error');
    badgeElement.style.display = 'none';
    this.el.nativeElement.appendChild(badgeElement);

    this.appNotifications?.subscribe((value: number): void => {
      badgeElement.innerText = value < NotificationsDirective.maxAmount ? value?.toString() : `${NotificationsDirective.maxAmount}+`;
      badgeElement.style.display = value ? 'flex' : 'none';
    });
  }
}
