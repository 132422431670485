<div id="stripe-promo-code">
  <div *ngIf="!subscription.promoCode" class="d-flex align-v-center">
    <mat-form-field class="compact no-error w-auto mat-form-field-invalid" [class.mat-form-field-invalid]="!!errorMessage">
      <mat-label></mat-label>
      <input #promocodeInput matInput placeholder="Add Promo Code" [disabled]="isLoading">
    </mat-form-field>
    <div class="ml-5">
      <button *ngIf="promocodeInput.value && !isLoading" class="btn link" (click)="apply(promocodeInput.value)">Apply</button>
      <app-preloader *ngIf="isLoading" [type]="preloaderTypes.ICON"></app-preloader>
    </div>
  </div>


  <div *ngIf="subscription.promoCode" class="btn d-flex align-v-center">
    <app-accept-promocode [subscription]="subscription"></app-accept-promocode>
    <button class="btn icon-close ic-text" (click)="remove()"></button>
  </div>
</div>


