import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * Property add form dialog. Combine Property, Address, PropertyForecast and PropertyShare form.
 * Save array of properties and forecasts, valuations, owners for each.
 */
@Component({
  selector: 'app-properties-add-form-dialog',
  templateUrl: './properties-add-form-dialog.component.html',
  styleUrls: ['./properties-add-form-dialog.component.scss']
})
export class PropertiesAddFormDialogComponent extends BaseComponent {
  constructor(
    public dialogRef: MatDialogRef<PropertiesAddFormDialogComponent>,
  ) {
    super();
  }
}
