import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {UserService} from 'taxtank-core';
import { UserRolesEnum } from 'taxtank-core/common';

/**
 * Shows content if current user's role matches the provided role
 * the implementation is very similar to *ngIf directive
 */
@Directive({
  selector: '[appRoles]'
})
export class RolesDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService
  ) {
  }

  @Input() set appRoles(roles: UserRolesEnum[] | UserRolesEnum) {
    if (this.userService.getCacheFirst().hasRoles(roles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
