import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Occupation, OccupationService } from 'taxtank-core';
import { NgControl } from '@angular/forms';
import { AbstractAutocompleteComponent } from '@shared/component/form-controls/autocomplete/abstract-autocomplete/abstract-autocomplete.component';
import { Observable } from 'rxjs';

/**
 * Occupation autocomplete
 */
@Component({
  selector: 'app-occupation-autocomplete',
  templateUrl: './occupation-autocomplete.component.html',
  styleUrls: ['./occupation-autocomplete.component.scss'],
})
export class OccupationAutocompleteComponent extends AbstractAutocompleteComponent<Occupation> {
  @Input() label = 'Occupation';

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private occupationService: OccupationService
  ) {
    super(ngControl, changeDetectorRef);
  }

  getOptions$(): Observable<Occupation[]> {
    return this.occupationService.getOccupations();
  }
}
