import { Component } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
  collapsed = true;
  loaded = false;

  toggle(): void {
    this.collapsed = !this.collapsed;
    this.loaded = true;
  }
}
