<div class="bg-light p-20">

  <div class="align-h-space-between">
    <div class="fw-bold">0%</div>
    <div class="fw-bold">{{workUsageLimit | number:'1.0-2'}}%</div>
  </div>

  <mat-slider [max]="workUsageLimit"
              [discrete]="true"
              [step]="disabled ? 0.01 : 1"
              [displayWith]="formatPercentageLabel"
              [disabled]="disabled">
    <input matSliderThumb [(ngModel)]="value" (valueChange)="onSlide($event)"/>
  </mat-slider>
</div>
