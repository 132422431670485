<mat-form-field [class]="inputClass">

  <mat-label>{{label}}</mat-label>

  <input #searchInput
         matInput
         [errorStateMatcher]="errorStateMatcher"
         [matAutocomplete]="auto"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         type="text"
         (input)="onInput($event.target.value)"
         (blur)="onTouched()">

  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="onSelect($event.option.value)"
                    [displayWith]="displayFn">

    <ng-container *ngIf="filteredOptions.length">
      <mat-option *ngFor="let activity of filteredOptions" [value]="activity">
        {{ activity.name }}
      </mat-option>
    </ng-container>

    <mat-option *ngIf="searchInput.value.length >= minSearchLength && !filteredOptions.length" [disabled]="true">
      No results found
    </mat-option>
  </mat-autocomplete>

  <div matSuffix>
    <ng-container [appPreloader]="[endpoints.BUSINESS_ACTIVITIES_GET]"
                  [preloaderType]="preloaderTypes.ICON"
                  [preloaderSize]="20">
    </ng-container>
  </div>

  <!-- Show control errors -->
  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
