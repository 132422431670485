import { Component, Input } from '@angular/core';
import { BankAccountPropertiesForm, compareMatOptions } from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * Bank account properties form component.
 * Allow user to add one or multiple properties to bank account.
 * Used in bank accounts add/edit/import process.
 * @TODO Alex: use it also in Bank account manage properties dialog
 */
@Component({
  selector: 'app-bank-account-properties-form',
  templateUrl: './bank-account-properties-form.component.html',
  styleUrls: ['./bank-account-properties-form.component.scss']
})
export class BankAccountPropertiesFormComponent extends BaseComponent {
  @Input() form: BankAccountPropertiesForm;
  compareFn = compareMatOptions;
}
