import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@shared/component/base/base.component';

@Component({
  selector: 'app-sidenav-toggle-button',
  templateUrl: './sidenav-toggle-button.component.html',
  styleUrls: ['./sidenav-toggle-button.component.scss']
})
export class SidenavToggleButtonComponent extends BaseComponent {
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() isSidebarOpened: boolean;
  @Input() isBurger: boolean;
}
