import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  SubscriptionBannerUpgradeComponent
} from '@components/subscription-banner/subscription-banner-upgrade/subscription-banner-upgrade.component';
import {
  SubscriptionChangeDialogComponent
} from '@client/shared/components/subscription/subscription-change-dialog/subscription-change-dialog.component';
import { DialogData } from '@client/shared/components/document/upload-document-dialog/upload-document-dialog.component';
import { ServiceProductIdEnum } from 'taxtank-core';

@Component({
  selector: 'app-subscription-banner-upgrade-sole',
  templateUrl: './subscription-banner-upgrade-sole.component.html',
  styleUrls: ['./subscription-banner-upgrade-sole.component.scss']
})
export class SubscriptionBannerUpgradeSoleComponent {
  constructor(
    private dialogRef: MatDialogRef<SubscriptionBannerUpgradeComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  openSubscriptionDialog(): void {
    this.dialog.open(SubscriptionChangeDialogComponent, {
      panelClass: 'dialog-large',
      data: ServiceProductIdEnum.SOLE_TANK
    });

    this.dialogRef.close();
  }
}
