<mat-expansion-panel [hideToggle]="true"
                     [disabled]="true"
                     [expanded]="form.enabled"
                     togglePosition="before">

  <mat-expansion-panel-header class="pv-0">
    <mat-panel-title class="align-v-center">
      <div class="mr-10">
        <mat-checkbox *ngIf="bankAccount.id" [checked]="true" [disabled]="true"></mat-checkbox>

        <mat-checkbox *ngIf="!bankAccount.id"
                      [checked]="form.enabled"
                      (change)="toggleSelection($event.checked)">
        </mat-checkbox>
      </div>

      <div class="fw-bold">
        <div class="account-name">{{bankAccount.accountName}}</div>
        <div class="fs-small c-text">{{bankAccount.accountNumber}}</div>
      </div>
    </mat-panel-title>

    <mat-panel-description>
      <div class="row w-100">
        <div class="col-12 col-md-3 align-v-center">
          <div class="c-text fs-big">
            <div class="fw-bold mb-15">Balance</div>
            <div>{{bankAccount.currentBalance | customCurrency}}</div>
          </div>
        </div>

        <div class="col-12 col-md-4 align-v-center">
          <div class="c-text">
            <div class="fw-bold mb-15">Type</div>
            <div>{{bankAccountTypes[bankAccount.type] | translate}}</div>
          </div>
        </div>

        <div class="col-12 col-md-5">
          <div [formGroup]="form" *ngIf="!bankAccount.id">
            <app-tank-type-select [exclude]="[tankTypes.HOLDING]" mode="select" formControlName="tankType"></app-tank-type-select>
          </div>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <form appFormErrorScroll class="additional-data-forms transparent" [formGroup]="form">
    <!-- Properties -->
    <app-bank-account-properties-form *ngIf="form.contains('bankAccountProperties')"
                                      [form]="form.get('bankAccountProperties')">
    </app-bank-account-properties-form>

    <!-- Businesses -->
    <app-bank-account-business-allocations-form *ngIf="form.contains('businessAllocations')"
                                                [form]="form.get('businessAllocations')">
    </app-bank-account-business-allocations-form>

    <!-- loan -->
    <app-bank-account-loan-form *ngIf="form.contains('loan')"
                                [form]="form.get('loan')">
    </app-bank-account-loan-form>
  </form>
</mat-expansion-panel>
