<div mat-dialog-title>
  <div class="dialog-caption">
    <div class="align-v-center">
      <app-image [src]="bankAccount.bankConnection.bank | call:'getPhoto'" [size]="40" format="square"></app-image>
      <div class="ml-10 bank-name">{{bankAccount.accountName}}</div>
    </div>
  </div>
  <button mat-dialog-close class="btn icon-close"></button>
</div>

<div mat-dialog-content>
  <app-bank-account-preview-card [bankAccount]="bankAccount" class="mb-30"></app-bank-account-preview-card>

  <form [formGroup]="form" class="transparent" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-6">
        <app-datepicker label="Import transactions from" formControlName="migrateFrom"></app-datepicker>
      </div>

      <div class="col-6">
        <app-datepicker label="Import transactions to" formControlName="migrateTo"></app-datepicker>
      </div>
    </div>

    <ng-container formArrayName="bankAccounts">
      <ng-container *ngIf="form.get('bankAccounts')['controls'][0] as bankAccountForm" [formGroup]="bankAccountForm">
        <div class="row">
          <div class="col-12">
            <app-tank-type-select [exclude]="[tankTypes.HOLDING]" mode="select" formControlName="tankType"></app-tank-type-select>
          </div>

          <div appFormErrorScroll class="additional-data-forms">
            <!-- Properties -->
            <app-bank-account-properties-form *ngIf="bankAccountForm.contains('bankAccountProperties')"
                                              [form]="bankAccountForm.get('bankAccountProperties')">
            </app-bank-account-properties-form>

            <!-- Businesses -->
            <app-bank-account-business-allocations-form *ngIf="bankAccountForm.contains('businessAllocations')"
                                                        [form]="bankAccountForm.get('businessAllocations')">
            </app-bank-account-business-allocations-form>

            <!-- loan -->
            <app-bank-account-loan-form *ngIf="bankAccountForm.contains('loan')"
                                        [form]="bankAccountForm.get('loan')">
            </app-bank-account-loan-form>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>
</div>

<div mat-dialog-actions>
  <div class="row gx-3 align-h-right">
    <div class="col-6 col-sm-auto">
      <button mat-dialog-close class="btn basic back w-100" type="submit">Back</button>
    </div>
    <div class="col-6 col-sm-auto">
      <button class="btn primary w-100" (click)="formRef.ngSubmit.emit()" [disabled]="!form">Submit</button>
    </div>
  </div>
</div>
