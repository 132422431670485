import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseFormComponent } from '@shared/component/base/base-form.component';
import { BankAccount, BankAccountsImportForm } from 'taxtank-core';

/**
 * Dialog component with bank account import form.
 * Using for mobile view and works with single bank account.
 */
@Component({
  selector: 'app-bank-account-import-form-dialog',
  templateUrl: './bank-account-import-form-dialog.component.html',
  styleUrls: ['./bank-account-import-form-dialog.component.scss']
})
export class BankAccountImportFormDialogComponent extends BaseFormComponent {
  form: BankAccountsImportForm;

  constructor(
    @Inject(MAT_DIALOG_DATA) public bankAccount: BankAccount,
    private dialogRef: MatDialogRef<BankAccountImportFormDialogComponent>
  ) {
    super();
  }

  buildForm(): void {
    this.form = new BankAccountsImportForm([this.bankAccount]);
    // inner forms are disabled by default because of multiple selection.
    // in desktop view user have to select bank account by checkbox to enable form, 
    // but here we have only one bank account selected before
    this.form.get(['bankAccounts', 0]).enable();
  }

  save(bankAccounts: BankAccount[]): void {
    this.dialogRef.close(bankAccounts);
  }
}
