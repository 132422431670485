import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-banner-start',
  templateUrl: './subscription-banner-start.component.html',
  styleUrls: ['./subscription-banner-start.component.scss']
})
export class SubscriptionBannerStartComponent {

  constructor(
    public dialogRef: MatDialogRef<SubscriptionBannerStartComponent>
  ) { }
}
