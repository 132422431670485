import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BankAccount, BankAccountService, BankConnection } from 'taxtank-core';
import { BaseComponent } from '@shared/component/base/base.component';

/**
 * Component contain manual bank account form or import basiq bank accounts form depending on bank connection
 */
@Component({
  selector: 'app-bank-accounts-add',
  templateUrl: './bank-accounts-add.component.html',
  styleUrls: ['./bank-accounts-add.component.scss']
})
export class BankAccountsAddComponent extends BaseComponent {
  @Input() connection: BankConnection;
  @Output() saved: EventEmitter<BankAccount[]> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() connectionSaved: EventEmitter<BankConnection> = new EventEmitter();

  constructor(
    private bankAccountService: BankAccountService
  ) {
    super();
  }

  save(bankAccounts: BankAccount[]) {
    this.bankAccountService.postBatch(bankAccounts).subscribe((newBankAccounts: BankAccount[]) => {
      this.saved.emit(newBankAccounts);
    });
  }
}
